import React, {useState} from 'react';
import styles from '../styles/pages/Home.module.css';
import IncorrectUrlComponent from '../components/shared/pageNotFoundComponent/index';

const PageNotFound = () => {
  return (
    <div className="page_content">
      <div className={styles.container}>
        <main className={styles.main}>
          {/* <SideNavigationComponent pageName="CONTENT-MANAGEMENT" {...props} /> */}
          <IncorrectUrlComponent />
        </main>
      </div>
    </div>
  );
};

PageNotFound.propTypes = {};

export default PageNotFound;
