import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
  setDashboardNotificationSwitch,
  setDashboardNotificationCount,
  setNotificationList,
} from '../../redux/actions/uiActions';
import styles from './GlobalNotification.module.scss';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
const deleteAllNotifications = async (
  getClearAllNotificationString,
  setEmptyString,
  notificationGroup,
  fetchNotificationList,
  fetchNotificationCount,
) => {
  if (getClearAllNotificationString(notificationGroup)?.toLowerCase()?.includes('all')) {
    setEmptyString('All Notifications Cleared');
  } else if (getClearAllNotificationString(notificationGroup)?.toLowerCase()?.includes('courses')) {
    setEmptyString('All Courses Notifications Cleared');
  } else if (getClearAllNotificationString(notificationGroup)?.toLowerCase()?.includes('image')) {
    setEmptyString('All Image Libraries Notifications Cleared');
  } else if (
    getClearAllNotificationString(notificationGroup)?.toLowerCase()?.includes('learning')
  ) {
    setEmptyString('All Learning Paths Notifications Cleared');
  } else if (getClearAllNotificationString(notificationGroup)?.toLowerCase()?.includes('user')) {
    setEmptyString('All User Managements Notifications Cleared');
  } else if (
    getClearAllNotificationString(notificationGroup)?.toLowerCase()?.includes('categories')
  ) {
    setEmptyString('All Categories Skills Tags Notifications Cleared');
  }

  let query = '';

  if (notificationGroup[0] === 'course') {
    query = '?section=Course';
  } else if (notificationGroup[0] === 'image') {
    query = '?section=Image';
  } else if (notificationGroup[0] === 'learning_path') {
    query = '?section=Learning_Path';
  } else if (notificationGroup[0] === 'audience_user_group') {
    query = '?section=AUDIENCE_USER_GROUP';
  } else if (notificationGroup[0] === 'cst') {
    query = '?section=CST';
  }

  const currentUserAmwayId = window.localStorage.getItem('am_id');
  const data = await makeRequest({
    method: 'DELETE',
    endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications${query}`,
  });
  if (data) {
    fetchNotificationList();
    fetchNotificationCount();
  }
  // const notifGroup = ["all"];
  // setNotificationGroup(notifGroup);
};

const arrayForShowingCoursesNotification = (coursesArray, tempNotificationList, disabledArray) => {
  coursesArray = tempNotificationList.filter((ele) =>
    ele?.section?.toLowerCase()?.includes('course'),
  );

  if (coursesArray?.length === 0) {
    disabledArray.push('course');
  }
};

const arrayForShowingLearningPathNotification = (
  learningArray,
  tempNotificationList,
  disabledArray,
) => {
  learningArray = tempNotificationList.filter((ele) =>
    ele?.section?.toLowerCase()?.includes('learning_path'),
  );

  if (learningArray?.length === 0) {
    disabledArray.push('learning_path');
  }
};

const arrayForShowingImageLibNotification = (imageArray, tempNotificationList, disabledArray) => {
  imageArray = tempNotificationList.filter((ele) => ele?.section?.toLowerCase()?.includes('image'));

  if (imageArray?.length === 0) {
    disabledArray.push('image');
  }
};

const arrayForShowingAudienceNotification = (
  audienceArray,
  tempNotificationList,
  disabledArray,
) => {
  audienceArray = tempNotificationList.filter((ele) =>
    ele?.section?.toLowerCase()?.includes('audience_user_group'),
  );

  if (audienceArray?.length === 0) {
    disabledArray.push('audience_user_group');
  }
};

const arrayForShowingCSTNotification = (audienceArray, tempNotificationList, disabledArray) => {
  audienceArray = tempNotificationList.filter((ele) =>
    ele?.section?.toLowerCase()?.includes('cst'),
  );

  if (audienceArray?.length === 0) {
    disabledArray.push('cst');
  }
};

const useEffectForNotificationListState = (notificationList, setDisabledNotificationGroup) => {
  let disabledArray = [];
  let coursesArray = [];
  let learningArray = [];
  let imageArray = [];
  let audienceArray = [];

  if (notificationList?.length != 0) {
    const tempNotificationList = [
      ...notificationList?.today,
      ...notificationList?.lastSevenDays,
      ...notificationList?.month,
    ];

    if (tempNotificationList?.length === 0) {
      setDisabledNotificationGroup(['all']);
      // setNotificationGroup([]);
    } else {
      arrayForShowingCoursesNotification(coursesArray, tempNotificationList, disabledArray);

      arrayForShowingLearningPathNotification(learningArray, tempNotificationList, disabledArray);

      arrayForShowingImageLibNotification(imageArray, tempNotificationList, disabledArray);

      arrayForShowingAudienceNotification(audienceArray, tempNotificationList, disabledArray);

      arrayForShowingCSTNotification(audienceArray, tempNotificationList, disabledArray);

      setDisabledNotificationGroup(disabledArray);
    }
  }
};

const addNotificationGroupItem = (item, notificationGroup, updateNotificationGroup) => {
  let tempArr = [];
  if (item === 'all') {
    if (!notificationGroup?.includes('all')) {
      tempArr.push('all');
      updateNotificationGroup(tempArr);
    }
  } else {
    tempArr = notificationGroup;

    if (tempArr === []) {
      tempArr.push(item);
    } else if (tempArr.includes(item)) {
      tempArr = [];
      tempArr.push('all');
    } else if (tempArr !== [] && !tempArr.includes(item)) {
      tempArr = [];
      tempArr.push(item);
    }

    updateNotificationGroup(tempArr);
  }
};

const getNotificationIcon = (props) => {
  const {action, section} = props;
  // console.log(">>>> >>>> getNotificationIcon >>>> props",props)
  switch (action?.toLowerCase()) {
    case 'create':
      if (section?.toLowerCase() == 'cst') {
        return 'cst_icon';
      }
      return 'create_icon';

    case 'launch':
      return 'create_icon';

    case 'update':
    case 'edit':
      return 'edit_icon';

    case 'archive':
      return 'archive_icon';

    case 'delete':
      return 'delete_icon';

    case 'offline':
      return 'offline_icon';

    case 'online':
      return 'online_icon';

    default:
      return 'edit_icon';
  }
};

const getClearAllNotificationString = (notificationGroup) => {
  switch (notificationGroup[0]) {
    case 'all':
      return 'Clear All';

    case 'course':
      return 'Clear Courses';

    case 'image':
      return 'Clear Image Library';

    case 'learning_path':
      return 'Clear Learning Paths';

    case 'audience':
      return 'Clear Audience Managements';

    case 'audience_user_group':
      return 'Clear User Management';

    case 'cst':
      return 'Clear Categories Skills Tags';

    default:
      return 'Clear All';
  }
};

const emptyScreenClassnameFunction = (disabledNotificationGroup, notificationGroup) => {
  return (
    disabledNotificationGroup?.includes('all') ||
    (disabledNotificationGroup?.includes('course') && notificationGroup.includes('course')) ||
    (disabledNotificationGroup?.includes('image') && notificationGroup.includes('image')) ||
    (disabledNotificationGroup?.includes('audience_user_group') &&
      notificationGroup.includes('audience_user_group')) ||
    (disabledNotificationGroup?.includes('learning_path') &&
      notificationGroup.includes('learning_path')) ||
    (disabledNotificationGroup?.includes('cst') && notificationGroup.includes('cst'))
  );
};

const EmptyScreenNotificationComponent = (props) => {
  const {emptyString} = props;
  return (
    <div className={styles.empty_screen_wrapper}>
      <div className={styles.img_wrapper}>
        <div className={styles.empty_img}></div>
      </div>
      <div className={styles.txt}>{emptyString}</div>
    </div>
  );
};
const TodayNotificationList = (props) => {
  const {notificationList, notificationGroup, getNotificationIcon, deleteSingleNotification} =
    props;
  return (
    notificationList &&
    notificationList?.today?.length > 0 && (
      <div
        className={[
          styles.section_wrapper,
          notificationList?.today?.find((x) => x.isRead === false) !== undefined
            ? styles.unread_state_parent
            : '',
          notificationList?.today?.find((x) => x.isRead === false) !== undefined
            ? styles.unread_notification
            : '',
        ].join(' ')}
      >
        <div className={[styles.title_wrapper, styles.unread_state_title].join(' ')}>
          <div className={[styles.calender_icon].join(' ')}></div>
          <div className={styles.title}>Today</div>
        </div>

        {notificationList &&
          notificationList?.today?.map((ele, index) => {
            if (
              notificationGroup.includes('all') ||
              notificationGroup.includes(ele?.section?.toLowerCase())
            ) {
              return (
                <div
                  key={index}
                  className={[styles.info_container, ele?.isRead ? '' : styles.unread_state].join(
                    ' ',
                  )}
                >
                  <div className={styles.info_wrapper}>
                    <div
                      className={[
                        styles.icon,
                        styles.left_icons,
                        styles[getNotificationIcon({action: ele?.action, section: ele?.section})],
                      ].join(' ')}
                    ></div>
                    <div
                      className={[styles.icon, styles.close_icon].join(' ')}
                      onClick={() => {
                        deleteSingleNotification(ele?.id);
                      }}
                    ></div>
                    <div className={styles.title_wrapper}>
                      <div className={styles.title}>{ele?.title}</div>
                      <div className={styles.description_txt}>{ele?.message}</div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    )
  );
};

const LastSevenDaysNotificationList = (props) => {
  const {notificationList, notificationGroup, getNotificationIcon, deleteSingleNotification} =
    props;
  return (
    notificationList &&
    notificationList?.lastSevenDays?.length > 0 && (
      <div
        className={[
          styles.section_wrapper,
          notificationList?.today?.find((x) => x.isRead === false) !== undefined
            ? styles.unread_state_parent
            : '',
          notificationList?.today?.find((x) => x.isRead === false) !== undefined
            ? styles.unread_notification
            : '',
        ].join(' ')}
      >
        <div className={[styles.title_wrapper, styles.unread_state_title].join(' ')}>
          <div className={[styles.calender_icon].join(' ')}></div>
          <div className={styles.title}>Last 7 days</div>
        </div>

        {notificationList &&
          notificationList?.lastSevenDays?.map((ele, index) => {
            if (
              notificationGroup.includes('all') ||
              notificationGroup.includes(ele?.section?.toLowerCase())
            ) {
              return (
                <div
                  key={index}
                  className={[styles.info_container, ele?.isRead ? '' : styles.unread_state].join(
                    ' ',
                  )}
                >
                  <div className={styles.info_wrapper}>
                    <div
                      className={[
                        styles.icon,
                        styles.left_icons,
                        styles[getNotificationIcon({action: ele?.action, section: ele?.section})],
                      ].join(' ')}
                    ></div>
                    <div
                      className={[styles.icon, styles.close_icon].join(' ')}
                      onClick={() => {
                        deleteSingleNotification(ele?.id);
                      }}
                    ></div>
                    <div className={styles.title_wrapper}>
                      <div className={styles.title}>{ele?.title}</div>
                      <div className={styles.description_txt}>{ele?.message}</div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
    )
  );
};

const PreviousMonthNotificationList = (props) => {
  const {notificationList, notificationGroup, getNotificationIcon, deleteSingleNotification} =
    props;
  let notificationType = notificationGroup[0];
  return (
    notificationList &&
    notificationList?.month?.length > 0 &&
    notificationList?.month?.map((ele, index) => {
      if (notificationGroup.includes('all') || notificationType === notificationType) {
        return (
          <div
            key={index}
            className={[
              styles.section_wrapper,
              !ele?.notifications[0].isRead ? styles.unread_state_parent : '',
              !ele?.notifications[0].isRead ? styles.unread_notification : '',
            ].join(' ')}
          >
            <div className={[styles.title_wrapper, styles.unread_state_title].join(' ')}>
              <div className={[styles.calender_icon].join(' ')}></div>
              <div className={styles.title}>{ele?.month + ' ' + ele?.year}</div>
            </div>
            {ele?.notifications?.length > 0 &&
              ele?.notifications?.map((ele2, index) => {
                if (
                  notificationGroup.includes('all') ||
                  notificationGroup.includes(ele2?.section?.toLowerCase())
                ) {
                  return (
                    <div
                      key={index}
                      className={[
                        styles.info_container,
                        ele2?.isRead ? '' : styles.unread_state,
                      ].join(' ')}
                    >
                      <div className={styles.info_wrapper}>
                        <div
                          className={[
                            styles.icon,
                            styles.left_icons,
                            styles[
                              getNotificationIcon({action: ele2?.action, section: ele2?.section})
                            ],
                          ].join(' ')}
                        ></div>
                        <div
                          onClick={() => {
                            deleteSingleNotification(ele2?.id);
                          }}
                          className={[styles.icon, styles.close_icon].join(' ')}
                        ></div>
                        <div className={styles.title_wrapper}>
                          <div className={styles.title}>{ele2?.title}</div>
                          <div className={styles.description_txt}>{ele2?.message}</div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        );
      }
    })
  );
};

const AllNotificationSection = (props) => {
  const {
    notificationGroup,
    disabledNotificationGroup,
    setEmptyString,
    addNotificationGroupItem,
    updateNotificationGroup,
  } = props;
  return (
    <div
      id={notificationGroup.includes('all') ? notificationGroup[0] : null}
      className={[
        styles.nav_item,
        notificationGroup.includes('all') ? styles.selected : '',
        false && disabledNotificationGroup?.includes('all') ? styles.disable : '',
      ].join(' ')}
      onClick={() => {
        // if (!disabledNotificationGroup?.includes("all")) {
        setEmptyString('No New Notifications To Show');
        addNotificationGroupItem('all', notificationGroup, updateNotificationGroup);
        // }
      }}
    >
      <div className={styles.item_text}>All</div>
    </div>
  );
};

const CoursesNotificationSection = (props) => {
  const {
    notificationGroup,
    disabledNotificationGroup,
    setEmptyString,
    addNotificationGroupItem,
    updateNotificationGroup,
  } = props;
  return (
    <div
      id={notificationGroup.includes('course') ? notificationGroup[0] : null}
      className={[
        styles.nav_item,
        notificationGroup.includes('course') ? styles.selected : '',
        false &&
        (disabledNotificationGroup?.includes('all') ||
          disabledNotificationGroup?.includes('course'))
          ? styles.disable
          : '',
      ].join(' ')}
      onClick={() => {
        // if (
        //   disabledNotificationGroup?.includes("all") ||
        //   disabledNotificationGroup?.includes("course")
        // ) {
        //   return;
        // } else {
        setEmptyString('No New Notifications To Show');
        addNotificationGroupItem('course', notificationGroup, updateNotificationGroup);
        // }
      }}
    >
      <div className={[styles.icon, styles.course_icon, styles.selected].join(' ')}></div>
      <div className={styles.item_text}>Courses</div>
    </div>
  );
};

const LearningPathNotificationSection = (props) => {
  const {
    notificationGroup,
    disabledNotificationGroup,
    addNotificationGroupItem,
    updateNotificationGroup,
    setEmptyString,
  } = props;
  return (
    <div
      id={notificationGroup.includes('learning_path') ? notificationGroup[0] : null}
      className={[
        styles.nav_item,
        notificationGroup.includes('learning_path') ? styles.selected : '',
        false &&
        (disabledNotificationGroup?.includes('all') ||
          disabledNotificationGroup?.includes('learning_path'))
          ? styles.disable
          : '',
      ].join(' ')}
      onClick={() => {
        // if (
        //   disabledNotificationGroup?.includes("all") ||
        //   disabledNotificationGroup?.includes("image")
        // ) {
        //   return;
        // } else {
        setEmptyString('No New Notifications To Show');
        addNotificationGroupItem('learning_path', notificationGroup, updateNotificationGroup);
        // }
      }}
    >
      <div className={[styles.icon, styles.learn_path_icon].join(' ')}></div>
      <div className={styles.item_text}>Learning Paths</div>
    </div>
  );
};

const ImagesNotificationSection = (props) => {
  const {
    notificationGroup,
    disabledNotificationGroup,
    setEmptyString,
    addNotificationGroupItem,
    updateNotificationGroup,
  } = props;
  return (
    <div
      id={notificationGroup.includes('image') ? notificationGroup[0] : null}
      className={[
        styles.nav_item,
        notificationGroup.includes('image') ? styles.selected : '',
        false &&
        (disabledNotificationGroup?.includes('all') || disabledNotificationGroup?.includes('image'))
          ? styles.disable
          : '',
      ].join(' ')}
      onClick={() => {
        // if (
        //   disabledNotificationGroup?.includes("all") ||
        //   disabledNotificationGroup?.includes("image")
        // ) {
        //   return;
        // } else {
        setEmptyString('No New Notifications To Show');
        addNotificationGroupItem('image', notificationGroup, updateNotificationGroup);
        // }
      }}
    >
      <div className={[styles.icon, styles.img_library_icon].join(' ')}></div>
      <div className={styles.item_text}>Image Library</div>
    </div>
  );
};

const AudienceManagementNotificationSection = (props) => {
  const {
    notificationGroup,
    disabledNotificationGroup,
    addNotificationGroupItem,
    updateNotificationGroup,
    setEmptyString,
  } = props;
  return (
    <div
      id={notificationGroup.includes('audience_user_group') ? notificationGroup[0] : null}
      className={[
        styles.nav_item,
        notificationGroup.includes('audience_user_group') ? styles.selected : '',
        false &&
        (disabledNotificationGroup?.includes('all') ||
          disabledNotificationGroup?.includes('audience_user_group'))
          ? styles.disable
          : '',
      ].join(' ')}
      onClick={() => {
        // if (
        //   disabledNotificationGroup?.includes("all") ||
        //     disabledNotificationGroup?.includes("audience_user_group")
        // ) {
        //   return;
        // } else {
        //   addNotificationGroupItem("audience_user_group",notificationGroup,updateNotificationGroup);
        // }
        setEmptyString('No New Notifications To Show');
        addNotificationGroupItem('audience_user_group', notificationGroup, updateNotificationGroup);
      }}
    >
      <div className={[styles.icon, styles.aud_mgt_icon].join(' ')}></div>
      <div className={styles.item_text}>User Group Management</div>
    </div>
  );
};

const CSTNotificationSection = (props) => {
  const {
    notificationGroup,
    disabledNotificationGroup,
    addNotificationGroupItem,
    updateNotificationGroup,
    setEmptyString,
  } = props;
  return (
    <div
      id={notificationGroup.includes('cst') ? notificationGroup[0] : null}
      className={[
        styles.nav_item,
        notificationGroup.includes('cst') ? styles.selected : '',
        false &&
        (disabledNotificationGroup?.includes('all') || disabledNotificationGroup?.includes('cst'))
          ? styles.disable
          : '',
      ].join(' ')}
      onClick={() => {
        setEmptyString('No New Notifications To Show');
        addNotificationGroupItem('cst', notificationGroup, updateNotificationGroup);
      }}
    >
      <div className={[styles.icon, styles.cst_icon].join(' ')}></div>
      <div className={styles.item_text}>Categories Skills Tags</div>
    </div>
  );
};

const GlobalNotification = (props) => {
  const {
    setDashboardNotificationSwitch,
    setDashboardNotificationCount,
    dashboardNotificationSwitch,
    notificationList,
    setNotificationList,
    notificationErrorState,
  } = props;

  const containerRef = React.createRef();
  const [notificationGroup, setNotificationGroup] = useState([]);
  const [disabledNotificationGroup, setDisabledNotificationGroup] = useState([]);
  const [enableClearAll, setEnableClearAll] = useState(false);
  const [noNetwork, setNoNetwork] = useState(false);
  const [serverDown, setServerDown] = useState(false);
  const [emptyString, setEmptyString] = useState('No New Notifications To Show');

  useEffect(() => {
    setNotificationGroup(['all']);
    setDisabledNotificationGroup([]);
    document.addEventListener('mousedown', handleClickOutside, true);
    setNoNetwork(navigator.onLine);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    useEffectForNotificationListState(notificationList, setDisabledNotificationGroup);
  }, [notificationList]);

  useEffect(() => {
    setServerDown(notificationErrorState);
  }, [notificationErrorState]);

  const handleClickOutside = async (event) => {
    if (
      containerRef &&
      !containerRef.current?.contains(event.target) &&
      !event.target.className.includes('GlobalNotification') &&
      !event.target.className.includes('SideNavigation_notification') &&
      !event.target.className.includes('temporary_test_class') &&
      !(event.target.className === '')
    ) {
      await readAllNotifications();
      fetchNotificationList();
      fetchNotificationCount();
      setDashboardNotificationSwitch(false);
    }
  };

  const fetchNotificationCount = async () => {
    const currentUserAmwayId = window.localStorage.getItem('am_id');
    const dataNotificationCount = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications/count`,
    });
    if (dataNotificationCount) {
      setDashboardNotificationCount(dataNotificationCount?.data?.count);
      setServerDown(false);
    } else if (dataNotificationCount == 'ERR_NETWORK') {
      setServerDown(true);
    }
  };

  const fetchNotificationList = async () => {
    const currentUserAmwayId = window.localStorage.getItem('am_id');
    const dataNotifications = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications`,
    });
    if (dataNotifications?.data) {
      setNotificationList(dataNotifications?.data);
      setServerDown(false);
    } else if (dataNotifications == 'ERR_NETWORK') {
      setServerDown(true);
    }
  };

  const fetchNotifications = (data) => {
    if (data && noNetwork) {
      fetchNotificationList();
      fetchNotificationCount();
    }
  };

  const readAllNotifications = async () => {
    const currentUserAmwayId = window.localStorage.getItem('am_id');
    const dataNotifications = await makeRequest({
      method: 'PUT',
      endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications`,
    });
    fetchNotifications(dataNotifications);
    // if(dataNotifications) {
    //   fetchNotificationList();
    //   fetchNotificationCount();
    // }
  };

  const deleteSingleNotification = async (notificationId) => {
    const currentUserAmwayId = window.localStorage.getItem('am_id');
    const data = await makeRequest({
      method: 'DELETE',
      endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications/${notificationId}`,
    });
    fetchNotifications(data);
    // if(data) {
    //   fetchNotificationList();
    //   fetchNotificationCount();
    // }
  };

  const updateNotificationGroup = (arr) => {
    setSelectedSubHeader(arr[0]);
    const tempArr = new Array(...arr);

    setNotificationGroup(tempArr);
  };

  const getPreviousMonthString = () => {
    const current = new Date();

    current.setMonth(current.getMonth() - 1);
    const previousMonth = current.toLocaleString('default', {month: 'long'});
    const year = current.getFullYear();

    return `${previousMonth}, ${year}`;
  };
  const NoNetworkNotificationComponent = () => {
    // const {emptyString} = props
    return (
      <div className={styles.no_network_screen_wrapper}>
        <div className={styles.img_wrapper}>
          <div className={styles.no_network_img}></div>
        </div>

        <div className={styles.content_wrapper}>
          <div className={styles.label}>No network available</div>
          <div className={styles.content}>
            <div>You appear to be offline. Please check your</div>
            <div>internet connection and try again.</div>
          </div>
        </div>
      </div>
    );
  };
  const ServerDownNotificationComponent = () => {
    // const {emptyString} = props

    return (
      <div className={styles.server_down_screen_wrapper}>
        <div className={styles.img_wrapper}>
          <div className={styles.server_down_img}></div>
        </div>

        <div className={styles.content_wrapper}>
          <div className={styles.label}>We’ll be right back!</div>
          <div className={styles.content}>
            <div>
              Academy is currently unavailable due to ongoing maintenance. We apologize for any
              inconvenience. Please try again later.
            </div>
          </div>
        </div>
      </div>
    );
  };
  const [selectedSubHeader, setSelectedSubHeader] = useState('');

  const executeScroll = (id) => {
    var element = document.getElementById(id);
    element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
  };

  useEffect(() => {
    selectedSubHeader && executeScroll(selectedSubHeader);
  }, [selectedSubHeader]);
  return (
    <div>
      {dashboardNotificationSwitch && (
        //  Here we added empty_screen_visible class for responsive empty screen, make it conditional
        <div
          ref={containerRef}
          className={[
            styles.notification_wrapper,
            !noNetwork ? styles.no_network_wrapper : '',
            serverDown ? styles.server_down_wrapper : '',
            emptyScreenClassnameFunction(disabledNotificationGroup, notificationGroup)
              ? styles.empty_screen_visible
              : '',
            '',
          ].join(' ')}
        >
          {/* no_network_wrapper => use this class name for only no network screen 
              server_down_wrapper 
          */}
          <div className={styles.header}>
            <div className={styles.title}>Notifications</div>
            <div className={styles.btn_wrapper}>
              {/* Close icon added with ui, instead using current close icon(should be hide) use this one */}
              <div className={[styles.close_icon, 'hide'].join(' ')}></div>
              {/* close icon ends */}
              {!emptyScreenClassnameFunction(disabledNotificationGroup, notificationGroup) &&
                !enableClearAll &&
                !disabledNotificationGroup?.includes('all') && (
                  <div
                    className={[styles.btn_txt, ''].join(' ')}
                    onClick={() => {
                      setEnableClearAll(true);
                    }}
                  >
                    {getClearAllNotificationString(notificationGroup)}
                  </div>
                )}
              {!emptyScreenClassnameFunction(disabledNotificationGroup, notificationGroup) &&
                enableClearAll &&
                !disabledNotificationGroup?.includes('all') && (
                  <div
                    className={[styles.confirmation_btn, ''].join(' ')}
                    onClick={async () => {
                      await deleteAllNotifications(
                        getClearAllNotificationString,
                        setEmptyString,
                        notificationGroup,
                        fetchNotificationList,
                        fetchNotificationCount,
                      );
                      setEnableClearAll(false);
                    }}
                  >
                    <div className={'temporary_test_class'}>Confirm Clear</div>
                  </div>
                )}
              {emptyScreenClassnameFunction(disabledNotificationGroup, notificationGroup) && (
                <div
                  onClick={() => {
                    setDashboardNotificationSwitch(false);
                  }}
                >
                  <div className={styles.close_icon} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.middle_body_wrapper}>
            <div className={styles.tab_menu_bar}>
              <AllNotificationSection
                notificationGroup={notificationGroup}
                disabledNotificationGroup={disabledNotificationGroup}
                setEmptyString={setEmptyString}
                addNotificationGroupItem={addNotificationGroupItem}
                updateNotificationGroup={updateNotificationGroup}
                setSelectedSubHeader={setSelectedSubHeader}
              />

              <CoursesNotificationSection
                notificationGroup={notificationGroup}
                disabledNotificationGroup={disabledNotificationGroup}
                setEmptyString={setEmptyString}
                addNotificationGroupItem={addNotificationGroupItem}
                updateNotificationGroup={updateNotificationGroup}
                setSelectedSubHeader={setSelectedSubHeader}
              />

              <LearningPathNotificationSection
                notificationGroup={notificationGroup}
                disabledNotificationGroup={disabledNotificationGroup}
                setEmptyString={setEmptyString}
                addNotificationGroupItem={addNotificationGroupItem}
                updateNotificationGroup={updateNotificationGroup}
                setSelectedSubHeader={setSelectedSubHeader}
              />

              <AudienceManagementNotificationSection
                notificationGroup={notificationGroup}
                disabledNotificationGroup={disabledNotificationGroup}
                setEmptyString={setEmptyString}
                addNotificationGroupItem={addNotificationGroupItem}
                updateNotificationGroup={updateNotificationGroup}
                setSelectedSubHeader={setSelectedSubHeader}
              />

              <ImagesNotificationSection
                notificationGroup={notificationGroup}
                disabledNotificationGroup={disabledNotificationGroup}
                setEmptyString={setEmptyString}
                addNotificationGroupItem={addNotificationGroupItem}
                updateNotificationGroup={updateNotificationGroup}
                setSelectedSubHeader={setSelectedSubHeader}
              />

              <CSTNotificationSection
                notificationGroup={notificationGroup}
                disabledNotificationGroup={disabledNotificationGroup}
                setEmptyString={setEmptyString}
                addNotificationGroupItem={addNotificationGroupItem}
                updateNotificationGroup={updateNotificationGroup}
                setSelectedSubHeader={setSelectedSubHeader}
              />
            </div>
            {/* Following empty screen should be parallel to inner_wrapper and text below img should change accordingly with states */}
            {/* Empty Screen */}
            <EmptyScreenNotificationComponent emptyString={emptyString} />
            {/* Empty Screen */}

            {noNetwork && !serverDown && (
              <div className={[styles.inner_wrapper, ''].join(' ')}>
                {/* first section for Today */}
                <TodayNotificationList
                  notificationList={notificationList}
                  notificationGroup={notificationGroup}
                  getNotificationIcon={getNotificationIcon}
                  deleteSingleNotification={deleteSingleNotification}
                />

                {/* second section for last 7 days */}
                <LastSevenDaysNotificationList
                  notificationList={notificationList}
                  notificationGroup={notificationGroup}
                  getNotificationIcon={getNotificationIcon}
                  deleteSingleNotification={deleteSingleNotification}
                />

                {/* third section for each previous months */}
                <PreviousMonthNotificationList
                  notificationList={notificationList}
                  getPreviousMonthString={getPreviousMonthString}
                  notificationGroup={notificationGroup}
                  getNotificationIcon={getNotificationIcon}
                  deleteSingleNotification={deleteSingleNotification}
                />
              </div>
            )}
            {serverDown && noNetwork && <ServerDownNotificationComponent />}
            {!noNetwork && <NoNetworkNotificationComponent />}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardNotificationSwitch: state?.uiState?.dashboardNotificationSwitch,
  notificationList: state?.uiState?.notificationList,
  currentUserId: state?.uiState?.currentUserId,
  notificationErrorState: state?.uiState?.notificationErrorState,
});

const mapDispatchToProps = (dispatch) => ({
  setDashboardNotificationSwitch: (options) => dispatch(setDashboardNotificationSwitch(options)),
  setDashboardNotificationCount: (options) => dispatch(setDashboardNotificationCount(options)),
  setNotificationList: (options) => dispatch(setNotificationList(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(GlobalNotification));
