import LocalizedStrings from 'react-localization';

let Strings = new LocalizedStrings({
  en: {
    IMG_LIBRARY: 'Image Library',
    NEW_IMG: 'New Image',
    FILE_NAME: 'File name',
    MAX_30_CHAR: 'Max 30 characters',
  },
  'zh-tw': {
    IMG_LIBRARY: 'Image Library',
  },
});

export default Strings;
