export const handleFilterApply = (props) => {
  const {
    params,
    filterData,
    findIndex,
    fetchAudienceGroupList,
    makeRequest,
    setSearchFilterEmptyState,
    setAudienceGroupList,
    setAudienceGroupListCopy,
    setErrorStateTemplate,
    isOnline,
    setIsLoading,
  } = props;
  // console.log(">>>> >>>> handleFilterApply >>>> props",props);
  let _filterData = filterData;
  let _selectedRadioButtonItem = {};
  let inUseItem;

  params &&
    params.length > 0 &&
    params.map((paramItem) => {
      const {typeOfFilter, index, checked, title, paramForAPI} = paramItem;

      // setting the selected state of filter UI
      if (typeOfFilter) {
        if (typeOfFilter === 'tiles') {
          _filterData[typeOfFilter][index].checked = !_filterData[typeOfFilter][index].checked;
        } else if (typeOfFilter === 'RADIO') {
          _selectedRadioButtonItem = paramItem;

          let _sectionData = [];
          _filterData.filtersList[0].filterTypeData.sectionData.map((singleItem) => {
            if (singleItem && findIndex(params, ['paramForAPI', singleItem.paramForAPI]) !== -1) {
              _sectionData.push({
                ...singleItem,
                checked: true,
              });
            } else {
              _sectionData.push({
                ...singleItem,
                checked: false,
              });
            }
          });
          _filterData.filtersList[0].filterTypeData.sectionData = _sectionData;
          //setFilterData(_filterData);
        } else if (typeOfFilter === 'CHECKBOX') {
          if (title === 'In-Use' && checked) {
            inUseItem = paramItem;
          }

          _filterData.filtersList[1].filterTypeData.sectionData[paramItem.index] = paramItem;
        }
      }
      // setting the selected state of filter UI > ends here
    });
  let selectedRadio = {};
  if (params?.typeOfFilter == 'RADIO') {
    selectedRadio = params;
  }
  fetchAudienceGroupList({
    sortBy: `${params[0]?.paramForAPI?.toLowerCase()}`,
    makeRequest,
    setSearchFilterEmptyState,
    setAudienceGroupList,
    setAudienceGroupListCopy,
    setErrorStateTemplate,
    isOnline,
    setIsLoading,
  });
};

export const handleCloseCoursesModal = (props) => {
  const {setCourses, isCourses} = props;

  setCourses(!isCourses);
};

export const handleCloseLearningModal = (props) => {
  const {setLearning, isLearning} = props;

  setLearning(!isLearning);
};

export const handleSaveDeleteChanges = (props) => {
  const {setDelete} = props;

  setDelete(false);
};

export const handleCloseNewUserGroup = (props) => {
  const {setNewUserGroup} = props;
  setNewUserGroup(false);
};

export const _filterData = {
  filtersList: [
    {
      filterType: 'RADIO',
      filterTypeData: {
        sectionTitle: 'Sort By',
        sectionData: [
          {
            index: 0,
            name: 'Recent Activity',
            id: 1,
            checked: true,
            filterParameter: 'name',
            filterParameterType: 'default',
            typeOfFilter: 'RADIO',
            paramForAPI: 'Recent_Activity',
          },
          {
            index: 1,
            name: 'A - Z',
            id: 2,
            checked: false,
            filterParameter: 'description',
            filterParameterType: 'ASC',
            typeOfFilter: 'RADIO',
            paramForAPI: 'A_Z',
          },
          {
            index: 2,
            name: 'Z - A',
            id: 3,
            checked: false,
            filterParameter: 'description',
            filterParameterType: 'DESC',
            typeOfFilter: 'RADIO',
            paramForAPI: 'Z_A',
          },
          {
            index: 3,
            name: 'Most Courses',
            id: 4,
            checked: false,
            filterParameter: 'updatedAt',
            filterParameterType: 'TIME-ASC',
            typeOfFilter: 'RADIO',
            paramForAPI: 'most_courses ',
          },
          {
            index: 4,
            name: 'Least Courses',
            id: 5,
            checked: false,
            filterParameter: 'updatedAt',
            filterParameterType: 'TIME-DESC',
            typeOfFilter: 'RADIO',
            paramForAPI: 'least_courses ',
          },
        ],
      },
    },
  ],
};

export const handleSearchInput = (props) => {
  const {event, setSearch, setPredictedSearch, audienceGroupList} = props;
  setSearch(event?.target?.value);
  setPredictedSearch('');

  let tempPredition = audienceGroupList?.map((item) => ({
    id:
      item?.audienceGroupId && item?.audienceGroupId !== null && item.audienceGroupId !== undefined
        ? item?.audienceGroupId
        : '',
    label:
      item?.audienceGrpName && item?.audienceGrpName !== null && item.audienceGrpName !== undefined
        ? item?.audienceGrpName
        : '',
  }));
  if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
    setPredictedSearch(tempPredition ? tempPredition : {});
  } else {
    setPredictedSearch([]);
  }
};

export const fetchAudienceGroupList = async (props) => {
  const {
    sortBy,
    makeRequest,
    setSearchFilterEmptyState,
    setAudienceGroupList,
    setAudienceGroupListCopy,
    setErrorStateTemplate,
    isOnline,
    setIsLoading,
  } = props;
  setIsLoading(true);
  const endpoint = `/admin/api/v1/audience-groups?sortBy=${sortBy}`;
  const dataCourses = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (dataCourses != 'ERR_NETWORK' && dataCourses) {
    setSearchFilterEmptyState(true);
    setAudienceGroupList(dataCourses?.data);
    setAudienceGroupListCopy(dataCourses?.data);
    setErrorStateTemplate({
      variant: '',
      status: false,
    });
    setIsLoading(false);
  } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
    setErrorStateTemplate({
      variant: 'serverError',
      status: true,
    });
    setIsLoading(false);
  }
};

export const handleCloseDeleteModal = (props) => {
  const {setDelete} = props;
  setDelete(false);
};

export const selectedAudiGrp = (props) => {
  const {
    e,
    item,
    isDrawer,
    audienceDetails,
    setDrawer,
    setAudienceDetails,
    makeRequest,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
  } = props;

  if (isDrawer && e.currentTarget.id === audienceDetails?.audienceGroupId) {
    setDrawer(false);
    setAudienceDetails(null);
  } else {
    fetchAudienceDetails({
      AudiId: item,
      makeRequest,
      setAudienceDetails,
      setDrawer,
      setDrawerAPIFailed,
      drawerIdForBrokenLink,
      setDrawerIdForBrokenLink,
    });
  }
};

export const fetchAudienceDetails = async (props) => {
  const {
    AudiId,
    makeRequest,
    setAudienceDetails,
    setDrawer,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
  } = props;

  const endpoint = `/admin/api/v1/audience-groups/${AudiId}`;
  let audienceDetailsData = await makeRequest({
    method: 'GET',
    endpoint,
  });
  if (audienceDetailsData != 'ERR_NETWORK' && audienceDetailsData) {
    setAudienceDetails(audienceDetailsData?.data);
    setDrawerAPIFailed(false);
    setDrawer(true);
  } else if (audienceDetailsData == 'ERR_NETWORK') {
    if (AudiId != undefined && AudiId !== drawerIdForBrokenLink) {
      setDrawerIdForBrokenLink(AudiId);
    }
    setDrawer(true);
    setDrawerAPIFailed(true);
  }
};
