import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import styles from './Portal.module.scss';
// import FilterComponent from './filter';
import FilterComponent from '../shared/filter/index';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import Notification from '../shared/notification';
import {useHistory} from 'react-router-dom';
//import config from "../../config";
import {makeRequest} from '../../components/utils/APIsUtils/httpsUtils';
import {
  setSelectedNavigationOption,
  setNotificationData,
  setIsUserLoggedIn,
  setErrorStateTemplate,
} from '../../redux/actions/uiActions';
import {timeDifference} from '../utils/commonUtil';
import {Hint} from 'react-autocomplete-hint';
import EmptyScreenComponent from '../shared/emptyScreen';
import TableListComponent from '../audienceManagement/TableComponent';
import {
  handleSearchInputUGM,
  getCoursesUIData,
  handleSearch,
  handleFilter,
  fetchCoursesListWithParams,
  handleToggleSwitch,
  fetchCategories,
  fetchCoursesList,
  fetchCoursesListWithParamsLP,
  handleFilterApplyNewLP,
  handleSearchInputLP,
  handleSearchInput,
  fetchCategoriesLP,
  handleFilterApplyNew,
  handleClickOutside,
  fetchAudienceGroupList,
  handleClickOutsideUGM,
  updateWindowDimensions,
  handleFilters,
  getMobileViewCourseState,
  handleFilterApplyUGM,
  fetchLearningPathList,
  redirectCourses,
  redirectUserGroup,
  redirectLearningPath,
  initialFirstUseEffect,
  tempCourseListUseEffect,
  courseListUseEffect,
  courseListAndSearchUseEffect,
  searchUseEffect,
  searchUserGroupUseEffect,
  searchLearningPathUseEffect,
} from '../utils/PortalDashboardUtils/PortalUtils';
import {dateIsoToDate, dateIsoToNormalDate, dateIsoToTime} from '../utils/commonUtil';
import {
  // _initialFilterDataLP,
  // _initialFilterData,
  // _initialFilterDataUGM,
  sortData,
} from '../utils/PortalDashboardUtils/PortalConstant';
import CardAndListCourseView from './cardAndListCourseView';
import AudienceSectionView from './AudienceSectionView';
import LearningPathSectionView from './LearningPathSectionView';
import MobileCoursesHeader from './MobileCoursesHeader';
import MobileAudienceHeader from './MobileAudienceHeader';
import MobileLearningHeader from './MobileLearningHeader';
import MobileCoursesSection from './MobileCoursesSection';
import MobileAudienceSection from './MobileAudienceSection';
import MobileLearningSection from './MobileLearningSection';
import MobileSelectPageSection from './MobileSelectPageSection';
const PortalComponent = (props) => {
  const {
    setSelectedNavigationOption,
    setNotificationData,
    notificationData,
    setIsUserLoggedIn,
    setErrorStateTemplate,
  } = props;

  const _initialFilterData = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Draft',
        className: 'draft',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Live',
        className: 'goLive',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Offline',
        className: 'goOffline',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 3,
        id: 'ARCHIEVE',
        title: 'Archive',
        className: 'archive',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launch_date',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launch_date',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: [],
        },
      },
    ],
  };

  const _initialFilterDataUGM = {
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
              paramForAPI: 'Recent_Activity',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'description',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'A_Z',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'description',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'Z_A',
            },
            {
              index: 3,
              name: 'Most Courses',
              id: 4,
              checked: false,
              filterParameter: 'updatedAt',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'most_courses',
            },
            {
              index: 4,
              name: 'Least Courses',
              id: 5,
              checked: false,
              filterParameter: 'updatedAt',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'least_courses',
            },
          ],
        },
      },
    ],
  };

  const _initialFilterDataLP = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Draft',
        className: 'draft',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Live',
        className: 'goLive',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Offline',
        className: 'goOffline',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 3,
        id: 'ARCHIEVE',
        title: 'Archive',
        className: 'archive',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launch_date',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launch_date',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: [],
        },
      },
    ],
  };
  const [isCardView, setIsCardView] = useState(true);
  const [isListView, setIsListView] = useState(false);
  const [courses, setCourses] = useState(false);
  const [learning, setLearning] = useState(false);
  const [audience, setAudience] = useState(false);
  const [filterCourses, setFilterCourses] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [tempCourseList, setTempCourseList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [courseListCopy, setCourseListCopy] = useState([]);
  const [courseFullList, setCourseFullList] = useState([]);
  const [courseListDefaultFilter, setCourseListDefaultFilter] = useState([]);
  const [showViewAllCardView, setShowViewAllCardView] = useState(false);
  const [showViewAllListView, setShowViewAllListView] = useState(false);
  const [search, setSearch] = useState('');
  const [searchUserGroup, setSearchUserGroup] = useState('');
  const [changeCourseList, setChangeCourseList] = useState(false);
  const [mobileTabSelected, setMobileTabSelected] = useState('COURSES');
  const [predictedSearch, setPredictedSearch] = useState('');
  const [predictedSearchUGM, setPredictedSearchUGM] = useState('');
  const [showEmptyTemplateFor, setShowEmptyTemplateFor] = useState('');
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);

  const [isSearchFilterEmptyStateCourses, setSearchFilterEmptyStatecourses] = useState(false);
  // Learing Path States
  const [learningPathAPIResponse, setLearningPathAPIResponse] = useState([]);
  const [learningPathAPIResponseCopy, setLearningPathAPIResponseCopy] = useState([]);
  const [isSearchFilterEmptyStateLP, setSearchFilterEmptyStateLP] = useState(false);
  const [searchLearningPath, setSearchLearningPath] = useState('');
  const [predictedSearchLP, setPredictedSearchLP] = useState('');

  const router = useHistory();

  const sortAndFilterFlyout = useRef(null);
  const sortAndFilterFlyoutUGM = useRef(null);

  const [filterData, setFilterData] = useState(_initialFilterData);
  const [filterDataUGM, setFilterDataUGM] = useState(_initialFilterDataUGM);
  const [filterDataTemp, setFilterDataTemp] = useState(new Object(_initialFilterData));
  const [filterDataTempUGM, setFilterDataTempUGM] = useState(new Object(_initialFilterDataUGM));
  const [filterDataLP, setFilterDataLP] = useState(_initialFilterDataLP);
  const [filterDataTempLP, setFilterDataTempLP] = useState(new Object(_initialFilterDataLP));

  const headingName = {sort_by: 'Sort By', filter_by: 'Category'};
  const headingNameUGM = {sort_by: 'Sort By'};
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [filterDropdownUGM, setFilterDropdownUGM] = useState(false);
  const [filterDropdownLP, setFilterDropdownLP] = useState(false);
  const [isOnline, setOnline] = useState(true);
  const [audienceGroupList, setAudienceGroupList] = useState([]);
  const [audienceGroupListCopy, setAudienceGroupListCopy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  window.addEventListener('online', () => {
    setOnline(true);
    setErrorStateTemplate({
      status: false,
      variant: 'networkError',
    });
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
    setErrorStateTemplate({
      status: true,
      variant: 'networkError',
    });
  });

  useEffect(async () => {
    updateWindowDimensions({setWidth, setHeight});
    window.addEventListener('resize', () => {
      updateWindowDimensions({setWidth, setHeight});
    });
    setIsLoading(true);
    initialFirstUseEffect({
      setOnline,
      setIsUserLoggedIn,
      setSelectedNavigationOption,
      makeRequest,
      setShowEmptyTemplateFor,
      setSearchFilterEmptyStatecourses,
      setCourseFullList,
      setCourseListDefaultFilter,
      setCourseListCopy,
      setCourseList,
      setShowViewAllCardView,
      setShowViewAllListView,
      setErrorStateTemplate,
      isOnline,
      filterData,
      findIndex,
      setFilterData,
      setFilterDataLP,
      filterDataLP,
      setSearchFilterEmptyState,
      searchUserGroup,
      setAudienceGroupListCopy,
      setAudienceGroupList,
      setLearningPathAPIResponse,
      setLearningPathAPIResponseCopy,
      setSearchFilterEmptyStateLP,
      setWidth,
      setHeight,
      setFilterDropdownLP,
      setFilterDropdownUGM,
      timeDifference,
      router,
      setFilterDropdown,
      setIsLoading,
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 10000);
  }, []);

  useEffect(() => {
    tempCourseListUseEffect({
      setCourseList,
      tempCourseList,
      setShowEmptyTemplateFor,
    });
  }, [tempCourseList]);

  useEffect(() => {
    courseListUseEffect({
      changeCourseList,
      tempCourseList,
      setCourseList,
      courseList,
      setChangeCourseList,
    });
  }, [courseList]);

  useEffect(() => {
    courseListAndSearchUseEffect({courseList, search, setShowEmptyTemplateFor});
  }, [courseList, search]);

  useEffect(() => {
    searchUseEffect({search, courseFullList, courseListCopy, setCourseList});
  }, [search]);

  useEffect(() => {
    searchUserGroupUseEffect({
      searchUserGroup,
      audienceGroupListCopy,
      setAudienceGroupList,
    });
  }, [searchUserGroup]);

  useEffect(() => {
    searchLearningPathUseEffect({
      searchLearningPath,
      learningPathAPIResponseCopy,
      setLearningPathAPIResponse,
    });
  }, [searchLearningPath]);

  return (
    <React.Fragment>
      <div className={[styles.portal_main_wrapper, ''].join(' ')}>
        <div className={[styles.portal_wrapper, 'bootstrap-wrapper'].join(' ')}>
          {width > 600 ? (
            <div className={'row'}>
              {/* <div className={[styles.p_lr_12, 'col-xl-7 col-lg-12 pull-left'].join(' ')}> */}
              <div className={['col-sm-12 col-md-12 col-xl-7 col-lg-12 pull-left'].join(' ')}>
                {/* Course Section  */}

                <CardAndListCourseView
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isListView={isListView}
                  isCardView={isCardView}
                  showEmptyTemplateFor={showEmptyTemplateFor}
                  courseList={courseList}
                  redirectCourses={redirectCourses}
                  setSelectedNavigationOption={setSelectedNavigationOption}
                  router={router}
                  dateIsoToNormalDate={dateIsoToNormalDate}
                  isSearchFilterEmptyStateCourses={isSearchFilterEmptyStateCourses}
                  EmptyScreenComponent={EmptyScreenComponent}
                  dateIsoToTime={dateIsoToTime}
                  dateIsoToDate={dateIsoToDate}
                  courses={courses}
                  search={search}
                  predictedSearch={predictedSearch}
                  Hint={Hint}
                  handleSearchInput={handleSearchInput}
                  setSearch={setSearch}
                  setPredictedSearch={setPredictedSearch}
                  setLearning={setLearning}
                  setAudience={setAudience}
                  learning={learning}
                  audience={audience}
                  setNotificationData={setNotificationData}
                  handleSearch={handleSearch}
                  setCourses={setCourses}
                  sortAndFilterFlyout={sortAndFilterFlyout}
                  filterData={filterData}
                  setFilterDataTemp={setFilterDataTemp}
                  setFilterData={setFilterData}
                  makeRequest={makeRequest}
                  setShowEmptyTemplateFor={setShowEmptyTemplateFor}
                  setSearchFilterEmptyStatecourses={setSearchFilterEmptyStatecourses}
                  setCourseListDefaultFilter={setCourseListDefaultFilter}
                  setErrorStateTemplate={setErrorStateTemplate}
                  setCourseFullList={setCourseFullList}
                  setCourseListCopy={setCourseListCopy}
                  setCourseList={setCourseList}
                  setShowViewAllListView={setShowViewAllListView}
                  setShowViewAllCardView={setShowViewAllCardView}
                  filterCourses={filterCourses}
                  setFilterDropdown={setFilterDropdown}
                  setFilterDropdownLP={setFilterDropdownLP}
                  setFilterDropdownUGM={setFilterDropdownUGM}
                  handleFilters={handleFilters}
                  filterDropdown={filterDropdown}
                  FilterComponent={FilterComponent}
                  headingName={headingName}
                  handleFilterApplyNew={handleFilterApplyNew}
                  filter={filter}
                  sortData={sortData}
                  setIsCardView={setIsCardView}
                  setIsListView={setIsListView}
                  handleToggleSwitch={handleToggleSwitch}
                  isOnline={isOnline}
                  height={height > 1400 ? height : 'auto'}
                />

                {/* Audience Section UGM */}
                <AudienceSectionView
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  audience={audience}
                  searchUserGroup={searchUserGroup}
                  predictedSearchUGM={predictedSearchUGM}
                  Hint={Hint}
                  handleSearchInputUGM={handleSearchInputUGM}
                  setSearchUserGroup={setSearchUserGroup}
                  setPredictedSearchUGM={setPredictedSearchUGM}
                  audienceGroupList={audienceGroupList}
                  setNotificationData={setNotificationData}
                  setSearch={setSearch}
                  setCourses={setCourses}
                  courses={courses}
                  setLearning={setLearning}
                  setAudience={setAudience}
                  learning={learning}
                  handleSearch={handleSearch}
                  filterDataUGM={filterDataUGM}
                  setFilterDataUGM={setFilterDataUGM}
                  setFilterDataTempUGM={setFilterDataTempUGM}
                  makeRequest={makeRequest}
                  setSearchFilterEmptyState={setSearchFilterEmptyState}
                  setAudienceGroupListCopy={setAudienceGroupListCopy}
                  setAudienceGroupList={setAudienceGroupList}
                  setErrorStateTemplate={setErrorStateTemplate}
                  isOnline={isOnline}
                  sortAndFilterFlyoutUGM={sortAndFilterFlyoutUGM}
                  filterCourses={filterCourses}
                  filterDropdownUGM={filterDropdownUGM}
                  setFilterDropdown={setFilterDropdown}
                  setFilterDropdownLP={setFilterDropdownLP}
                  setFilterDropdownUGM={setFilterDropdownUGM}
                  handleFilterApplyUGM={handleFilterApplyUGM}
                  FilterComponent={FilterComponent}
                  sortData={sortData}
                  isSearchFilterEmptyState={isSearchFilterEmptyState}
                  TableListComponent={TableListComponent}
                  EmptyScreenComponent={EmptyScreenComponent}
                  setSelectedNavigationOption={setSelectedNavigationOption}
                  router={router}
                  componentUGM={true}
                  redirectUserGroup={redirectUserGroup}
                  showEmptyTemplateFor={showEmptyTemplateFor}
                  height={height > 1400 ? height : 'auto'}
                />
              </div>

              {/* Learning Section */}
              <LearningPathSectionView
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                learning={learning}
                searchLearningPath={searchLearningPath}
                predictedSearchLP={predictedSearchLP}
                Hint={Hint}
                setNotificationData={setNotificationData}
                setSearch={setSearch}
                setCourses={setCourses}
                courses={courses}
                setLearning={setLearning}
                setAudience={setAudience}
                handleSearch={handleSearch}
                makeRequest={makeRequest}
                FilterComponent={FilterComponent}
                sortData={sortData}
                EmptyScreenComponent={EmptyScreenComponent}
                setSelectedNavigationOption={setSelectedNavigationOption}
                router={router}
                showEmptyTemplateFor={showEmptyTemplateFor}
                setSearchLearningPath={setSearchLearningPath}
                learningPathAPIResponse={learningPathAPIResponse}
                setPredictedSearchLP={setPredictedSearchLP}
                handleSearchInputLP={handleSearchInputLP}
                searchUserGroup={searchUserGroup}
                audience={audience}
                filterDataLP={filterDataLP}
                setFilterDataLP={setFilterDataLP}
                setFilterDataTempLP={setFilterDataTempLP}
                setLearningPathAPIResponse={setLearningPathAPIResponse}
                setLearningPathAPIResponseCopy={setLearningPathAPIResponseCopy}
                setSearchFilterEmptyStateLP={setSearchFilterEmptyStateLP}
                setFilterDropdown={setFilterDropdown}
                setFilterDropdownLP={setFilterDropdownLP}
                setFilterDropdownUGM={setFilterDropdownUGM}
                filterDropdownLP={filterDropdownLP}
                redirectLearningPath={redirectLearningPath}
                headingName={headingName}
                handleFilterApplyNewLP={handleFilterApplyNewLP}
                isSearchFilterEmptyStateLP={isSearchFilterEmptyStateLP}
                dateIsoToNormalDate={dateIsoToNormalDate}
                lpFilter={true}
                height={height > 1400 ? height : 'auto'}
              />
            </div>
          ) : width < 600 && width > 1 ? (
            <div className={[styles.card_wrapper, ''].join(' ')}>
              <div className={styles.header_wrapper}>
                <MobileSelectPageSection
                  mobileTabSelected={mobileTabSelected}
                  setMobileTabSelected={setMobileTabSelected}
                />
                <MobileCoursesHeader
                  mobileTabSelected={mobileTabSelected}
                  setIsLoading={setIsLoading}
                  courses={courses}
                  search={search}
                  setSearch={setSearch}
                  setLearning={setLearning}
                  setAudience={setAudience}
                  learning={learning}
                  audience={audience}
                  setNotificationData={setNotificationData}
                  handleSearch={handleSearch}
                  setCourses={setCourses}
                  filterData={filterData}
                  setFilterDataTemp={setFilterDataTemp}
                  setFilterData={setFilterData}
                  makeRequest={makeRequest}
                  setShowEmptyTemplateFor={setShowEmptyTemplateFor}
                  setSearchFilterEmptyStatecourses={setSearchFilterEmptyStatecourses}
                  setCourseListDefaultFilter={setCourseListDefaultFilter}
                  setErrorStateTemplate={setErrorStateTemplate}
                  setCourseFullList={setCourseFullList}
                  setCourseListCopy={setCourseListCopy}
                  setCourseList={setCourseList}
                  setShowViewAllListView={setShowViewAllListView}
                  setShowViewAllCardView={setShowViewAllCardView}
                  filterCourses={filterCourses}
                  FilterComponent={FilterComponent}
                  headingName={headingName}
                  handleFilterApplyNew={handleFilterApplyNew}
                  filter={filter}
                  sortData={sortData}
                  isOnline={isOnline}
                  setFilterCourses={setFilterCourses}
                  handleFilter={handleFilter}
                  dateIsoToTime={dateIsoToTime}
                  dateIsoToDate={dateIsoToDate}
                  dateIsoToNormalDate={dateIsoToNormalDate}
                />
                <MobileLearningHeader
                  setIsLoading={setIsLoading}
                  mobileTabSelected={mobileTabSelected}
                  searchLearningPath={searchLearningPath}
                  predictedSearchLP={predictedSearchLP}
                  Hint={Hint}
                  setSearchLearningPath={setSearchLearningPath}
                  learningPathAPIResponse={learningPathAPIResponse}
                  setPredictedSearchLP={setPredictedSearchLP}
                  handleSearchInputLP={handleSearchInputLP}
                  searchUserGroup={searchUserGroup}
                  setSearch={setSearch}
                  setCourses={setCourses}
                  courses={courses}
                  setLearning={setLearning}
                  setAudience={setAudience}
                  learning={learning}
                  audience={audience}
                  filterDataLP={filterDataLP}
                  setFilterDataLP={setFilterDataLP}
                  setFilterDataTempLP={setFilterDataTempLP}
                  makeRequest={makeRequest}
                  setLearningPathAPIResponse={setLearningPathAPIResponse}
                  setLearningPathAPIResponseCopy={setLearningPathAPIResponseCopy}
                  setSearchFilterEmptyStateLP={setSearchFilterEmptyStateLP}
                  setNotificationData={setNotificationData}
                  setFilterDropdownLP={setFilterDropdownLP}
                  handleSearch={handleSearch}
                  filterDropdownLP={filterDropdownLP}
                  handleFilterApplyNewLP={handleFilterApplyNewLP}
                  FilterComponent={FilterComponent}
                  headingName={headingName}
                  sortData={sortData}
                />

                <MobileAudienceHeader
                  mobileTabSelected={mobileTabSelected}
                  searchUserGroup={searchUserGroup}
                  setSearchUserGroup={setSearchUserGroup}
                  setPredictedSearchUGM={setPredictedSearchUGM}
                  audienceGroupList={audienceGroupList}
                  handleSearchInputUGM={handleSearchInputUGM}
                  setNotificationData={setNotificationData}
                  setSearch={setSearch}
                  setCourses={setCourses}
                  courses={courses}
                  setLearning={setLearning}
                  setAudience={setAudience}
                  learning={learning}
                  audience={audience}
                  handleSearch={handleSearch}
                  filterDropdownUGM={filterDropdownUGM}
                  filterCourses={filterCourses}
                  filterDataUGM={filterDataUGM}
                  setFilterDataUGM={setFilterDataUGM}
                  setFilterDataTempUGM={setFilterDataTempUGM}
                  makeRequest={makeRequest}
                  setSearchFilterEmptyState={setSearchFilterEmptyState}
                  setAudienceGroupListCopy={setAudienceGroupListCopy}
                  setAudienceGroupList={setAudienceGroupList}
                  setErrorStateTemplate={setErrorStateTemplate}
                  isOnline={isOnline}
                  handleFilter={handleFilter}
                  setFilterDropdownUGM={setFilterDropdownUGM}
                  setFilterCourses={setFilterCourses}
                  handleFilterApplyUGM={handleFilterApplyUGM}
                  FilterComponent={FilterComponent}
                  sortData={sortData}
                  setIsLoading={setIsLoading}
                />
              </div>

              <MobileCoursesSection
                isLoading={isLoading}
                mobileTabSelected={mobileTabSelected}
                courseList={courseList}
                redirectCourses={redirectCourses}
                setSelectedNavigationOption={setSelectedNavigationOption}
                router={router}
                getMobileViewCourseState={getMobileViewCourseState}
                dateIsoToNormalDate={dateIsoToNormalDate}
                EmptyScreenComponent={EmptyScreenComponent}
                isSearchFilterEmptyStateCourses={isSearchFilterEmptyStateCourses}
              />

              <MobileAudienceSection
                isLoading={isLoading}
                mobileTabSelected={mobileTabSelected}
                TableListComponent={TableListComponent}
                audienceGroupList={audienceGroupList}
                isSearchFilterEmptyState={isSearchFilterEmptyState}
                EmptyScreenComponent={EmptyScreenComponent}
                redirectUserGroup={redirectUserGroup}
                setSelectedNavigationOption={setSelectedNavigationOption}
                router={router}
                showEmptyTemplateFor={showEmptyTemplateFor}
              />

              <MobileLearningSection
                isLoading={isLoading}
                mobileTabSelected={mobileTabSelected}
                learningPathAPIResponse={learningPathAPIResponse}
                redirectLearningPath={redirectLearningPath}
                setSelectedNavigationOption={setSelectedNavigationOption}
                router={router}
                dateIsoToNormalDate={dateIsoToNormalDate}
                showEmptyTemplateFor={showEmptyTemplateFor}
                EmptyScreenComponent={EmptyScreenComponent}
                isSearchFilterEmptyStateLP={isSearchFilterEmptyStateLP}
              />
            </div>
          ) : null}
        </div>
      </div>
      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

PortalComponent.propTypes = {};
const mapStateToProps = (state) => ({
  notificationData: state && state.uiState && state.uiState.notificationData,
});
const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(PortalComponent));
