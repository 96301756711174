import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from '../CourseWizard.module.scss';
import AdaptFileModalComponent from '../AdaptFileModal';
import NoteCardComponent from '../../../shared/noteCard';
import CardNewComponent from '../../../shared/cardNewComponent';
import CheckboxNew from '../../../shared/checkboxNew';
import Pills from '../../../shared/pills';
import DeleteModalComponent from '../../../audienceManagement/DeleteModal';
import LoadingShimmerCustom from '../../../shared/LoadingShimmerCustom';
const EditWizard = ({
  openAdaptFileModal,
  adaptFileState,
  fileDataTemp,
  data,
  setOpenAdaptFileModal,
  openInNewTab,
  setFileData,
  regexNameChinese,
  handleDataFill,
  addUserGroupList,
  deleteUserGroupList,
  disableStateUserManagement,
  handleDeleteUserTargetingGroups,
  handleDeleteUserGroups,
  disableClickBanner,
  handleFileUpload,
  uploadingFileButton,
  fileData,
  isCourseIDFetched,
  mainImageBackup,
  setImageData,
  setThumbnailImage169,
  setCourseMode,
  setNewCourseData,
  setNewCourseWizardState,
  uploadImageFromDesktop,
  uploadNewImagePath,
  setNewThumbnailImageData,
  thumbnailImage169,
  previewAdaptFileCreateNewCourse,
  previewData,
  categories,
  categoryData,
  setCategoryData,
  skills,
  skillsRef,
  setPillsDataSkills,
  pillsDataSkills,
  pillsAddAudienceGroup,
  pillsDataKeyword,
  filterListById,
  handleAddUserTargetingGroups,
  setNotificationData,
  newCourseID,
  addUserGroupListCopy,
  publishButtonAfterPreview,
  keyword,
  keywordsRef,
  setPillsDataKeyword,
  SearchListComponent,
  fetchSelectedCourse,
  setOpenDecommission,
  openDecommission,
  setIsArchive,
  isArchive,
  changeCourseStatus,
  closeCourseWizard,
  isLoading,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(<LoadingShimmerCustom className={[styles.card_holder_ugm_section].join(' ')} />);
    }
    return content;
  };
  return (
    <div
      className={[styles.inner_wrapper, styles.edit_course_wrapper, styles.preview_adapt, ''].join(
        ' ',
      )}
    >
      <div
        className={styles.prev_adapt_file_wrapper}
        onClick={async () => {
          if (
            // data?.course_state?.toLowerCase() === "live" ||
            // data?.courseState?.toLowerCase() === "live" ||
            uploadingFileButton
          ) {
            return;
          } else {
            await previewAdaptFileCreateNewCourse();
            // setOpenPreviewAdaptFile(true);
            // adaptFileState === "SUCCESS" && openInNewTab();
          }
        }}
      >
        <div
          className={
            (previewData ||
              (data && data?.adaptFileName?.length > 0) ||
              (fileData && fileData?.name?.length > 0)) &&
            !uploadingFileButton
              ? styles.txt
              : [styles.txt, styles.txt_disable].join(' ')
          }
        >
          Preview Adapt File
        </div>
        <div className={[styles.adapt_file_icon].join(' ')}></div>
      </div>

      <div className={styles.row_wrapper}>
        {openAdaptFileModal && (
          <AdaptFileModalComponent
            unzipState={adaptFileState}
            closeModal={() => {
              setOpenAdaptFileModal(false);
              fetchSelectedCourse(data?.courseId);
            }}
            openPreviewFile={() => {
              openInNewTab();
              fetchSelectedCourse(data?.courseId);
              // updateCourseDetails(data?.courseId,{adaptFileName:fileData?.name,courseState:data?.courseState
              //   //  ,version :versionNum
              // })
              setFileData(fileDataTemp);
            }}
          />
        )}
        {/* Info container block */}
        <div className={styles.info_container}>
          <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.setting_icon].join(' ')}></span>
                <span>General Settings</span>
              </div>
            </div>
            <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Category</div>
              </div>
              <div className={[styles.input_wrapper, styles.input_capitalize].join(' ')}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                ) : (
                  <SearchListComponent
                    list={categories}
                    varient="dropdown"
                    defaultInputData={categoryData ? categoryData : ''}
                    handlePillList={(title, dataWithTitle) => {
                      //setPillsData(data);
                      setCategoryData(title);
                      handleDataFill('category', dataWithTitle);
                    }}
                  />
                )}
              </div>
            </div>
            <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>English Course Author / Speaker Name</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.input} />
                ) : (
                  <input
                    type={'text'}
                    name="englishSpeaker"
                    maxLength="30"
                    placeholder={'Simon Sinek'}
                    value={data && data?.englishSpeaker}
                    className={styles.input}
                    onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Chinese Course Author / Speaker Name</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.input} />
                ) : (
                  <input
                    maxLength="30"
                    type={'text'}
                    name="chineseSpeaker"
                    value={data && data?.chineseSpeaker}
                    placeholder={'此处显示'}
                    className={styles.input}
                    onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
            <div className={[styles.title_wrapper, styles.padding_class].join(' ')}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.timer_icon].join(' ')}></span>
                <span>Course Runtime</span>
              </div>
            </div>
            <div className={styles.course_time_wrapper}>
              <div className={styles.txt}>Total Course Runtime</div>
              <div className={styles.time_spend_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.hrs_box} />
                ) : (
                  <input
                    type={'number'}
                    name="duration"
                    defaultValue={data?.duration === 0 ? '' : data && Number(data?.duration)}
                    value={data?.duration === 0 ? '' : data && Number(data?.duration)}
                    placeholder={'0'}
                    min="0"
                    max="20000"
                    className={styles.hrs_box}
                    onChange={(e) => handleDataFill(e.target.name, Number(e.target.value))}
                    onFocus={(e) => (e.target.placeholder = '')}
                    onBlur={(e) => (e.target.placeholder = '0')}
                  />
                )}
                <div className={styles.txt_xs}>Minutes</div>
              </div>
            </div>
          </div>
        </div>
        {/* Info container block */}
        <div className={styles.info_container}>
          <div className={[styles.inr_wrapper, styles.margin_zero, 'col-md-12 col-lg-6'].join(' ')}>
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.info_icon].join(' ')}></span>
                <span>Course Information</span>
              </div>
              <div className={styles.txt}>
                English Course Title and Description are required to save a course in a draft state.
                All fields are mandatory (except Achievements, User Group Targeting, Skills You Will
                Gain and Keyword Tagging) to publish a course.{' '}
              </div>
            </div>
          </div>
        </div>
        {/* Info container block */}
        <div className={[styles.info_container, styles.error_wrapper].join(' ')}>
          <div
            className={[styles.inr_wrapper, styles.margin_class, 'col-md-12 col-lg-6'].join(' ')}
          >
            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                data?.englishTitle?.length < 3 && data?.englishTitle?.length != 0
                  ? styles.error_wrapper
                  : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>
                  English Course Title
                  {/* <span className={styles.astric}>*</span> */}
                </div>
                <div className={styles.tag_text}>Max 30 characters</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.input} />
                ) : (
                  <input
                    type={'text'}
                    name="englishTitle"
                    maxLength="30"
                    placeholder={'Enter Course Title'}
                    value={data && data?.englishTitle}
                    className={styles.input}
                    pattern={regexNameChinese}
                    onChange={(e) => {
                      e.target.value.length === 0 && handleDataFill(e.target.name, '');
                      handleDataFill(
                        e.target.name,
                        e.target.value?.toString()?.replaceAll(regexNameChinese, ''),
                      );
                    }}
                  />
                )}
                <div className={styles.error_icon_red}></div>
              </div>
              {data?.englishTitle?.length < 3 && data?.englishTitle?.length != 0 ? (
                <div className={styles.error_message}>
                  {/* Need 3 or more characters to Create New Course */}
                  Need 3 or more characters. Valid characters include A-z, 0-9, ., :, ?, !, -, “ ”,
                  ‘’
                </div>
              ) : null}
            </div>
            <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Description</div>
                <div className={styles.tag_text}>Max 500 characters</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.textarea} />
                ) : (
                  <textarea
                    type={'text'}
                    maxLength="500"
                    name="englishDescription"
                    value={data && data?.englishDescription}
                    placeholder={'Enter Course Description'}
                    className={styles.textarea}
                    onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
            <div
              className={[
                styles.form_field,
                styles.info_wrapper,
                data?.chineseTitle?.length < 3 && data?.chineseTitle?.length != 0
                  ? styles.error_wrapper
                  : ' ',
              ].join(' ')}
            >
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Chinese Course Title</div>
                <div className={styles.tag_text}>Max 30 characters</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.input} />
                ) : (
                  <input
                    type={'text'}
                    maxLength="30"
                    name="chineseTitle"
                    value={data && data?.chineseTitle?.toString()?.replaceAll(regexNameChinese, '')}
                    placeholder={'输入课程名称'}
                    className={styles.input}
                    pattern={regexNameChinese}
                    onChange={(e) => {
                      e.target.value.length === 0 && handleDataFill(e.target.name, '');
                      handleDataFill(
                        e.target.name,
                        e.target.value?.toString()?.replaceAll(regexNameChinese, ''),
                      );
                    }}
                  />
                )}
                <div className={styles.error_icon_red}></div>
              </div>
              {data?.chineseTitle?.length < 3 && data?.chineseTitle?.length != 0 ? (
                <div className={styles.error_message}>
                  {/* Need 3 or more characters to Create New Course */}
                  Need 3 or more characters. Valid characters include A-z, 0-9, ., :, ?, !, -, “ ”,
                  ‘’
                </div>
              ) : null}
            </div>
            <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Description</div>
                <div className={styles.tag_text}>Max 500 characters</div>
              </div>
              <div className={styles.input_wrapper}>
                {isLoading ? (
                  <LoadingShimmerCustom className={styles.textarea} />
                ) : (
                  <textarea
                    type={'text'}
                    maxLength="500"
                    name="chineseDescription"
                    value={data && data?.chineseDescription}
                    placeholder={'输入课程描述'}
                    className={styles.textarea}
                    onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Info container block */}
        <div className={styles.info_container}>
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          >
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.user_icon].join(' ')}></span>
                <span>User Group Targeting</span>
              </div>
              <div className={styles.txt}>
                Target this course to the User Groups mentioned below. If you do not want this
                course to be accessible to a particular audience group(s), you can remove them from
                the course accesibility list. You can specify the Course type by selecting from the
                options given below for each User Group.{' '}
              </div>
            </div>
            {addUserGroupList?.length == 0 && deleteUserGroupList?.length > 0 && (
              <div className={styles.note_wrapper}>
                <NoteCardComponent />
              </div>
            )}
            <div className={styles.card_list_wrapper}>
              {addUserGroupList?.length > 0 && (
                <div className={styles.title}>User Groups with Course Access</div>
              )}
              <div className={styles.card_wrapper}>
                {!isLoading &&
                  addUserGroupList?.map((element, index) => {
                    return (
                      <CardNewComponent
                        key={index}
                        ugCourseTitle={element?.title}
                        id={element?.id || element?.audGrpId}
                        isDisabled={disableStateUserManagement}
                        onHandleClickRadio={(ele, id) => {
                          handleDeleteUserTargetingGroups(
                            {
                              courseType: ele,
                              id: element?.id || element?.audGrpId,
                              title: element?.title,
                              audienceGrpDescription:
                                element?.audienceGrpDescription || element.description,
                            },
                            addUserGroupList,
                          );
                        }}
                        courseType={element?.courseType}
                        onButtonClick={() => {
                          handleDeleteUserGroups({
                            courseType: element?.courseType,
                            id: element?.id || element?.audGrpId,
                            title: element?.title,
                            audienceGrpDescription:
                              element?.audienceGrpDescription || element.description,
                          });
                        }}
                        disableClickBanner={() => {
                          disableClickBanner();
                        }}
                        bonusIcon={true}
                        audienceGrpDescription={element?.audienceGrpDescription}
                      />
                    );
                  })}
                {isLoading ? getShimmerList(5) : null}
              </div>
            </div>
            <div className={styles.card_list_wrapper}>
              {deleteUserGroupList?.length > 0 && (
                <div className={styles.title}>User Groups without Course Access</div>
              )}
              <div className={styles.card_wrapper}>
                {!isLoading &&
                  deleteUserGroupList?.map((element, index) => {
                    return (
                      <CardNewComponent
                        key={index}
                        withoutCourses={true}
                        ugCourseTitle={element?.title}
                        id={element?.id || element?.audGrpId}
                        isDisabled={disableStateUserManagement}
                        onHandleClickRadio={(ele, id) => {
                          handleDeleteUserTargetingGroups({
                            courseType: ele,
                            id: element?.id || element?.audGrpId,
                            title: element?.title,
                            audienceGrpDescription:
                              element?.audienceGrpDescription || element.description,
                          }),
                            deleteUserGroupList;
                        }}
                        courseType={element?.courseType}
                        onButtonClick={() => {
                          handleAddUserTargetingGroups({
                            courseType: 'General visibility',
                            id: element?.id || element?.audGrpId,
                            title: element?.title,
                            audienceGrpDescription:
                              element?.audienceGrpDescription || element.description,
                          });
                        }}
                        disableClickBanner={() => {
                          disableClickBanner();
                        }}
                        bonusIcon={true}
                        audienceGrpDescription={element?.audienceGrpDescription}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.achievements_icon].join(' ')}></span>
                <span>Achievements</span>
              </div>
              <div className={styles.txt}>
                Add a certificate to a course to reward the user for completion. They will get an
                automatically generated certificate with the Course Name, their Name, and Date.
                Certificate addition to a course is only possible during creation of a course. It
                cannot be added or removed later.
              </div>
            </div>
            <div className={styles.certificate_wrapper}>
              <div className={styles.title}>Certificate</div>

              {isLoading ? (
                <div className={styles.checkbox_container}>
                  {' '}
                  <div className={styles.outerDiv}>
                    {' '}
                    <LoadingShimmerCustom className={styles.achievement_section} />
                  </div>{' '}
                  <div className={styles.disable}>
                    ABOs recieve a Certificate for completing this course.
                  </div>{' '}
                </div>
              ) : (
                <div className={styles.checkbox_container}>
                  {/* <CheckboxComponent
              checked={data && data?.isCertificateAvailable}
              checkFunc={() => {
                data && data?.isCertificateAvailable
                  ? handleDataFill("isCertificateAvailable", Boolean(false))
                  : handleDataFill("isCertificateAvailable", Boolean(true));
              }}
            /> */}
                  <CheckboxNew
                    isChecked={data?.isCertificateAvailable}
                    handleCheckClick={() => {
                      data && data?.isCertificateAvailable
                        ? handleDataFill('isCertificateAvailable', Boolean(false))
                        : handleDataFill('isCertificateAvailable', Boolean(true));
                    }}
                    isDisabled={true}
                    // label={
                    //   "ABOs recieve a Certificate for completing this course."
                    // }
                  />
                  <div className={styles.disable}>
                    ABOs recieve a Certificate for completing this course.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Info container block */}
        <div className={styles.info_container}>
          <div
            className={[styles.inr_wrapper, styles.thumbnail_wrapper, 'col-md-12 col-lg-6'].join(
              ' ',
            )}
          >
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.thumbnail_icon].join(' ')}></span>
                <span>Thumbnail Image</span>
              </div>
            </div>
            {!mainImageBackup && !isLoading ? (
              <div
                className={[
                  styles.pre_browse_wrapper,
                  uploadingFileButton ? styles.disabled : '',
                  styles.uploadig_state,
                  '',
                ].join(' ')}
              >
                <div className={styles.browse_img_txt_wrapper}>
                  <div className={styles.browse_img}></div>
                  <div
                    className={styles.browse_text}
                    onClick={() => {
                      if (!uploadingFileButton) {
                        setCourseMode('FROM_COURSE_WIZARD');
                        router.push('/Contentmanagement');
                        // setOpenImageLib();
                        setNewCourseData({
                          data: data,
                          adaptFile: fileData,
                          skills: pillsDataSkills,
                          keyword: pillsDataKeyword,
                          newCourseID: newCourseID,
                          categoryData: categoryData,
                          pillsAddAudienceGroup: pillsAddAudienceGroup,
                          isPreviewAdaptFile: publishButtonAfterPreview,
                          addUserGroupList: addUserGroupList,
                          addUserGroupListCopy: addUserGroupListCopy,
                          deleteUserGroupList: deleteUserGroupList,
                        });
                        //setNewAdaptFile(fileData);
                        setNewCourseWizardState({
                          courseWizardOpen: true,
                          courseWizardStateOpen: 'edit',
                          section: '',
                        });
                      }
                      setThumbnailImage169(null);
                    }}
                  >
                    Choose Image from Image Library
                  </div>
                </div>
                <div className={styles.text}>or</div>
                <input
                  type={'file'}
                  accept=".jpg,.jpeg"
                  onChange={(event) => {
                    if (!uploadingFileButton) {
                      setCourseMode('FROM_COURSE_WIZARD');
                      setImageData({
                        imageName: null,
                        imageDescription: null,
                        pillsData: null,
                      });
                      uploadImageFromDesktop(event, event.target.files[0]);
                      setNewCourseData({
                        data: data,
                        adaptFile: fileData,
                        skills: pillsDataSkills,
                        keyword: pillsDataKeyword,
                        newCourseID: newCourseID,
                        categoryData: categoryData,
                        pillsAddAudienceGroup: pillsAddAudienceGroup,
                        isPreviewAdaptFile: publishButtonAfterPreview,
                        addUserGroupList: addUserGroupList,
                        addUserGroupListCopy: addUserGroupListCopy,
                        deleteUserGroupList: deleteUserGroupList,
                      });
                      // setNewAdaptFile(fileData);
                      setNewCourseWizardState({
                        courseWizardOpen: true,
                        courseWizardStateOpen: 'edit',
                        section: '',
                      });
                    }
                  }}
                  hidden={true}
                  multiple={false}
                  id={'chooseImageEdit'}
                />
                <label htmlFor="chooseImageEdit">
                  <div className={[styles.browse_text, styles.secondary_text].join(' ')}>
                    Choose Image from Local Device
                  </div>
                </label>
              </div>
            ) : mainImageBackup && !isLoading ? (
              <div className={[styles.thumbnail_inner_wrapper, ''].join(' ')}>
                <div className={[styles.top_section, ' '].join(' ')}>
                  <div className={[styles.btn_wrapper_uaf, styles.margin_zero].join(' ')}>
                    <div
                      className={styles.txt}
                      onClick={() => {
                        if (!uploadingFileButton) {
                          setCourseMode('FROM_COURSE_WIZARD');
                          setImageData({
                            imageName: null,
                            imageDescription: null,
                            pillsData: null,
                          });
                          // setOpenImageLib();
                          router.push('/Contentmanagement');
                          setNewCourseData({
                            data: data,
                            adaptFile: fileData,
                            skills: pillsDataSkills,
                            keyword: pillsDataKeyword,
                            newCourseID: newCourseID,
                            categoryData: categoryData,
                            pillsAddAudienceGroup: pillsAddAudienceGroup,
                            isPreviewAdaptFile: publishButtonAfterPreview,
                            addUserGroupList: addUserGroupList,
                            addUserGroupListCopy: addUserGroupListCopy,
                            deleteUserGroupList: deleteUserGroupList,
                          });
                          //setNewAdaptFile(fileData);
                          setNewCourseWizardState({
                            courseWizardOpen: true,
                            courseWizardStateOpen: 'edit',
                            section: '',
                          });
                        }
                      }}
                    >
                      Choose From Image Library
                    </div>
                    <div className={styles.book_icon}></div>
                  </div>
                  <div
                    className={styles.insturction_text_wrapper}
                    onClick={() => {
                      if (!uploadingFileButton) {
                        //setNewImageData(null);
                        setCourseMode('FROM_COURSE_WIZARD');
                        uploadNewImagePath();
                        setNewCourseData({
                          data: data,
                          adaptFile: fileData,
                          skills: pillsDataSkills,
                          keyword: pillsDataKeyword,
                          newCourseID: newCourseID,
                          categoryData: categoryData,
                          pillsAddAudienceGroup: pillsAddAudienceGroup,
                          isPreviewAdaptFile: publishButtonAfterPreview,
                          addUserGroupList: addUserGroupList,
                          addUserGroupListCopy: addUserGroupListCopy,
                          deleteUserGroupList: deleteUserGroupList,
                        });
                        setNewThumbnailImageData(mainImageBackup);
                        //setNewAdaptFile(fileData);
                        setNewCourseWizardState({
                          courseWizardOpen: true,
                          courseWizardStateOpen: 'edit',
                          section: '',
                        });
                      }
                    }}
                  >
                    <div className={styles.info_text}>Upload New Image </div>
                    <div className={styles.left_arrow_icon}></div>
                  </div>
                </div>
                <div className={[styles.img_wrapper, ' '].join(' ')}>
                  <div className={styles.img}>
                    <img
                      // src={ImageDetail?.bannerImage ? ImageDetail.bannerImage : ""}
                      src={
                        //newThumbnailId?.length > 0 &&
                        thumbnailImage169?.img ? thumbnailImage169?.img : mainImageBackup
                      }
                      alt="thumbnail"
                      className={styles.selected_img}
                    />
                  </div>
                </div>
              </div>
            ) : isLoading ? (
              <LoadingShimmerCustom className={styles.pre_browse_wrapper} />
            ) : null}
            <div className={styles.tag_text}> 3MB max | .jpg, jpeg</div>
          </div>
          <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.content_book_icon].join(' ')}></span>
                <span>Course Content</span>
              </div>
              <div className={styles.txt}>
                Replacing Adapt files is a great way to make minor content tweaks, or fix spelling
                errors. Adapt files can only be updated while the course is offline. This process
                happens separately from changes made to the rest of the course details page.
                <span className={styles.bold_text}>
                  Once an Adapt file is replaced, the old one is deleted and cannot be undone by
                  clicking on ‘Cancel’ button.
                </span>
              </div>
            </div>
            {isLoading ? (
              <LoadingShimmerCustom className={styles.input} />
            ) : (
              <input
                type={'file'}
                //accept=".zip,.rar,.7z"
                accept=".zip"
                onChange={(event) => {
                  handleFileUpload(event, data?.id || data?.courseId);
                }}
                hidden={true}
                multiple={false}
                id={'uploadFile'}
                disabled={
                  data?.course_state?.toLowerCase() === 'live' ||
                  data?.courseState?.toLowerCase() === 'live' ||
                  isLoading ||
                  uploadingFileButton
                }
              />
            )}
            <label htmlFor="uploadFile" style={{display: 'block', width: '100%'}}>
              <div
                className={
                  uploadingFileButton &&
                  (data?.course_state?.toLowerCase() !== 'live' ||
                    data?.courseState?.toLowerCase() !== 'live')
                    ? [styles.btn_wrapper_uaf, styles.inprogress_state].join(' ')
                    : (fileData?.name || data?.adaptFileName || isCourseIDFetched) &&
                      (data?.course_state?.toLowerCase() !== 'live' ||
                        data?.courseState?.toLowerCase() !== 'live')
                    ? [styles.btn_wrapper_uaf, styles.final_state].join(' ')
                    : styles.btn_wrapper_uaf
                }
                onClick={() => {
                  if (
                    data?.course_state?.toLowerCase() === 'live' ||
                    data?.courseState?.toLowerCase() === 'live'
                  ) {
                    setNotificationData({
                      show: true,
                      type: 'FAILURE',
                      title: 'Replacing Adapt file is disabled for Live courses.',
                      description:
                        'Uploading new Adapt file is possible only when the course is turned Offline. You can change the status in the Publish settings.',
                    });
                  }
                }}
              >
                <div className={styles.txt}>
                  {uploadingFileButton ? 'File Uploading' : 'Replace Adapt Files'}
                </div>
                <div className={styles.upload_icon}></div>
              </div>
              {uploadingFileButton && (
                <div className={styles.note_text}>
                  Note: Please do not navigate to any other page when the file is uploading.
                </div>
              )}
            </label>
            {(fileData?.name || data?.adaptFileName) && (
              <div className={[styles.attachement_wrapper, ''].join(' ')}>
                <div className={styles.txt}>{fileData?.name || data?.adaptFileName}</div>
              </div>
            )}
            <div className={styles.warning_section}>
              <div className={styles.message_section}>
                <div className={[styles.heading, styles.mb_5].join(' ')}>
                  When replacing Adapt Files avoid adding or removing sections from the file when
                  building the adapt file as this causes a negative user experience to our ABOs. We
                  recommend creating a new course with the new file instead.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Info container block */}
        <div className={styles.info_container}>
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          >
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.skills_icon].join(' ')}></span>
                <span>Skills You Will Gain</span>
              </div>
              <div className={styles.txt}>
                Choose from a pre-loaded list of Skills that aid the user in choosing which courses
                to take. Skills appear on the course details drawer. Skills are automatically
                translated into all language supported by your market. You may select up to 5 skills
                to display on a course.
              </div>
            </div>
            <div className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}>
              {isLoading ? (
                <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
              ) : (
                <div className={styles.input_wrapper} ref={skillsRef}>
                  <SearchListComponent
                    list={skills}
                    reference={skillsRef}
                    varient="textInput"
                    placeHolderText={
                      data?.skills?.length >= 5 ? 'All Skills Added' : 'Enter Skills You Will Gain'
                    }
                    //defaultInputData={data && data.category}
                    pillsLimit={5}
                    handlePillList={(data, dataWithTitle) => {
                      setPillsDataSkills(data);
                      handleDataFill('skills', dataWithTitle);
                    }}
                    defaultPillList={pillsDataSkills}
                    disabledInput={data?.skills?.length >= 5}
                  />
                  <div className={styles.count_num}>{`${
                    pillsDataSkills?.length ? pillsDataSkills?.length : '0'
                  }/5`}</div>
                </div>
              )}
            </div>
            {!isLoading && (
              <div className={[styles.pills_container, 'col-xl-12 col-lg-12 pull-left'].join(' ')}>
                <Pills
                  pillData={pillsDataSkills}
                  isClose={true}
                  isBold={false}
                  onClosePill={(item) => {
                    filterListById(
                      item.id,
                      pillsDataSkills,
                      setPillsDataSkills,
                      'skills',
                      handleDataFill,
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          >
            <div className={styles.title_wrapper}>
              <div className={styles.title}>
                <span className={[styles.icon, styles.tag_icon].join(' ')}></span>
                <span>Keyword Tagging</span>
              </div>
              <div className={styles.txt}>
                Keyword Tags are used by the Academy to aid in search and filtering both for
                front-end app users. These keywords do not appear on course detail drawers. Add all
                relevant tags to the course.
              </div>
            </div>
            <div
              className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}
              ref={keywordsRef}
            >
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Add Tags</div>
              </div>
              {isLoading ? (
                <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
              ) : (
                <SearchListComponent
                  list={keyword}
                  reference={keywordsRef}
                  varient="textInput"
                  placeHolderText="Add Keywords and Enter"
                  //defaultInputData={data && data.category}
                  handlePillList={(data, dataWithTitle) => {
                    setPillsDataKeyword(data);
                    handleDataFill('keywords', dataWithTitle);
                  }}
                  pillsLimit={100}
                  defaultPillList={pillsDataKeyword}
                />
              )}
            </div>
            {!isLoading && (
              <div className={[styles.pills_container, 'col-xl-12 col-lg-12 pull-left'].join(' ')}>
                <Pills
                  pillData={pillsDataKeyword}
                  isClose={true}
                  isBold={false}
                  onClosePill={(item) => {
                    filterListById(
                      item.id,
                      pillsDataKeyword,
                      setPillsDataKeyword,
                      'keywords',
                      handleDataFill,
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {/* Info container block */}
        <div className={styles.info_container}>
          <div
            className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(' ')}
          >
            <div
              className={styles.title_wrapper}
              onClick={() => {
                !uploadingFileButton && setOpenDecommission(!openDecommission);
              }}
            >
              <div className={styles.title}>
                <span className={[styles.icon, styles.warn_icon].join(' ')}></span>
                <span>Decommission Course</span>
                <div
                  className={
                    openDecommission
                      ? [styles.arw_blue_icon, styles.is_decommission_open].join(' ')
                      : styles.arw_blue_icon
                  }
                ></div>
              </div>

              {openDecommission && (
                <div className={styles.txt}>
                  This will permanently remove a course from the Academy App. Users who have taken
                  the course will still see the archived version in their Completed Courses section
                  but may not re-take it. No user can start the course once archived. This action
                  cannot be undone.
                </div>
              )}
            </div>
            {openDecommission && !isLoading && (
              <div
                className={[styles.btn_wrapper_uaf, styles.btn_wrapper_dec].join(' ')}
                onClick={() => setIsArchive(true)}
              >
                <div className={[styles.icon, styles.gift_icon].join(' ')}></div>
                <div className={styles.txt}>Archive This Course</div>
              </div>
            )}
            {openDecommission && isLoading ? (
              <LoadingShimmerCustom
                className={[styles.btn_wrapper_uaf, styles.btn_wrapper_dec].join(' ')}
              />
            ) : null}
          </div>
        </div>
      </div>
      {isArchive && (
        <DeleteModalComponent
          modalType={'ARCHIVE'}
          closeDeleteModal={() => setIsArchive(false)}
          saveDeleteChanges={async () => {
            await changeCourseStatus(data?.courseId, data, 'archive');
          }}
          name={
            data?.englishTitle ? data.englishTitle : data?.chineseTitle ? data.chineseTitle : ''
          }
          deleteCall={async () => {
            await changeCourseStatus(data?.courseId, data, 'archive');
          }}
        />
      )}
    </div>
  );
};
export default EditWizard;
