import React, {createRef, useEffect, useRef, useState} from 'react';
import styles from '../createNewLearningPath/CreateNewLP.module.scss';
import SearchListComponent from '../../shared/searchListComponent';
import Pills from '../../shared/pills';
import {ReactComponent as CourseSequencingImg} from '../../../styles/images/Illustration_BuildingYourA.svg';
import LoadingShimmerCustom from '../../shared/LoadingShimmerCustom';

//import SearchListComponent from '';
const CategorySkillsTagsLP = ({
  keyword,
  pillsDataKeyword,
  skillsListFull,
  keywordsListFull,
  categoriesListFull,
  setSkillsListFull,
  setPillsDataKeyword,
  filterListById,
  handleDataFill,
  selectedSkills,
  handleListRemove,
  handleListAdd,
  refreshList,
  setSubheader,
  addedCourseList,
  isLoadingApi,
  isRefresh,
  fetchSkills,
}) => {
  const keywordsRef = createRef();
  const [width, setWidth] = useState(0);
  const [fullSelectedList, setFullselectedlist] = useState([]);
  const [unselectedSkills, setUnselectedSkills] = useState([]);
  const [selectedFullSkills, setSelectedFullSkills] = useState([]);
  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    //setHeight(window.innerHeight);
  };
  const [tagsDropdownOpen, setTagsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(isLoadingApi);
  }, [isLoadingApi]);
  useEffect(() => {
    if (isRefresh == false && !isLoadingApi) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    } else if (isRefresh == true) {
      setIsLoading(true);
    }
  }, [isRefresh]);
  useEffect(() => {
    updateWindowDimensions();
    refreshList();
    setFullselectedlist([...keywordsListFull, ...pillsDataKeyword]);
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  useEffect(() => {
    setFullselectedlist([...keywordsListFull, ...pillsDataKeyword]);
  }, [pillsDataKeyword, keywordsListFull]);
  const updateKeywordList = (data, dataWithID) => {
    let tempArr = [];
    let results = [];
    tempArr = data.filter(({id: id1}) => !keywordsListFull.some(({id: id2}) => id2 === id1));
    tempArr?.forEach((data) => {
      results.push(data.id);
    });
    setPillsDataKeyword(tempArr);
    handleDataFill('keywords', results);
  };
  useEffect(async () => {
    let tempArr = [];
    skillsListFull?.forEach((element) => {
      var findItem = tempArr.find((dld) => dld.id === element.id);
      var findItemSelected = selectedSkills.find((dld) => dld.id === element.id);

      if (!findItem && !findItemSelected)
        tempArr.push({
          id: element.id,
          title: element.title,
          selected: false,
        });
    });
    setUnselectedSkills(tempArr);
    if (tempArr?.length == 0) {
      await fetchSkills();
    }
    setSelectedFullSkills([...new Set(selectedSkills)]);
  }, [skillsListFull, selectedSkills]);
  return (
    <div
      className={[
        styles.inner_wrapper,
        styles.category_wrapper,
        addedCourseList?.length <= 1 ? styles.empty_course_category : '',
        '',
      ].join(' ')}
    >
      {' '}
      {/* When missing course add empty_course_category */}
      {addedCourseList?.length > 1 ? (
        <div className={styles.row_wrapper}>
          {/* Info container  block */}
          <div className={[styles.info_container, styles.category_inner_wrapper].join(' ')}>
            <div
              className={[styles.inr_wrapper, styles.margin_pad_x, 'col-md-12 col-lg-12'].join(' ')}
            >
              <div className={styles.title_wrapper}>
                <div className={styles.title}>Category</div>
                <div className={styles.txt}>
                  Learning Paths inherit the categories of the Courses added to them. You cannot add
                  categories to Learning Paths. This Learning Path will be displayed in these
                  following categories.
                </div>
              </div>

              {isLoading ? (
                <div className={styles.category_list_wrapper}>
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                </div>
              ) : (
                <div className={styles.category_list_wrapper}>
                  {categoriesListFull?.map((ele, index) => {
                    return (
                      <div className={styles.list} key={index}>
                        {ele.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {/* Info container 2nd block */}
          <div className={[styles.info_container, styles.skills_inner_wrapper].join(' ')}>
            <div
              className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-12'].join(
                ' ',
              )}
            >
              <div className={styles.title_wrapper}>
                <div className={styles.title}>{`Skills You Will Gain (${
                  selectedFullSkills?.length || 0
                }/5)`}</div>
                <div className={styles.txt}>
                  Skill You Will Gain of all the courses have been listed below. Select any 5 skills
                  that are relevant to this learning path and these Skills will be seen to the ABO
                  on the Front End. By completing this Learning Path, the ABOs will gain all the
                  Skills listed below.
                </div>
              </div>

              {isLoading ? (
                <div className={styles.category_list_wrapper}>
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                  <LoadingShimmerCustom
                    className={[styles.list, styles.category_shimmer].join(' ')}
                  />
                </div>
              ) : (
                <div className={styles.pills_add_wrapper}>
                  {selectedFullSkills.map((data, index) => {
                    return (
                      <div
                        className={[styles.pills, styles.selected].join(' ')}
                        key={index}
                        onClick={() => {
                          handleListRemove(data);
                        }}
                      >
                        {/* <div className={[styles.pill_img, styles.dollar].join(' ')} /> */}
                        <div className={styles.pill_txt}>{data.title}</div>
                        <div className={[styles.pill_add, styles.add_circle].join(' ')} />
                      </div>
                    );
                  })}
                  {unselectedSkills.map((data, index) => {
                    return (
                      <div
                        className={[styles.pills].join(' ')}
                        key={index}
                        onClick={() => {
                          selectedSkills.length <= 4 && handleListAdd(data);
                        }}
                      >
                        {/* <div className={[styles.pill_img, styles.dollar].join(' ')} /> */}
                        <div className={styles.pill_txt}>{data.title}</div>
                        <div className={[styles.pill_add, styles.add_circle].join(' ')} />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div
              className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-12'].join(
                ' ',
              )}
            >
              <div className={styles.title_wrapper}>
                <div className={styles.title}>Keyword Tagging</div>
                <div className={styles.txt}>
                  Learning Paths automatically ineherit all the tags of the courses you add. If you
                  would like to add additional tags to this learning path you may do so below.
                </div>
              </div>

              <div className={styles.form_outer_field}>
                <div
                  className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}
                  ref={keywordsRef}
                >
                  <div className={'col-md-12 col-lg-6'}>
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>Add Tags</div>
                    </div>

                    {isLoading ? (
                      <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                        <LoadingShimmerCustom
                          className={[styles.search_list_wrapper_loading, styles.tags_shimmer].join(
                            ' ',
                          )}
                        />
                      </div>
                    ) : (
                      <SearchListComponent
                        list={keyword}
                        reference={keywordsRef}
                        placeHolderText="Add Keywords and Enter"
                        varient="textInput"
                        handlePillList={(data, dataWithID) => {
                          updateKeywordList(data, dataWithID);
                        }}
                        pillsLimit={100}
                        defaultPillList={fullSelectedList}
                      />
                    )}
                  </div>
                  {!isLoading && (
                    <div className={[styles.pills_container, 'col-xl-12 col-lg-12'].join(' ')}>
                      <Pills
                        pillData={pillsDataKeyword}
                        isClose={true}
                        isBold={false}
                        bgBlue={true}
                        onClosePill={(item) => {
                          filterListById(
                            item.id,
                            pillsDataKeyword,
                            setPillsDataKeyword,
                            'keywords',
                            handleDataFill,
                          );
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className={styles.tags_inherited_wrapper}>
                  <div
                    className={styles.collapsible_wrapper}
                    onClick={() => {
                      setTagsDropdownOpen(!tagsDropdownOpen);
                    }}
                  >
                    <div className={styles.text}>Tags inherited from Courses</div>
                    <div
                      className={[
                        tagsDropdownOpen ? styles.down_arw_icon_open : styles.down_arw_icon,
                      ].join(' ')}
                    />
                  </div>
                  <div className={styles.row_wrapper_margin}>
                    <div
                      className={[
                        styles.pills_container,
                        'col-xl-12 col-lg-12',
                        tagsDropdownOpen ? '' : 'hide',
                      ].join(' ')}
                    >
                      <Pills
                        pillData={keywordsListFull}
                        isClose={false}
                        isBold={false}
                        color={'black'}
                        onClosePill={(item) => {
                          // filterListById(
                          //   item.id,
                          //   pillsDataKeyword,
                          //   setPillsDataKeyword,
                          //   'keywords',
                          //   handleDataFill,
                          // );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.main_wrapper_empty_lp} style={{padding: '0px'}}>
          {isLoading ? (
            <LoadingShimmerCustom
              className={[styles.emptyLp_wrapper, styles.emptyLp_wrapper_shimmer].join(' ')}
            />
          ) : (
            <div className={styles.emptyLp_wrapper} style={{height: '66vh'}}>
              <div className={styles.sequencing_empty_img}>
                <CourseSequencingImg />
              </div>
              <div className={styles.sequencing_empty_text_header}>Missing Courses!</div>
              <div className={styles.sequencing_empty_text_subheader}>
                {width > 600 ? (
                  <div>
                    <div>
                      {' '}
                      As there are no Courses added to this Learning Path, Course Sequencing is
                      disabled.
                    </div>
                    <div>
                      Add a minimum of 2 or more courses. Go to ‘Course Addition’ tab to add courses
                      to this Learning Path.
                    </div>
                  </div>
                ) : (
                  <div>
                    As there are no Courses added to this Learning Path, Course Sequencing is
                    disabled. Add a minimum of 2 or more courses. Go to ‘Course Addition’ tab to add
                    courses to this Learning Path.
                  </div>
                )}
              </div>
              <div
                className={styles.sequencing_empty_text_button_wrapper}
                onClick={() => setSubheader('Course Addition')}
              >
                <div className={styles.sequencing_empty_text_button}>Course Addition</div>
                <div className={styles.sequencing_empty_text_button_line}></div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

CategorySkillsTagsLP.propTypes = {};

export default CategorySkillsTagsLP;
