import React, {useEffect, useState, useRef} from 'react';
// import PropTypes from "prop-types";
// import _ from 'lodash';
import Header from '../shared/header';
import DeleteModal from '../audienceManagement/DeleteModal';
import styles from './categoriesSkillsTags.module.scss';
import {connect} from 'react-redux';
import TopBarComponenent from '../audienceManagement/TopBar';
import TableListComponentCST from './TableComponentCST';
import {
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setNotificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
} from '../../redux/actions/uiActions';
import DrawerComponentCST from './DrawerCST';
import CreateNewCST from './CreateNewCST';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
import EmptyScreenComponent from '../shared/emptyScreen';
import {setErrorStateTemplate} from '../../redux/actions/uiActions';
import Notification from '../shared/notification';
import config from '../../config';
import {useHistory} from 'react-router-dom';
import {
  fetchCategorySkillsTags,
  fetchCategorySkillsTagsListWithParams,
  fetchCategorySkillsTagsDetails,
  handleSearchInput,
  handleFilterApplyNew,
  closeDrawerModal,
  handleOnClickEditCourse,
  handleOnClickEditLearningPath,
  handleCloseCST,
  handleCSTTranslationChange,
  refreshList,
  handleDeleteCST,
  selectedCategorySkillsTags,
  initialUseEffect,
  handleTranslationChange,
  searchVariableUseEffect,
} from '../utils/CategoriesSkillsTagsManagementUtils/CSTLandingPage';

import {
  handleCSTCreatModelCloseOpen,
  handleEnglishTranslationCreateCST,
  handleChineseTranslationCreateCST,
  handleCreateNewCSTButtonSaveChanges,
} from '../utils/CategoriesSkillsTagsManagementUtils/CreatCST';

const CategoriesSkillsTagsManagementComponent = ({
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setErrorStateTemplate,
  notificationData,
  setNotificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const [isDelete, setDelete] = useState(false);
  const [search, setSearch] = useState('');
  const [isDrawer, setDrawer] = useState(false);
  const [isOnline, setOnline] = useState(true);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [categorySkillsTagsDetails, setCategorySkillsTagsDetails] = useState({});

  const [predictedSearch, setPredictedSearch] = useState('');
  const [isDrawerAPIFailed, setDrawerAPIFailed] = useState(false);
  const [drawerIdForBrokenLink, setDrawerIdForBrokenLink] = useState('');
  const searchFontXS = '14px';

  // Landing Page Data List
  const [categorgyList, setCategorgyList] = useState([]);
  const [categorgyListCopy, setCategorgyListCopy] = useState([]);

  // English and Chinese Translation Name State
  const [translationNames, setTranslationNames] = useState({
    english_Translation: '',
    chinese_Translation: '',
  });
  const [translationNamesIntialCopy, setTranslationNamesIntialCopy] = useState({
    english_Translation: '',
    chinese_Translation: '',
  });
  const [coursesDrawerList, setCoursesDrawerList] = useState([]);
  const [learningPathsDrawerList, setLearningPathsDrawerList] = useState([]);

  // Loading States
  const [isLoadingCoursesList, setLoadingCoursesList] = useState(false);
  const [isLoadingLearningPathList, setLoadingLearningPathList] = useState(false);

  // Error states
  const [isEnglishTranslationEmpty, setEnglishTranslationEmpty] = useState(false);
  const [isChineseTranslationEmpty, setChineseTranslationEmpty] = useState(false);
  const [englishTranslationDuplicetName, setEnglishTranslationDuplicetName] = useState(false);
  const [nameErrorStateChinese, setNameErrorStateChinese] = useState(false);
  const [nameErrorStateEnglish, setNameErrorStateEnglish] = useState(false);

  // Invalid Char States for Drawer Input Field
  const [englishTranslationInvalidCharacter, setEnglishTranslationInvalidCharacter] = useState('');
  const [chineseTranslationInvalidCharacter, setChineseTranslationInvalidCharacter] = useState('');

  // Broken Link CST Drawer Name State
  const [selctedCST, setSelectedCST] = useState({});

  // Create CST States
  const [isCreateCSTOpen, setCreateCSTOpen] = useState(false);
  const [mouseHoverState, setMouseHoverState] = useState('Select type');
  const [selectTypeText, setSelectTypeText] = useState('Select type');
  const [openSelectTypeDropdown, setOpenSelectTypeDropdown] = useState(false);
  const [englishTranslationCreateCST, setEnglishTranslationCreateCST] = useState('');
  const [chineseTranslationCreateCST, setChineseTranslationCreateCST] = useState('');

  // Invalid Char States for Drawer Input Field
  const [
    englishTranslationCreateCSTInvalidCharacter,
    setEnglishTranslationCreateCSTInvalidCharacter,
  ] = useState('');
  const [
    chineseTranslationCreateCSTInvalidCharacter,
    setChineseTranslationCreateCSTInvalidCharacter,
  ] = useState('');

  // Create CST Error State
  // English
  const [isCreateEnglishTranslationEmpty, setCreateEnglishTranslationEmpty] = useState(false);
  const [CreateEnglishTranslationDuplicetName, setCreateEnglishTranslationDuplicetName] =
    useState(false);
  const [CreateEnglishTranslationLength, setCreateEnglishTranslationLength] = useState('');
  const [nameErrorStateEnglishCreate, setNameErrorStateEnglishCreate] = useState(false);
  const [CreateMaxCharErrorMessageEnglish, setCreateMaxCharErrorMessageEnglish] = useState(false);
  const [isCreateButtonDisabled, setCreateButtonDisabled] = useState(true);
  // Chinese
  const [isCreateChineseTranslationEmpty, setCreateChineseTranslationEmpty] = useState(false);
  const [CreateChineseTranslationLength, setCreateChineseTranslationLength] = useState('');
  const [nameErrorStateChineseCreate, setNameErrorStateChineseCreate] = useState(false);
  const [CreateMaxCharErrorMessageChinese, setCreateMaxCharErrorMessageChinese] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const regexNameChinese = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z:： -]+/g;
  const _initialFilterData = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Categories',
        className: 'draft',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Skills',
        className: 'goLive',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Tags',
        className: 'goOffline',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
    ],
  };

  useEffect(async () => {
    setIsLoading(true);
    initialUseEffect({
      setCategorgyList,
      setSearchFilterEmptyState,
      setCategorgyListCopy,
      setErrorStateTemplate,
      isOnline,
      setSelectedNavigationOption,
      setIsUserLoggedIn,
      setIsLoading,
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const [filterDataTemp, setFilterDataTemp] = useState(new Object(_initialFilterData));

  const [filterData, setFilterData] = useState(_initialFilterData);

  useEffect(() => {
    searchVariableUseEffect({
      search,
      categorgyListCopy,
      setCategorgyList,
    });
  }, [search, categorgyListCopy]);

  return (
    <React.Fragment>
      <div className={[styles.audience_mgt_wrapper, styles.category_main_wrapper].join(' ')}>
        {/* Header Component */}
        <Header
          headerName="Categories, Skills, Tags Management"
          headerXSName=""
          btnName1="Cancel"
          btnName2="Save changes"
          isHeaderXS={false}
          isBtn1={false}
          isBtn2={false}
        />
        {/* Header Component */}
        {/* Learning Empty error container */}
        {/* {categorgyList?.length == 0 && (
          <div className={[styles.am_empty_screen_wrapper, ''].join(' ')}>
            <div className={styles.inner_wrapper_empty}>
              <div className={styles.empty_screen}>
                <div className={[styles.image_wrapper, styles.am_image].join(' ')}></div>

                <div className={styles.content_wrapper}>
                  <div className={styles.label}>Categories, Skills, Tags Management</div>
                  <div className={styles.content}>
                    Ability to target courses at specific groups of ABOs coming later in 2022.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {/* End of empty screen */}
        <div className={[styles.audience_wrapper].join(' ')}>
          <TopBarComponenent
            list={categorgyList}
            fetchList={(params) => {
              fetchCategorySkillsTags({
                setCategorgyList,
                setSearchFilterEmptyState,
                setCategorgyListCopy,
                setErrorStateTemplate,
                isOnline,
              });
            }}
            searchListFunction={(e) => {
              if (e != '') {
                setSearch(e.target.value);
              } else if (e == '') {
                setSearch('');
              }
            }}
            placeholderName={'Search Categories/Skills/Tags'}
            setPredictedSearch={setPredictedSearch}
            predictedSearch={predictedSearch}
            handleSearchInput={(event) => {
              handleSearchInput({
                event,
                setSearch,
                setPredictedSearch,
                categorgyList,
              });
            }}
            handleFilterApply={(params) => {
              handleFilterApplyNew({
                params,
                filterData,
                makeRequest,
                setFilterData,
                setFilterDataTemp,
                setCategorgyList,
                setCategorgyListCopy,
                setSearchFilterEmptyState,
                setErrorStateTemplate,
                setIsLoading,
              });
            }}
            filterData={filterData}
            addNewButton={() => {
              isCreateCSTOpen;
            }}
            addNewButtonText={'New'}
            marginLeft={'auto'}
            searchFontXS={searchFontXS}
            isOpenSubCategories={true}
            componentCST={true}
            isDrawer={isDrawer}
            setDrawer={setDrawer}
            searchBarFrom={'CST'}
            filterPillAlign="center"
            isTilesBlue={true}
            newButtonHandle={(props) => {
              handleCSTCreatModelCloseOpen({
                setCreateCSTOpen,
                isCreateCSTOpen,
                setSelectTypeText,
                setOpenSelectTypeDropdown,
                setMouseHoverState,
                setEnglishTranslationCreateCST,
                setChineseTranslationCreateCST,
                nameErrorStateEnglishCreate,
                setNameErrorStateEnglishCreate,
                setCreateEnglishTranslationDuplicetName,
                CreateEnglishTranslationDuplicetName,
                setCreateMaxCharErrorMessageEnglish,
                setNameErrorStateChineseCreate,
                setCreateMaxCharErrorMessageChinese,
                setCreateChineseTranslationEmpty,
                setCreateEnglishTranslationEmpty,
              });
            }}
          />

          <div className={styles.inner_wrapper}>
            <TableListComponentCST
              list={categorgyList}
              isLoading={isLoading}
              EmptyScreenComponent={EmptyScreenComponent}
              isSearchFilterEmptyState={isSearchFilterEmptyState}
              onClickAudienceGrp={(e, ele) => {
                selectedCategorySkillsTags({
                  e,
                  item: ele?.id,
                  isDrawer,
                  categorySkillsTagsDetails,
                  setDrawer,
                  setCategorySkillsTagsDetails,
                  makeRequest,
                  setDrawerAPIFailed,
                  drawerIdForBrokenLink,
                  setDrawerIdForBrokenLink,
                  type: ele?.type,
                  setTranslationNames,
                  setCoursesDrawerList,
                  setLearningPathsDrawerList,
                  englishTranslationDuplicetName,
                  coursesDrawerList,
                  learningPathsDrawerList,
                  setSelectedCST,
                  translationNames,
                  translationNamesIntialCopy,
                  setEnglishTranslationEmpty,
                  setChineseTranslationEmpty,
                  setCategorgyList,
                  setSearchFilterEmptyState,
                  setCategorgyListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  setEnglishTranslationDuplicetName,
                  setNotificationData,
                  setDashboardNotificationCountApi,
                  setNotificationListApi,
                  regexNameChinese,
                  setTranslationNamesIntialCopy,
                  filterData,
                  nameErrorStateChinese,
                  nameErrorStateEnglish,
                });
              }}
              componentClass="category_main_wrapper"
              drawerDetails={categorySkillsTagsDetails}
              drawerIdForBrokenLink={drawerIdForBrokenLink}
              drawerOpen={isDrawer}
              drawerClose={setDrawer}
            />

            <DrawerComponentCST
              drawerDetails={categorySkillsTagsDetails}
              drawerOpen={isDrawer}
              drawerClose={setDrawer}
              isAPIFailed={isDrawerAPIFailed}
              drawerTryAgainCall={(drawerId) => {
                fetchCategorySkillsTagsDetails({
                  CSTId: drawerId,
                  makeRequest,
                  setCategorySkillsTagsDetails,
                  setDrawer,
                  setDrawerAPIFailed,
                  drawerIdForBrokenLink,
                  setDrawerIdForBrokenLink,
                  setTranslationNames,
                  setCoursesDrawerList,
                  setLearningPathsDrawerList,
                  setTranslationNamesIntialCopy,
                  learningPathsDrawerList,
                  coursesDrawerList,
                  setCategorgyList,
                  setSearchFilterEmptyState,
                  setCategorgyListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  type: selctedCST,
                });
              }}
              drawerIdForBrokenLink={drawerIdForBrokenLink}
              translationNames={translationNames}
              handleTranslationChange={(props) => {
                handleTranslationChange({
                  e: props?.e,
                  variant: props?.variant,
                  setTranslationNames,
                  translationNames,
                  regexNameChinese,
                });
              }}
              refreshList={(props) => {
                refreshList({
                  variant: props?.variant,
                  drawerId: props?.drawerId,
                  type: props?.type,
                  setLoadingCoursesList,
                  setLoadingLearningPathList,
                  setCategorySkillsTagsDetails,
                  setDrawer,
                  setDrawerAPIFailed,
                  drawerIdForBrokenLink,
                  setDrawerIdForBrokenLink,
                  setTranslationNames,
                  setCoursesDrawerList,
                  setLearningPathsDrawerList,
                  setTranslationNamesIntialCopy,
                  learningPathsDrawerList,
                  coursesDrawerList,
                  setCategorgyList,
                  setSearchFilterEmptyState,
                  setCategorgyListCopy,
                  setErrorStateTemplate,
                  isOnline,
                });
              }}
              coursesDrawerList={coursesDrawerList}
              learningPathsDrawerList={learningPathsDrawerList}
              isLoadingCoursesList={isLoadingCoursesList}
              isLoadingLearningPathList={isLoadingLearningPathList}
              handleCSTTranslationChange={(props) => {
                handleCSTTranslationChange({
                  type: props?.type,
                  CSTId: props?.CSTId,
                  translationNames,
                  setNotificationData,
                  translationNamesIntialCopy,
                  setDashboardNotificationCountApi,
                  setNotificationListApi,
                });
              }}
              closeDrawerModal={(props) => {
                closeDrawerModal({
                  englishTranslationLength: props?.englishTranslationLength,
                  chineseTranslationLength: props?.chineseTranslationLength,
                  drawerClose: props?.drawerClose,
                  drawerOpen: props?.drawerOpen,
                  drawerDetails: props?.drawerDetails,
                  setChineseTranslationEmpty,
                  setEnglishTranslationEmpty,
                  setCategorgyList,
                  setSearchFilterEmptyState,
                  setCategorgyListCopy,
                  setErrorStateTemplate,
                  isOnline,
                  handleCSTTranslationChange,
                  translationNamesIntialCopy,
                  translationNames,
                  setEnglishTranslationDuplicetName,
                  englishTranslationDuplicetName,
                  setNotificationData,
                  setDashboardNotificationCountApi,
                  setNotificationListApi,
                  filterData,
                  nameErrorStateChinese,
                  nameErrorStateEnglish,
                });
              }}
              isChineseTranslationEmpty={isChineseTranslationEmpty}
              isEnglishTranslationEmpty={isEnglishTranslationEmpty}
              setChineseTranslationEmpty={setChineseTranslationEmpty}
              setEnglishTranslationEmpty={setEnglishTranslationEmpty}
              translationNamesIntialCopy={translationNamesIntialCopy}
              handleCloseCST={() => {
                handleCloseCST({setDelete, isDelete});
              }}
              handleOnClickEditCourse={(props) => {
                handleOnClickEditCourse(props);
              }}
              handleOnClickEditLearningPath={(props) => {
                handleOnClickEditLearningPath({id: props?.id, state: props?.state});
              }}
              list={categorgyList}
              setEnglishTranslationDuplicetName={setEnglishTranslationDuplicetName}
              englishTranslationDuplicetName={englishTranslationDuplicetName}
              selctedCST={selctedCST}
              nameErrorStateChinese={nameErrorStateChinese}
              setNameErrorStateChinese={setNameErrorStateChinese}
              nameErrorStateEnglish={nameErrorStateEnglish}
              setNameErrorStateEnglish={setNameErrorStateEnglish}
              englishTranslationInvalidCharacter={englishTranslationInvalidCharacter}
              setEnglishTranslationInvalidCharacter={setEnglishTranslationInvalidCharacter}
              chineseTranslationInvalidCharacter={chineseTranslationInvalidCharacter}
              setChineseTranslationInvalidCharacter={setChineseTranslationInvalidCharacter}
              setNotificationData={setNotificationData}
            />
          </div>
        </div>

        {/* Create New Category Modal */}
        <CreateNewCST
          setEnglishTranslationCreateCSTInvalidCharacter={
            setEnglishTranslationCreateCSTInvalidCharacter
          }
          setChineseTranslationCreateCSTInvalidCharacter={
            setChineseTranslationCreateCSTInvalidCharacter
          }
          englishTranslationCreateCSTInvalidCharacter={englishTranslationCreateCSTInvalidCharacter}
          chineseTranslationCreateCSTInvalidCharacter={chineseTranslationCreateCSTInvalidCharacter}
          isCreateEnglishTranslationEmpty={isCreateEnglishTranslationEmpty}
          setCreateEnglishTranslationEmpty={setCreateEnglishTranslationEmpty}
          isCreateChineseTranslationEmpty={isCreateChineseTranslationEmpty}
          setCreateChineseTranslationEmpty={setCreateChineseTranslationEmpty}
          CreateEnglishTranslationDuplicetName={CreateEnglishTranslationDuplicetName}
          setCreateEnglishTranslationDuplicetName={setCreateEnglishTranslationDuplicetName}
          CreateMaxCharErrorMessageChinese={CreateMaxCharErrorMessageChinese}
          setCreateMaxCharErrorMessageChinese={setCreateMaxCharErrorMessageChinese}
          CreateMaxCharErrorMessageEnglish={CreateMaxCharErrorMessageEnglish}
          setCreateMaxCharErrorMessageEnglish={setCreateMaxCharErrorMessageEnglish}
          setNameErrorStateChineseCreate={setNameErrorStateChineseCreate}
          nameErrorStateChineseCreate={nameErrorStateChineseCreate}
          setNameErrorStateEnglishCreate={setNameErrorStateEnglishCreate}
          nameErrorStateEnglishCreate={nameErrorStateEnglishCreate}
          CreateChineseTranslationLength={CreateChineseTranslationLength}
          setCreateChineseTranslationLength={setCreateChineseTranslationLength}
          CreateEnglishTranslationLength={CreateEnglishTranslationLength}
          setCreateEnglishTranslationLength={setCreateEnglishTranslationLength}
          list={categorgyList}
          openAndCloseCST={isCreateCSTOpen}
          handleCSTCreatModelCloseOpen={() => {
            handleCSTCreatModelCloseOpen({
              setCreateCSTOpen,
              isCreateCSTOpen,
              setSelectTypeText,
              setOpenSelectTypeDropdown,
              setMouseHoverState,
              setEnglishTranslationCreateCST,
              setChineseTranslationCreateCST,
              nameErrorStateEnglishCreate,
              setNameErrorStateEnglishCreate,
              setCreateEnglishTranslationDuplicetName,
              CreateEnglishTranslationDuplicetName,
              setCreateMaxCharErrorMessageEnglish,
              setNameErrorStateChineseCreate,
              setCreateMaxCharErrorMessageChinese,
              setCreateChineseTranslationEmpty,
              setCreateEnglishTranslationEmpty,
            });
          }}
          mouseHoverState={mouseHoverState}
          setMouseHoverState={setMouseHoverState}
          selectTypeText={selectTypeText}
          setSelectTypeText={setSelectTypeText}
          openSelectTypeDropdown={openSelectTypeDropdown}
          setOpenSelectTypeDropdown={setOpenSelectTypeDropdown}
          handleEnglishTranslationCreateCST={(props) => {
            handleEnglishTranslationCreateCST({
              text: props?.text,
              setEnglishTranslationCreateCST,
              regexNameChinese: props?.regexNameChinese,
              regexNameChinese2: props?.regexNameChinese2,
            });
          }}
          handleChineseTranslationCreateCST={(props) => {
            handleChineseTranslationCreateCST({
              text: props?.text,
              setChineseTranslationCreateCST,
              regexNameChinese: props?.regexNameChinese,
              regexNameChinese2: props?.regexNameChinese2,
            });
          }}
          englishTranslationCreateCST={englishTranslationCreateCST}
          chineseTranslationCreateCST={chineseTranslationCreateCST}
          handleCreateNewCSTButtonSaveChanges={(props) => {
            handleCreateNewCSTButtonSaveChanges({
              selectTypeText,
              englishTranslationCreateCST,
              chineseTranslationCreateCST,
              setCategorgyList,
              setSearchFilterEmptyState,
              setCategorgyListCopy,
              setErrorStateTemplate,
              isOnline,
              setCreateCSTOpen,
              isCreateCSTOpen,
              setSelectTypeText,
              setOpenSelectTypeDropdown,
              setMouseHoverState,
              setEnglishTranslationCreateCST,
              setChineseTranslationCreateCST,
              nameErrorStateEnglishCreate,
              setNameErrorStateEnglishCreate,
              setCreateEnglishTranslationDuplicetName,
              CreateEnglishTranslationDuplicetName,
              nameErrorStateChineseCreate,
              setNameErrorStateChineseCreate,
              setCreateMaxCharErrorMessageEnglish,
              setCreateMaxCharErrorMessageChinese,
              setNotificationData,
              setDashboardNotificationCountApi,
              setNotificationListApi,
              fetchCategorySkillsTags,
              setCreateChineseTranslationEmpty,
              setCreateEnglishTranslationEmpty,
              filterData: _initialFilterData,
              setFilterData,
              setIsLoading,
            });
          }}
        />
        {/* Create New Category Modal */}

        {/* Delete Modal */}
        {isDelete ? (
          <DeleteModal
            cstDelete={true}
            type={`${categorySkillsTagsDetails?.type}`}
            name={`the ${categorySkillsTagsDetails?.type}`}
            boldTxt={`${categorySkillsTagsDetails?.englishTranslation}`}
            closeDeleteModal={() => {
              handleCloseCST({setDelete, isDelete});
            }}
            deleteCall={() => {
              handleDeleteCST({
                categorySkillsTagsDetails,
                setDrawer,
                setNotificationData,
                setCategorgyList,
                setSearchFilterEmptyState,
                setCategorgyListCopy,
                setErrorStateTemplate,
                isOnline,
                setNotificationListApi,
                setDashboardNotificationCountApi,
                filterData,
              });
            }}
          />
        ) : null}
        {/* Delete Modal */}
      </div>

      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isUserLoggedIn: state && state.uiState && state.uiState.isUserLoggedIn,
  notificationData: state && state.uiState && state.uiState.notificationData,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(CategoriesSkillsTagsManagementComponent),
);
