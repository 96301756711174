import React, {createRef, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from './CourseWizard.module.scss';
import Header from '../../shared/header';
import {
  setNotificationData,
  setCourseMode,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  setErrorStateTemplate,
  setSelectedNavigationOption,
} from '../../../redux/actions/uiActions';
import {
  setMainImageBackup,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setCroppedImageData,
  setImageData,
  setThumbnailImage169,
} from '../../../redux/actions/imageCrop/imageCropAction';
import {
  setNewCourseData,
  setNewCourseWizardState,
  setNewImageData,
  setNewAdaptFile,
  setBlockNavigation,
  setNewThumbnailImageData,
  setNewThumbnailId,
} from '../../../redux/actions/newCourseActions';
import {connect} from 'react-redux';
import Notification from '../../shared/notification';
import {_apiHeaderConfigs} from '../../utilAPIHeader';
import config from '../../../config';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import CreateWizard from './CreateWizard';
import EditWizard from './EditWizard';
import SearchListComponent from '../../shared/searchListComponent/index';
import {ListArraySubheader, regexNameChinese} from './Constant';
import {
  audienceListForEachFun,
  fullDataCheckFunc,
  publishEditCourseArrayFun,
  EditCourseArrayFun,
} from './CourseWizardutils';
const {currentEnv} = config;

const CourseWizardComponent = ({
  // ApiData,
  //closeCourseWizard,
  mainImageBackup,
  setMainImageBackup,
  setNewThumbnailImageData,
  newCourseData,
  setNewCourseData,
  setNewImageData,
  setNewCourseWizardState,
  newCourseWizardState,
  //fetchSelectedCourse,
  setNotificationData,
  notificationData,
  setCourseMode,
  newThumbnailId,
  showCreateNewCoursesWizard,
  setBlockNavigation,
  fetchCoursesList,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setCroppedImageData,
  setImageData,
  currentUserId,
  openNewCourse,
  setNewThumbnailId,
  thumbnailImage169,
  setThumbnailImage169,
  setErrorStateTemplate,
  setSelectedNavigationOption,
  allState,
}) => {
  const [mainState, setMainState] = useState(true);
  const [nextState, setNextState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [headerButtons, setHeaderButtons] = useState(false);
  const [openDecommission, setOpenDecommission] = useState(false);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [publishButtonDisableEdit, setPublishButtonDisableEdit] = useState(false);
  const [saveAndExitDisable, setSaveAndExitDisable] = useState(false);
  const [btn2SaveAndExitDisable, setBtn2SaveAndExitDisable] = useState(true);
  const [exitButtonEnable, setExitButtonEnable] = useState(true);
  const [uploadingFileButton, setUploadingFileButton] = useState(false);
  const [disableImageLib, setDisableImageLib] = useState(false);
  const [imageFileDesk, setImageFileDesk] = useState(null);
  const [data, setData] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [audienceGroup, setAudienceGroup] = useState([]);
  const [skills, setSkills] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [pillsAddAudienceGroup, setPillsAddAudienceGroup] = useState([]);
  const [pillsDataSkills, setPillsDataSkills] = useState([]);
  const [pillsDataKeyword, setPillsDataKeyword] = useState([]);
  const [categoryData, setCategoryData] = useState('');
  const [typesCheckbox, setTypeCheckbox] = useState();
  const [fileData, setFileData] = useState(null);
  const [fileDataTemp, setFileDataTemp] = useState(null);
  const [newCourseID, setNewCourseID] = useState(null);
  const [isCourseIDFetched, setIsCourseIDFetched] = useState(false);
  const [addUserGroupList, setAddUserGroupList] = useState([]);
  const [addUserGroupListCopy, setAddUserGroupListCopy] = useState([]);
  const [disableStateUserManagement, setDisableStateUserManagement] = useState(false);
  const [deleteUserGroupList, setDeleteUserGroupList] = useState([]);
  const containerRef = useRef();
  const skillsRef = useRef();
  const keywordsRef = useRef();
  const router = useHistory();
  const {query = {}} = router.location;
  const [isArchive, setIsArchive] = useState(false);
  const [openAdaptFileModal, setOpenAdaptFileModal] = useState(false);
  const [adaptFileState, setAdaptFileState] = useState('INPROGRESS');
  const [previewData, setPreviewData] = useState(null);
  const [notificationBanerCount, setNotificationBanerCount] = useState(0);
  const [previewAdaptFileFlag, setPreviewAdaptFileFlag] = useState(false);
  const [publishButtonAfterPreview, setPublishButtonAfterPreview] = useState(true);
  const [openPreviewAdaptFile, setOpenPreviewAdaptFile] = useState(false);
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);
  const [adaptFileButtonState, setAdaptFileButtonState] = useState(true);
  const [list, setList] = useState(ListArraySubheader);
  const [subheader, setSubheader] = useState('');
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));
  const [isOnline, setOnline] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  window.addEventListener('online', () => {
    setOnline(true);
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
  });
  window.addEventListener('popstate', (event) => {
    window.location.reload();
  });
  useEffect(() => {
    if (uploadingFileButton) {
      setPublishButtonDisableEdit(true);
      setSaveAndExitDisable(true);
      setDisableStateUserManagement(true);
    } else {
      setPublishButtonDisableEdit(false);
      setSaveAndExitDisable(false);
      setDisableStateUserManagement(false);
      getEnableSaveAndExit({
        englishTitle: data?.englishTitle,
        chineseTitle: data?.chineseTitle,
        englishDescription: data?.englishDescription,
        chineseDescription: data?.chineseDescription,
      }) &&
        !editState &&
        setExitButtonEnable(false);
    }
  }, [uploadingFileButton]);

  useEffect(() => {
    setPublishButtonDisableEdit(false);
    if (allCheckFunc()) {
      setNextButtonDisable(false);
    }
  }, [publishButtonAfterPreview]);

  const handleDataFill = (name, value) => {
    if (name === 'duration') {
      let limit = 25000;
      if (value > limit) {
        value = limit;
      }
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };
  const fetchSelectedCourse = async (courseId) => {
    if (
      courseId != 0 &&
      courseId != '0' &&
      courseId != null &&
      courseId != 'null' &&
      courseId != undefined &&
      courseId != 'undefined' &&
      courseId != '0&sub_header'
    ) {
      const dataCourseId = await makeRequest({
        method: 'GET',
        endpoint: `/admin/api/v1/courses/${courseId}`,
      });
      if (dataCourseId != 'ERR_NETWORK' && dataCourseId) {
        let respData = {};

        if (dataCourseId?.data) {
          respData = {
            ...dataCourseId?.data,
            course_state: dataCourseId?.data?.courseState,
          };
          setApiData(dataCourseId?.data);
        }
      } else if (dataCourseId == 'ERR_NETWORK' && isOnline) {
        setErrorStateTemplate({
          variant: 'serverError',
          status: true,
        });
      }
    }
  };
  const updateCourseDetails = async (id, courseDetails, publishCourse = false) => {
    setNewThumbnailId('');
    const courseTitle = newCourseID && newCourseID.title;
    const courseID = newCourseID && newCourseID.courseId;
    courseDetails.title = courseTitle;
    if (fileData && fileData.name) {
      courseDetails.adaptFileName = fileData && fileData.name;
    }

    const dataCourseID = await makeRequest({
      method: 'PUT',
      endpoint: `/admin/api/v1/courses/${courseID}`,
      body: JSON.stringify(courseDetails),
    });
    if (dataCourseID) {
      //fetchSelectedCourse(dataCourseID?.data?.entry?.uid);
      // After BFF changes are Deployed
      // Remove above fetchSelectedCourse call and Use Below fetchSelectedCourse
      setTimeout(() => {
        if (dataCourseID?.data?.entry?.uid) {
          //fetchSelectedCourse(dataCourseID?.data?.entry?.uid);
          // fetchCoursesList();
        } else if (dataCourseID?.data?.courseId) {
          //fetchSelectedCourse(dataCourseID?.data?.courseId);
          // fetchCoursesList();
        }
      }, 1000);
      if (dataCourseID.status === 'Success' && publishCourse) {
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'New Course Launched',
          description: `${courseDetails.englishTitle} has been launched by the ${oktaToken.idToken.claims.fullName}`,
        });
      } else if (
        dataCourseID.status === 'Success' &&
        (courseDetails.courseState == 'Live' || courseDetails.courseState == 'Offline')
      ) {
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'Course Edited',
          description: `${courseDetails.englishTitle} has been edited by the ${oktaToken.idToken.claims.fullName}`,
        });
      }
    }

    setNotificationListApi();
    setDashboardNotificationCountApi(currentUserId);
  };
  const createAudienceLists = (list) => {
    let allList = [];
    list?.forEach((element) => {
      let tempObj = {
        title: element?.audienceGrpName,
        id: element?.audienceGroupId,
        audienceGrpDescription: element?.audienceGrpDescription || '',
      };
      allList.push(tempObj);
    });
    //setDefaultAudienceGroup(defaultList);
    setAudienceGroup(allList);
  };
  const fetchAudienceGroupList = async () => {
    const dataAudience = await makeRequest({
      method: 'GET',
      endpoint: '/admin/api/v1/audience-groups?sortBy=oldest_to_newest',
    });
    if (dataAudience != 'ERR_NETWORK' && dataAudience) {
      createAudienceLists(dataAudience?.data);
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
    } else if (dataAudience == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
    }
  };
  const fetchCategories = async () => {
    const dataCategories = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/categories',
    });
    if (dataCategories) {
      setCategories(dataCategories?.data);
    }
  };

  const fetchSkills = async () => {
    const dataSkills = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/skills',
    });
    if (dataSkills) {
      setSkills(dataSkills?.data);
    }
  };

  const fetchKeywords = async () => {
    const dataKeywords = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/keywords',
    });
    if (dataKeywords) {
      setKeyword(dataKeywords?.data);
    }
  };

  const checkFun = async (eleData, publishState = false) => {
    if (
      eleData &&
      (eleData?.englishTitle?.length > 2 || eleData?.chineseTitle?.length > 2) &&
      !exitButtonEnable
    ) {
      const courseId = newCourseID && newCourseID.courseId;
      let elementData = EditCourseArrayFun(
        publishState,
        eleData,
        addUserGroupList,
        deleteUserGroupList,
        fileData,
      );
      await updateCourseDetails(courseId, elementData, publishState);
    }
  };

  const saveAndExitClick = () => {
    if (data?.courseId === null || data?.courseId === undefined) {
      callCreateCourseAPI();
    } else {
      checkFun(data, false);
    }
  };
  const allCheckFunc = () => {
    return fullDataCheckFunc(data, fileData, publishButtonAfterPreview);
  };

  const getEnableSaveAndExit = ({englishTitle = '', englishDescription = ''}) => {
    let flag = false;

    if (englishTitle?.length > 0 && englishDescription?.length > 0) {
      if (englishTitle?.length >= 3 && englishDescription?.length >= 3) {
        flag = true;
      }
    } else if (englishTitle?.length > 0 || englishDescription?.length > 0) {
      if (englishTitle?.length >= 3 && englishDescription?.length >= 3) {
        flag = true;
      }
    }
    return flag;
  };
  const closeCourseWizard = () => {
    const url = '/Coursesmanagement';
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');

    setTimeout(() => {
      router.push({
        pathname: '/Coursesmanagement',
        search: `?id=${data?.courseId ? data?.courseId : '0'}`,
        //query: {actionName: ""},
      });
      setNewCourseWizardState({
        courseWizardOpen: false,
        courseWizardStateOpen: '',
        section: '',
      });
      setNewCourseData(null);

      setNewThumbnailImageData(null);
    }, 500);
  };
  React.useEffect(() => {
    const unblock = router.block((location, action) => {
      if (newCourseWizardState.courseWizardOpen) {
        //closeCourseWizard();
        if (newCourseWizardState.section.length > 0) {
          return true;
        } else if (newCourseWizardState.section.length <= 0) {
          if (action === 'PUSH') {
            return true;
          }
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, []);

  useEffect(async () => {
    if (
      getEnableSaveAndExit({
        englishTitle: data?.englishTitle,
        chineseTitle: data?.chineseTitle,
        englishDescription: data?.englishDescription,
        chineseDescription: data?.chineseDescription,
      }) &&
      !nextState &&
      !uploadingFileButton
    ) {
      setHeaderButtons(true);
      setPublishButtonDisableEdit(false);
      !editState && setExitButtonEnable(false);
      setNextButtonDisable(false);
      setAdaptFileButtonState(false);
    } else {
      setNextButtonDisable(true);
      setPublishButtonDisableEdit(true);
      setAdaptFileButtonState(true);
      !editState && !nextState && setExitButtonEnable(true);
      //setBlockNavigation(false);
      !nextState && setHeaderButtons(false);
    }
    if (allCheckFunc() && nextState) {
      setNextButtonDisable(false);
      !editState && setExitButtonEnable(false);
      setPublishButtonDisableEdit(false);
    } else {
      !allCheckFunc() && nextState && setNextButtonDisable(true);
    }
  }, [data]);

  useEffect(async () => {
    //handleUserTargetingGroupsList()
    setBlockNavigation(true);
    // fetch categories
    fetchCategories();
    // fetch skills
    fetchSkills();
    // fetch keyword
    fetchKeywords();
    if (router.location.pathname == '/create-course-wizard') {
      setSelectedNavigationOption('COURSES-MANAGEMENT');
      if (router.location.search) {
        let string = router.location.search;
        const stringArray = string?.split('&');
        let strArr1 = stringArray[0]?.split('=');
        let strArr2 = stringArray[1]?.split('=');
        let selectedID = strArr1[1];
        if (
          selectedID == 0 ||
          selectedID == '0' ||
          selectedID == 1 ||
          selectedID == '1' ||
          selectedID == undefined ||
          selectedID == 'undefined' ||
          (selectedID == null && selectedID == 'null')
        ) {
          !newCourseData && setData(null);
          setIsLoading(false);
        }
        let selectedSubHeader = strArr2[1];
        if (selectedSubHeader == 'course_description') {
          setMainState(true);
          setNextState(false);
          setEditState(false);
          setSubheader('Course Description');
        } else if (selectedSubHeader == 'categories_skills_tags') {
          setMainState(false);
          setNextState(true);
          setEditState(false);
          setSubheader('Categories, Skills, Tags');
        } else if (selectedSubHeader == 'edit') {
          setMainState(false);
          setNextState(false);
          setEditState(true);
        }
        selectedID != 0 &&
          selectedID != '0' &&
          selectedID != 1 &&
          selectedID != '1' &&
          selectedID != undefined &&
          selectedID != 'undefined' &&
          selectedID != null &&
          selectedID != 'null' &&
          !newCourseData &&
          (await fetchSelectedCourse(selectedID));
      }
    }
    //fetchAudienceGroupList
    await fetchAudienceGroupList();
    return () => {
      setData(null);
      setPillsDataKeyword(null);
      setPillsDataSkills(null);
      setOpenAdaptFileModal(false);
      setBlockNavigation(false);
    };
  }, []);
  useEffect(async () => {
    // setPublishButtonDisableEdit(true);
    let audienceGrp = [];
    if (apiData && !openNewCourse) {
      setNewCourseData(null);
      setData((prevData) => ({
        ...prevData,
        ...apiData,
        types: apiData?.types?.length > 0 ? apiData?.types : undefined,
      }));
      setCategoryData(apiData?.category?.length > 0 ? apiData?.category[0]?.title : '');
      apiData?.courseState === 'Live' && setDisableStateUserManagement(true);
      apiData?.courseState === 'Offline' && setDisableStateUserManagement(false);
      apiData?.audienceGroups.forEach((data) => {
        let tempObj = {
          id: data?.id,
          title: data?.title,
          audienceGrpDescription: data?.description,
          courseType: data?.courseType,
        };
        audienceGrp.push(tempObj);
      });
      setAddUserGroupList(audienceGrp);
      setAddUserGroupListCopy(new Array(...audienceGrp));
      setIsCourseIDFetched(true);
      let temp = {
        courseId: apiData.courseId,
        title: apiData.title, //TODO Backend is not sending UUID in response
      };
      setPillsAddAudienceGroup(audienceGrp);
      setNewCourseID(temp);
      setPillsDataKeyword(apiData?.keywords);
      setPillsDataSkills(apiData?.skills);
      setMainImageBackup(apiData?.thumbnail);
      setTypeCheckbox(apiData?.types?.toString());
      if (
        newCourseWizardState.courseWizardOpen &&
        newCourseWizardState.courseWizardStateOpen === 'edit'
      ) {
        setEditState(true);
        setMainState(false);
        setNextState(false);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [apiData]);
  const handleClickOutsideContainer = (event) => {
    if (
      //showCreateNewCoursesWizard &&
      (data?.englishTitle?.length >= 3 ||
        data?.chineseTitle?.length >= 3 ||
        newCourseID?.courseId) &&
      containerRef &&
      !uploadingFileButton &&
      (newCourseWizardState.courseWizardStateOpen === 'main' ||
        newCourseWizardState.courseWizardStateOpen === 'edit') &&
      !containerRef.current?.contains(event.target) &&
      event.target.className.includes('SideNavigation') &&
      !(
        event.target.className.indexOf('CourseWizard') !== -1 ||
        event.target.className.indexOf('Header') !== -1
      )
    ) {
      event.preventDefault();
      setBlockNavigation(true);
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Course Not Saved',
        description: 'Save & Exit to save this course in a Draft state.',
      });
    }
  };

  const handleClickOutsideContainerUploading = (event) => {
    if (
      // showCreateNewCoursesWizard &&
      uploadingFileButton &&
      containerRef &&
      (newCourseWizardState.courseWizardStateOpen === 'main' ||
        newCourseWizardState.courseWizardStateOpen === 'edit') &&
      !containerRef.current?.contains(event.target) &&
      event.target.className.includes('SideNavigation') &&
      !(
        event.target.className.indexOf('CourseWizard') !== -1 ||
        event.target.className.indexOf('Header') !== -1
      )
    ) {
      event.preventDefault();
      setBlockNavigation(true);
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'No Action can be done during File Uploading',
        description:
          'All the buttons are disabled when the file is uploading. Please wait for the file to upload to do any action.',
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideContainer, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideContainer, false);
      document.removeEventListener('click', handleClickOutsideContainer, false);
    };
  }, [data, uploadingFileButton]);
  useEffect(() => {
    uploadingFileButton &&
      document.addEventListener('mousedown', handleClickOutsideContainerUploading, false) &&
      setOpenDecommission(false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideContainerUploading, false);
      document.removeEventListener('click', handleClickOutsideContainerUploading, false);
      //setBlockNavigation(false);
    };
  }, [uploadingFileButton]);

  const getCourseButtonStatus = () => {
    const status = data?.courseState?.toString()?.toLowerCase();
    return status === 'live' ? 'Go Offline' : status === 'offline' ? 'Go Live' : 'Delete Course';
  };

  const editSaveAndExitEnable = (data) => {
    setBtn2SaveAndExitDisable(false);
    if (
      (data?.englishTitle?.length >= 3 && data?.englishDescription?.length >= 3) ||
      (newCourseData?.data?.englishTitle?.length >= 3 &&
        newCourseData?.data?.englishDescription?.length >= 3)
    ) {
      setBtn2SaveAndExitDisable(false);
    } else {
      setBtn2SaveAndExitDisable(true);
    }
  };

  const filterListById = (id, list, setlist, name, stateFunction) => {
    let results = list?.filter((element) => {
      return element?.id !== id;
    });
    setlist(results);
    let tempArr = [];
    results?.forEach((data) => {
      tempArr.push(data.id);
    });
    stateFunction(name, tempArr);
  };

  function setCookie(cookieKey, value, expirationTimeInMinute, domain = '.amway.com', path = '/') {
    try {
      let expires = '';
      if (expirationTimeInMinute) {
        const date = new Date();
        date.setTime(date.getTime() + expirationTimeInMinute * 60000);
        expires = `; expires=${date.toGMTString()}`;
      }
      let _cookieString = '';
      if (cookieKey && value) {
        _cookieString = `${cookieKey}=${value}`;
      } else if (expires) {
        _cookieString += `${expires}=${value}`;
      }
      document.cookie = `${_cookieString}; domain=${domain}; path=${path}; Secure`;
    } catch (err) {
      //catch
    }
  }
  const openInNewTab = (url) => {
    if (previewData !== null && previewData?.data?.version) {
      const newWindow = window.open(previewData?.data?.courseUrl, '_blank', 'noopener,noreferrer');
      const _cookies = previewData?.data?.cookies;
      if (_cookies) {
        for (const item of _cookies) {
          setCookie(item?.cookieName, item?.cookieValue, item?.cookieExpirationInMinutes);
        }
      }
      setPublishButtonAfterPreview(false);
      allCheckFunc();
      if (newWindow) {
        newWindow.opener = null;
      }
    }
    setOpenPreviewAdaptFile(false);
  };
  const previewAdaptFileApi = async (versionNumber, id) => {
    let versionNum = versionNumber ? versionNumber : data?.version;
    const courseId = data?.courseId || id;

    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/courses/${courseId}/preview?version=${
        versionNum ? versionNum : '0'
      }`,
    });
    if (apiData?.data) {
      if (
        apiData?.status?.toLowerCase() === 'success' &&
        !apiData?.data?.isErrorOccuredInHosting &&
        apiData?.data?.version >= versionNum
      ) {
        setPreviewData(apiData);
        setAdaptFileState('SUCCESS');
        if (allCheckFunc() && mainState) {
          setNextButtonDisable(false);
        } else if (allCheckFunc() && nextState) {
          setNextButtonDisable(false);
        }
        setPublishButtonDisableEdit(false);
        if (apiData?.data?.version > versionNum) {
          versionNum = apiData?.data?.version;
          setData((prevData) => ({
            ...prevData,
            version: apiData?.data?.version,
          }));
        }
      } else if (
        !apiData?.isErrorOccuredInHosting &&
        apiData?.data?.version != versionNum &&
        apiData?.data?.version < versionNum
      ) {
        previewAdaptFileApi(versionNum, courseId);
      } else if (
        apiData?.status?.toLowerCase() === 'success' &&
        apiData?.data?.isErrorOccuredInHosting &&
        apiData?.data?.version >= versionNum
      ) {
        setAdaptFileState('FAILURE');
        setNotificationData({
          show: true,
          type: 'WARNING',
          title: 'File Hosting Failed',
          description:
            'Adapt file hosting failed. Please re-uplaod the adapt file to publish this course. Click on Course Description to access the upload button.',
        });
        if (apiData?.data?.version > versionNum) {
          versionNum = apiData?.data?.version;
          setData((prevData) => ({
            ...prevData,
            version: apiData?.data?.version,
          }));
        }
      }
    }
  };

  const previewAdaptFileApiNew = async (versionNumber) => {
    const versionNum = versionNumber ? versionNumber : data?.version;
    const courseId = data?.courseId;

    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/courses/${courseId}/preview?version=${
        versionNum ? versionNum : '0'
      }`,
    });
    if (apiData) {
      if (
        apiData?.status?.toLowerCase() === 'success' &&
        !apiData?.data?.isErrorOccuredInHosting &&
        apiData?.data?.version >= versionNum
      ) {
        setPreviewData(apiData);
        setIsAPICallInProgress(false);
        setNotificationBanerCount(1);
        setAdaptFileState('SUCCESS');
        setPublishButtonDisableEdit(false);
        setPreviewAdaptFileFlag(false);
        nextState && setPublishButtonAfterPreview(false);
        if (allCheckFunc() && mainState) {
          setNextButtonDisable(false);
        } else if (allCheckFunc() && nextState) {
          setNextButtonDisable(false);
        }
      } else if (!apiData?.isErrorOccuredInHosting && apiData?.version < versionNum) {
        if (apiData?.version == 0) {
          setIsAPICallInProgress(true);
          setPreviewData(null);
          previewAdaptFileApiNew(versionNum);
          setNotificationBanerCount(Number(notificationBanerCount) + 1);
        } else {
          setPreviewData(apiData);
          setNotificationData({
            show: true,
            type: 'FAILURE',
            title: 'Adapt File hosting is In-Progress',
            description: `${fileData?.name} is being hosted. Currently you are pre-viewing the previously uploaded Adapt file. Please wait for a few seconds for pre-viewing the recently uploaded Adapt file.`,
          });
        }
      } else if (apiData?.isErrorOccuredInHosting) {
        setPreviewAdaptFileFlag(false);
        setIsAPICallInProgress(false);
        if (apiData?.version == 0 && versionNum == 1) {
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'File Hosting Failed',
            description:
              'Adapt file hosting failed. Please re-uplaod the adapt file to publish this course. Click on Course Description to access the upload button.',
          });
        } else {
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'File Hosting Failed',
            description:
              'Adapt file hosting failed. Please re-upload the adapt file to publish this course. Click on Course Description to access the upload button. Currently you are pre-viewing previously uploaded Adapt file.',
          });
        }
      } else {
        return;
      }
    }
  };

  const previewAdaptFileCreateNewCourse = async (versionNumber) => {
    setUploadingFileButton(true);
    setHeaderButtons(false);
    const versionNum = versionNumber ? versionNumber : data?.version;
    const courseId = data?.courseId;
    const url = `/admin/api/v1/courses/${courseId}/preview?version=${
      versionNum ? versionNum : '0'
    }`;
    let apiData = await makeRequest({
      method: 'GET',
      endpoint: url,
    });
    if (apiData) {
      if (apiData?.status?.toLowerCase() === 'success') {
        if (!apiData?.data?.isErrorOccuredInHosting && apiData?.data?.version >= versionNum) {
          setPreviewData(apiData);
          setIsAPICallInProgress(false);
          setOpenPreviewAdaptFile(true);
          setNotificationBanerCount(1);
          setAdaptFileState('SUCCESS');
          setPublishButtonDisableEdit(false);
          setPreviewAdaptFileFlag(false);
          nextState && setPublishButtonAfterPreview(false);
          if (allCheckFunc() && mainState) {
            setNextButtonDisable(false);
          } else if (allCheckFunc() && nextState) {
            setNextButtonDisable(false);
          }
          setUploadingFileButton(false);
          setHeaderButtons(true);
        } else if (
          !apiData?.data?.isErrorOccuredInHosting &&
          apiData?.data?.version == 0
          //          apiData?.status?.toLowerCase() === "success" &&
          // !apiData?.data?.isErrorOccuredInHosting &&
          // apiData?.data?.version < versionNum
        ) {
          // notificationBanerCount ==0 &&
          setNotificationData({
            show: true,
            type: 'FAILURE',
            title: 'Adapt File hosting is In-Progress',
            description: `${fileData?.name} is being hosted. Please wait for a few seconds before trying again.`,
          });
          setIsAPICallInProgress(true);
          setPreviewData(null);
          //  previewAdaptFileApiNew(versionNum);
          // setNotificationBanerCount( Number(notificationBanerCount)+1);
          // else {
          //   setPreviewData(apiData);
          // //  setOpenPreviewAdaptFile(true);
          //   setNotificationData({
          //     show: true,
          //     type: "FAILURE",
          //     title: "Adapt File hosting is In-Progress",
          //     description: `${fileData?.name} is being hosted. Currently you are pre-viewing the previously uploaded Adapt file. Please wait for a few seconds for pre-viewing the recently uploaded Adapt file.`,
          //   });
          //   // openInNewTab('url');
          // }
          setUploadingFileButton(false);
          setHeaderButtons(true);
        } else if (
          !apiData?.data?.isErrorOccuredInHosting &&
          apiData?.data?.version >= 1 &&
          apiData?.data?.isEarlierVersionAvailable
          // &&
          //apiData?.data?.version == versionNum
          //          apiData?.status?.toLowerCase() === "success" &&
          // !apiData?.data?.isErrorOccuredInHosting &&
          // apiData?.data?.version < versionNum
        ) {
          setPreviewData(apiData);
          setNotificationData({
            show: true,
            type: 'FAILURE',
            title: 'Adapt File hosting is In-Progress',
            description: `${fileData?.name} is being hosted. Currently you are pre-viewing the previously uploaded Adapt file. Please wait for a few seconds for pre-viewing the recently uploaded Adapt file.`,
          });
          setUploadingFileButton(false);
          setHeaderButtons(true);
          setOpenPreviewAdaptFile(true);
        } else if (
          (apiData?.data?.isErrorOccuredInHosting &&
            apiData?.data?.version == 1 &&
            versionNum == 1) ||
          (!apiData?.data?.isEarlierVersionAvailable &&
            apiData?.data?.version >= 1 &&
            apiData?.data?.version == versionNum)
        ) {
          setPreviewAdaptFileFlag(false);
          setIsAPICallInProgress(false);
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'File Hosting Failed',
            description:
              'Adapt file hosting failed. Please re-uplaod the adapt file to publish this course. Click on Course Description to access the upload button.',
          });
          setUploadingFileButton(false);
          setHeaderButtons(true);
        } else if (
          apiData?.data?.isErrorOccuredInHosting &&
          apiData?.data?.isEarlierVersionAvailable &&
          apiData?.data?.version >= 1 &&
          apiData?.data?.version == versionNum
        ) {
          setPreviewData(apiData);
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'File Hosting Failed',
            description:
              'Adapt file hosting failed. Please re-upload the adapt file to publish this course. Click on Course Description to access the upload button. Currently you are pre-viewing previously uploaded Adapt file.',
          });
          setUploadingFileButton(false);
          setHeaderButtons(true);
          setOpenPreviewAdaptFile(true);
        } else if (apiData?.data?.version < versionNum) {
          previewAdaptFileCreateNewCourse(versionNum);
        }
      } else {
        setPreviewAdaptFileFlag(false);
        setIsAPICallInProgress(false);
        setNotificationData({
          show: true,
          type: 'WARNING',
          title: 'File Hosting Failed',
          description:
            'Adapt file hosting failed. Please re-uplaod the adapt file to publish this course. Click on Course Description to access the upload button.',
        });
        setUploadingFileButton(false);
        setHeaderButtons(true);
      }
    }
  };

  const uploadNewImagePath = () => {
    router.push({
      pathname: '/add-new-image',
      //query: {actionName: ""},
    });
  };

  const uploadImageFromDesktop = (event, file) => {
    setImageFileDesk(file);
    setNewImageData(event);
    // const test = .readAsDataURL(data)
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      var base64_data = reader.result;

      //setMainImg(base64_data);
      setMainImageBackup(base64_data);
    };
    uploadNewImagePath();
  };

  useEffect(() => {
    if (newThumbnailId?.length > 0 && mainImageBackup?.length > 0) {
      handleDataFill('thumbnailId', newThumbnailId);
    }
  }, [mainImageBackup]);

  useEffect(() => {
    openPreviewAdaptFile && openInNewTab('url');
  }, [openPreviewAdaptFile]);

  useEffect(() => {
    if (!apiData && newCourseData) {
      setFileData(newCourseData?.adaptFile);
      setCategoryData(newCourseData?.categoryData ? newCourseData?.categoryData : '');
      setPillsAddAudienceGroup(newCourseData?.pillsAddAudienceGroup);
      setNewCourseID(newCourseData?.newCourseID);
      setTypeCheckbox(newCourseData?.data?.types?.toString());
      setPillsDataKeyword(newCourseData?.keyword);
      setPillsDataSkills(newCourseData?.skills);
      setData((prevData) => ({
        ...prevData,
        ...newCourseData?.data,
        thumbnailId: newThumbnailId ? newThumbnailId : newCourseData?.data?.thumbnailId,
      }));
      setPublishButtonAfterPreview(newCourseData?.isPreviewAdaptFile);
      setAddUserGroupList(newCourseData?.addUserGroupList || []);
      setAddUserGroupListCopy(newCourseData?.addUserGroupListCopy || []);
      setDeleteUserGroupList(newCourseData?.deleteUserGroupList || []);
      newCourseData?.data?.courseState === 'Live' && setDisableStateUserManagement(true);
      newCourseData?.data?.courseState === 'Offline' && setDisableStateUserManagement(false);
    }
    if (
      (newCourseData?.data?.englishDescription?.length >= 3 ||
        newCourseData?.data?.chineseDescription?.length >= 3) &&
      (newCourseData?.data?.englishTitle?.length >= 3 ||
        newCourseData?.data?.chineseTitle?.length >= 3)
    ) {
      setIsCourseIDFetched(true);
    }

    if (mainImageBackup === null && newCourseData?.thumbnail) {
      setMainImageBackup(newCourseData?.thumbnail);
    }
    if (newCourseWizardState?.courseWizardStateOpen === 'edit') {
      setEditState(true);
      setMainState(false);
      setNextState(false);
    }
    newCourseData != null &&
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
  }, [newCourseData]);

  const publishNewCourse = () => {
    const date = new Date();
    if (allCheckFunc()) {
      let publishData = data;
      publishData.date = date.toISOString();
      publishData.courseState = 'Live';
      checkFun(publishData, true);
      setMainImageBackup(null);
    }
  };

  const changeCourseStatus = async (courseId, elementData, status) => {
    const apiData = await makeRequest({
      method: 'PATCH',
      endpoint: `/admin/api/v1/courses/${courseId}/${status}`,
    });
    if (apiData !== 'ERR_NETWORK') {
      status == 'archive' &&
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'Course Archived',
          description: `${elementData.englishTitle} has been archived by the ${oktaToken.idToken.claims.fullName}.`,
        });

      // await fetchCoursesList();
      fetchSelectedCourse(data.courseId);

      setNotificationListApi();
      setDashboardNotificationCountApi(currentUserId);
    }
    return closeCourseWizard() && setIsArchive(false);
  };

  const publishEditCourseDetails = async (publishCourse = false) => {
    let tempArr = [];
    tempArr = publishEditCourseArrayFun(
      publishCourse,
      data,
      addUserGroupList,
      deleteUserGroupList,
      fileData,
    );
    await updateCourseDetails(data?.id, tempArr, publishCourse);
    setTimeout(() => {
      setMainImageBackup(null);
      setNewCourseData(null);
    }, 1502);
  };
  const updateEditCourseDetails = async () => {
    let publishData = Object.assign({}, data);
    let tempCategories = '';
    let tempSkills = [];
    let tempKeywords = [];
    let addUserList = [];
    let deleteUserList = [];
    if (typeof data?.category === 'string') {
      tempCategories = data?.category;
    }
    if (typeof data?.category === 'object') {
      data?.category?.map((ele) => {
        tempCategories = ele?.id;
      });
    }
    data?.skills?.map((ele) => {
      ele.id ? tempSkills.push(ele.id) : tempSkills.push(ele);
    });
    data?.keywords?.map((ele) => {
      ele.id ? tempKeywords.push(ele.id) : tempKeywords.push(ele);
    });
    publishData.date = new Date().toISOString();
    publishData.courseState = 'Draft';

    if (publishData?.englishTitle?.length > 1 && publishData?.chineseTitle?.length > 1) {
      publishData.isMultilingual = Boolean(true);
    } else {
      publishData.isMultilingual = Boolean(false);
    }
    if (publishData?.types?.length > 0) {
      publishData.types = publishData?.types;
    }
    // else {
    //   publishData.types = publishData?.types;
    // }

    delete publishData?.id;
    delete publishData?.courseUrl;
    delete publishData?.thumbnail;
    delete publishData?.updatedAt;
    delete publishData?.updatedBy;
    // delete publishData?.types;
    delete publishData?.course_state;
    delete publishData?.filename;
    //delete publishData?.isMultilingual;
    delete publishData?.launchDate;
    delete publishData?.category;
    delete publishData?.skills;
    delete publishData?.keywords;
    delete publishData?.createdBy;

    publishData.category = tempCategories;
    publishData.skills = tempSkills;
    publishData.keywords = tempKeywords;
    publishData.version = data?.version;

    if (publishData?.thumbnailId === null) {
      delete publishData?.thumbnailId;
    }
    if (publishData?.adaptFileName === null) {
      delete publishData?.adaptFileName;
    }
    addUserGroupList?.forEach((ele) => {
      addUserList?.push({
        audGrpId: ele?.id,
        courseType: ele?.courseType,
      });
    });
    deleteUserGroupList?.forEach((ele) => {
      deleteUserList?.push({
        audGrpId: ele?.id,
        courseType: ele?.courseType,
      });
    });
    publishData.audienceGroups = {
      deleteAudGrpIds: deleteUserList,
      addAudGrpIds: addUserList,
    };
    await updateCourseDetails(data?.id, publishData);
  };

  const createNewCourse = async (file) => {
    let courseDetails = {};
    courseDetails = data;
    if (data?.audienceGroups?.addAudGrpIds?.length > 0) {
      courseDetails.audienceGroups = {
        deleteAudGrpIds: [],
        addAudGrpIds: data?.audienceGroups?.addAudGrpIds || [],
      };
    } else {
      delete courseDetails.audienceGroups;
    }
    const apiData = await makeRequest({
      method: 'POST',
      endpoint: '/admin/api/v1/course',
      body: JSON.stringify(courseDetails),
    });
    if (apiData) {
      setData((prevData) => ({
        ...prevData,
        ...apiData?.data,
      }));
      if (file) {
        uploadToS3(file, apiData?.data?.courseId);
      }
      setNewCourseID(apiData['data']);
      setIsCourseIDFetched(true);
    }
  };

  const callCreateCourseAPI = async (file) => {
    const {englishTitle, englishDescription} = data;
    if (data.courseId === null || data.courseId === undefined) {
      if (englishTitle && englishTitle?.length >= 3 && englishDescription) {
        await createNewCourse(file);
      } else {
        englishTitle &&
          englishTitle?.length >= 1 &&
          englishTitle?.length < 3 &&
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'Course Title is Mandatory',
            description: 'Fill Course Title and Course Description to Enable Upload Adapt File',
          });
      }
    }
  };
  // File Upload to S3 functionality
  const syncToS3 = async (url, fileData, id) => {
    await fetch(url, {
      method: 'PUT',
      body: fileData,
      headers: {
        'Content-Type': 'application/zip',
        'Content-Encoding': 'gzip',
      },
    })
      .then(async (res) => {
        if (res.statusText === 'OK') {
          setNotificationData({
            show: true,
            type: 'SUCCESS',
            title: 'Adapt File Uploaded Successfully',
            description: `${fileData?.name} has been uploaded.`,
          });
          // await fetchSelectedCourse(data.courseId);
          let versionNum = data.version ? Number(data.version) : 0;
          versionNum = versionNum + 1;

          setAdaptFileState('INPROGRESS');
          setOpenAdaptFileModal(true);
          if (editState) {
            previewAdaptFileApi(versionNum, id);
            // updateCourseDetails(data?.courseId,{adaptFileName:fileData?.name,courseState:data?.courseState
            //  ,version :versionNum
            // })
            //publishEditCourseDetails(false);
          } else if (mainState || nextState) {
            previewAdaptFileApi(versionNum, id);
            setPreviewAdaptFileFlag(true);
            //previewAdaptFileApiNew(versionNum);
          }
          setPublishButtonAfterPreview(true);
          setData((prevData) => ({
            ...prevData,
            version: versionNum,
          }));
        } else {
          setNotificationData({
            show: true,
            type: 'WARNING',
            title: 'Adapt file Failed Uploading',
            description: `${fileData?.name} has not been uploaded. Please re-upload the Adapt file.`,
          });
        }
        setUploadingFileButton(false);
        setSaveAndExitDisable(false);
        if (mainState) {
          setNextButtonDisable(false);
        } else if (allCheckFunc() && nextState) {
          setNextButtonDisable(false);
        } else {
          !allCheckFunc() && nextState && setNextButtonDisable(true);
        }
        setPublishButtonDisableEdit(false);
        setBtn2SaveAndExitDisable(false);
        setDisableImageLib(false);
        setHeaderButtons(true);
      })
      .catch((error) => {
        setUploadingFileButton(false);
        setSaveAndExitDisable(false);
        setNextButtonDisable(false);
        setPublishButtonDisableEdit(false);
        setBtn2SaveAndExitDisable(false);
        setDisableImageLib(false);
        setHeaderButtons(true);
      });
  };

  const uploadToS3 = async (fileData, id) => {
    setSaveAndExitDisable(true);
    setNextButtonDisable(true);
    setPublishButtonDisableEdit(true);
    setBtn2SaveAndExitDisable(true);
    setDisableImageLib(true);
    setUploadingFileButton(true);
    setHeaderButtons(false);
    const courseId = (newCourseID && newCourseID.courseId) || id;

    const apiData = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/courses/${courseId}/upload`,
    });
    if (apiData) {
      let url = apiData?.data?.preSignedUrl;

      syncToS3(url, fileData, courseId);
    } else {
      setUploadingFileButton(false);
    }
  };

  const handleButton = () => {
    !editState && !exitButtonEnable ? setIsLoading(true) : null;
    if (nextState || mainState) {
      if (
        (apiData?.course_state === 'Draft' ||
          newCourseData?.data?.course_state === 'Draft' ||
          apiData?.courseState === 'Draft' ||
          newCourseData?.data?.courseState === 'Draft') &&
        !openNewCourse &&
        !exitButtonEnable &&
        isCourseIDFetched &&
        (data?.id || data?.courseId)
      ) {
        updateEditCourseDetails();
        setTimeout(() => {
          closeCourseWizard();
          setNewCourseWizardState({
            courseWizardOpen: false,
            courseWizardStateOpen: '',
            section: '',
          });
          setMainImageBackup(null);
          setImageData({
            imageName: null,
            imageDescription: null,
            pillsData: null,
          });
          setNewImageData(null);

          setCroppedImageData(null);
          setCroppedImage_16_9(null);
          setCroppedImage_1_1(null);
          setSelectedImage_16_9_Backup(null);
          setSelectedImage_1_1_Backup(null);
          setNewCourseData(null);
        }, 1000);
      } else {
        saveAndExitClick();
        setTimeout(() => {
          closeCourseWizard();
          setNewCourseWizardState({
            courseWizardOpen: false,
            courseWizardStateOpen: '',
            section: '',
          });
          setMainImageBackup(null);
          setImageData({
            imageName: null,
            imageDescription: null,
            pillsData: null,
          });
          setNewImageData(null);
          setCroppedImageData(null);
          setCroppedImage_16_9(null);
          setCroppedImage_1_1(null);
          setSelectedImage_16_9_Backup(null);
          setSelectedImage_1_1_Backup(null);
          // fetchCoursesList();
        }, 1000);
      }
    } else if (editState) {
      setTimeout(() => {
        closeCourseWizard();
        setNewCourseWizardState({
          courseWizardOpen: false,
          courseWizardStateOpen: '',
          section: '',
        });
        setMainImageBackup(null);
      }, 1000);
      fetchSelectedCourse(data?.courseId);
    }
    setTimeout(() => {
      setImageData({
        imageName: null,
        imageDescription: null,
        pillsData: null,
      });
      setNewImageData(null);
      setMainImageBackup(null);
      setCroppedImageData(null);
      setCroppedImage_16_9(null);
      setCroppedImage_1_1(null);
      setSelectedImage_16_9_Backup(null);
      setSelectedImage_1_1_Backup(null);
      setIsLoading(false);
    }, 2000);
  };
  const handleNextButton = () => {
    setIsLoading(true);
    if (mainState) {
      setNextState(true);
      setMainState(false);
      let tempArr = [];
      let headerIndex = list.findIndex((data) => {
        return data.title == subheader;
      });
      if (headerIndex !== null && headerIndex !== -1) {
        list.forEach((data, index) => {
          if (index <= headerIndex + 1) {
            tempArr.push({
              className: data.className,
              title: data.title,
              subHeaderClick: true,
            });
          } else {
            tempArr.push(data);
          }
        });
      }
      setSubheader(list[headerIndex + 1].title);
      setList(tempArr);
      setNextButtonDisable(true);
      setHeaderButtons(true);
      setPreviewAdaptFileFlag(true);
      // fileData && previewAdaptFileApiNew();
      if (
        allCheckFunc() &&
        (apiData?.course_state === 'Draft' || newCourseData?.data?.course_state === 'Draft')
      ) {
        setNextButtonDisable(false);
        //setPublishButtonDisableEdit(false);
      } else if (allCheckFunc()) {
        setNextButtonDisable(false);
      }
      // previewAdaptFileFlag &&
      //   setNotificationData({
      //     show: true,
      //     type: "FAILURE",
      //     title: "Adapt File hosting is In-Progress",
      //     description: `${fileData?.name} is being hosted. Please wait for a few seconds before trying again.`,
      //   });
    } else if (nextState) {
      if (apiData?.course_state === 'Draft' || newCourseData?.data?.course_state === 'Draft') {
        publishEditCourseDetails(true);
      } else {
        publishNewCourse();
      }
      setTimeout(() => {
        closeCourseWizard();
        setNextState(false);
        setMainState(false);
        setNextButtonDisable(true);
        setHeaderButtons(false);
        setNewCourseWizardState({
          courseWizardOpen: false,
          courseWizardStateOpen: '',
          section: '',
        });
        setImageData({
          imageName: null,
          imageDescription: null,
          pillsData: null,
        });
        setMainImageBackup(null);
        setNewImageData(null);

        setCroppedImageData(null);
        setCroppedImage_16_9(null);
        setCroppedImage_1_1(null);
        setSelectedImage_16_9_Backup(null);
        setSelectedImage_1_1_Backup(null);
      }, 1500);
    } else if (editState) {
      publishEditCourseDetails(false);

      setTimeout(() => {
        closeCourseWizard();
        setNewCourseWizardState({
          courseWizardOpen: false,
          courseWizardStateOpen: '',
          section: '',
        });
        setImageData({
          imageName: null,
          imageDescription: null,
          pillsData: null,
        });
        setMainImageBackup(null);
        setNewImageData(null);

        setCroppedImageData(null);
        setCroppedImage_16_9(null);
        setCroppedImage_1_1(null);
        setSelectedImage_16_9_Backup(null);
        setSelectedImage_1_1_Backup(null);
      }, 1500);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  const subHeaderCategoriesClick = (val) => {
    if (allCheckFunc()) {
      setNextButtonDisable(false);
    } else {
      setNextButtonDisable(true);
    }
    setNextState(val);
    setMainState(false);
    //setEditState(false);
  };

  const subHeaderDescriptionClick = (val) => {
    if (
      data &&
      (data?.englishTitle?.length >= 3 || data?.chineseTitle?.length >= 3) &&
      (data?.englishDescription?.length >= 3 || data?.chineseDescription?.length >= 3)
    ) {
      setNextButtonDisable(false);
      setBlockNavigation(true);
    } else {
      setNextButtonDisable(true);
    }
    setNextState(false);
    setMainState(val);
    //setEditState(false);
  };

  const handleFileUpload = (event, id) => {
    //const fullFileName = event.target.files[0].name.split('.')
    //Size converted to MB
    const fileSize = event.target.files[0].size / 1000000;
    if (
      event.target.files[0].name.includes('.zip') &&
      (event.target.files[0].type == 'application/zip' ||
        event.target.files[0].type == 'application/x-zip-compressed') &&
      // ||
      // event.target.files[0].name.includes(".7z")&&event.target.files[0].type=="application/x-7z-compressed" ||
      // fullFileName[fullFileName?.length-1] == "rar" && event.target.files[0].type==""
      fileSize <= 100
    ) {
      setFileDataTemp(event.target.files[0]);
      if (id !== null && id !== undefined) {
        uploadToS3(event.target.files[0]);
      } else {
        callCreateCourseAPI(event.target.files[0]);
      }
      setNotificationData({
        show: true,
        type: 'UPLOADING',
        title: 'Adapt File upload has started',
        description: `${event.target.files[0]?.name} upload in-progress. `,
      });
      event.target.value = null;
    } else {
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Invalid File',
        // description: `Please upload .zip, .rar or .7zip files only!`,
        description: 'Please upload only .zip files of size 100MB max!',
      });
    }
  };

  const updateUserGroups = () => {
    let addUserList = [];
    let deleteUserList = [];
    addUserList = audienceListForEachFun(addUserGroupList);
    deleteUserList = audienceListForEachFun(deleteUserGroupList);
    setData((prevData) => ({
      ...prevData,
      audienceGroups: {
        deleteAudGrpIds: deleteUserList,
        addAudGrpIds: addUserList,
      },
    }));
  };
  useEffect(() => {
    isAPICallInProgress &&
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Adapt File hosting is In-Progress',
        description: `${fileData?.name} is being hosted. Please wait for a few seconds before trying again.`,
      });
  }, [isAPICallInProgress]);

  useEffect(() => {
    mainState && !apiData?.courseId && !newCourseData && handleUserTargetingGroupsList();
    apiData?.courseId && !openNewCourse && handleUserTargetingGroupsListDelete();
  }, [audienceGroup, apiData]);

  useEffect(() => {
    updateUserGroups();
  }, [addUserGroupList, deleteUserGroupList]);

  const handleUserTargetingGroupsListDelete = () => {
    let tempArr = [];
    let resultsDelete = [];
    if (audienceGroup?.length !== addUserGroupList?.length) {
      const results = audienceGroup?.filter(
        ({id: id1}) => !addUserGroupList.some(({id: id2}) => id2 === id1),
      );
      const ids = results.map((o) => o.id);
      resultsDelete = results.filter(({id}, index) => !ids.includes(id, index + 1));
      //resultsDelete = results?.filter(({ id: id1 }) => !deleteUserGroupList.some(({ id: id2 }) => id2 === id1));
      resultsDelete?.forEach((data) => {
        tempArr.push({
          id: data.id,
          title: data.title,
          courseType: 'General visibility',
          audienceGrpDescription: data?.audienceGrpDescription || data?.description,
        });
      });
    }
    setDeleteUserGroupList(tempArr);
  };

  const handleUserTargetingGroupsList = () => {
    let obj = {};
    let tempArr = [];
    audienceGroup?.forEach((data) => {
      obj = {
        title: data.title,
        audGrpId: data.id,
        audienceGrpDescription: data.audienceGrpDescription || data?.description,
        courseType: 'General visibility',
      };
      tempArr.push(obj);
    });
    setAddUserGroupList(tempArr);
  };

  const handleDeleteUserTargetingGroups = (obj, list, element) => {
    let tempArr = [];
    tempArr = list;
    const index = list?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    if (index !== undefined && index !== -1) {
      tempArr.splice(index, 1, obj);
    }
    setAddUserGroupList(tempArr);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };
  const handleAddUserTargetingGroups = (obj, list) => {
    let tempArr = [];
    let tempArr2 = deleteUserGroupList;
    tempArr = [...addUserGroupList, obj];
    setAddUserGroupList(tempArr);
    const index = deleteUserGroupList?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    if (index !== undefined && index !== -1) {
      tempArr2.splice(index, 1);
    }
    setDeleteUserGroupList(tempArr2);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };
  const handleDeleteUserGroups = (obj, list) => {
    let tempArr = [];
    let tempArr2 = addUserGroupList;
    const results = deleteUserGroupList?.find((data) => {
      return data.id == obj?.id || data.audGrpId == obj.id;
    });
    const val = addUserGroupListCopy?.find((data) => {
      return data.id == obj?.id || data.audGrpId == obj.id;
    });
    if (results == undefined) {
      if (val !== undefined) {
        tempArr = [...deleteUserGroupList, val];
      } else {
        tempArr = [...deleteUserGroupList, obj];
      }
    }
    const index = addUserGroupList?.findIndex((ele) => {
      return ele.id == obj.id || ele.audGrpId == obj.id;
    });
    if (index !== undefined && index !== -1) {
      tempArr2.splice(index, 1);
    }
    setDeleteUserGroupList(tempArr);
    setAddUserGroupList(tempArr2);
    updateUserGroups();
    editState || newThumbnailId?.length > 0 ? editSaveAndExitEnable(data) : null;
  };

  const disableClickBanner = () => {
    data.courseState === 'Live' &&
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Editing User Group Targeting is disabled for Live courses.',
        description: `Editing User Groups is possible only when the course is turned Offline. You can change the status in the Publish settings.`,
      });
  };

  const selectedSubHeaderNew = (title) => {
    if (title == 'Categories, Skills, Tags') {
      subHeaderCategoriesClick(true);
    } else if (title == 'Course Description') {
      subHeaderDescriptionClick(true);
    }
    setSubheader(title);
  };

  return (
    <React.Fragment>
      {/* Main Container start */}
      <div
        className={[styles.course_wizard_container, ''].join(' ')}
        ref={containerRef}
        id={'course_wrapper'}
      >
        {/* Header Component */}
        <Header
          isLoading={isLoading}
          headerName={
            editState && apiData
              ? apiData?.englishTitle
              : editState && newCourseData?.data?.englishTitle
              ? newCourseData?.data?.englishTitle
              : 'Create New Course Wizard'
          }
          headerXSName=" "
          btnName1={editState ? 'Cancel' : exitButtonEnable ? 'Exit' : 'Save & Exit'}
          btnName2={nextState ? 'Publish' : editState ? 'Save & Exit' : 'Next'}
          isHeaderXS={false}
          isBtn1={true}
          isBtn2={true}
          isPreviewAdaptFile={true}
          headerButtons={headerButtons}
          isProgressBar={!editState}
          header_btn_adapt_option={nextState || editState ? true : false}
          isBtn2Disable={editState ? btn2SaveAndExitDisable : nextButtonDisable}
          handleDisableClick={() =>
            mainState &&
            setNotificationData({
              show: true,
              type: 'WARNING',
              title: 'Course Title is Mandatory',
              description: 'Fill Course Title and Course Description to Enable Upload Adapt File',
            })
          }
          isBtn1Disable={saveAndExitDisable}
          handleButton={() => {
            setThumbnailImage169(null);
            handleButton();
          }}
          handleNextButton={() => {
            setThumbnailImage169(null);
            handleNextButton();
          }}
          subHeaderDescriptionClick={(val) => {
            !editState && subHeaderDescriptionClick(val);
          }}
          subHeaderCategoriesClick={(val) => {
            !editState && subHeaderCategoriesClick(val);
          }}
          showSubHeader={true}
          list={list}
          selectedSubHeaderNew={(name) => {
            !editState && selectedSubHeaderNew(name);
          }}
          subheader={subheader}
        />
        {/* Header Component */}

        <div className={[styles.course_wrapper, 'bootstrap-wrapper'].join(' ')}>
          {(mainState || nextState) && (
            <CreateWizard
              adaptFileButtonState={adaptFileButtonState}
              mainState={mainState}
              nextState={nextState}
              openAdaptFileModal={openAdaptFileModal}
              adaptFileState={adaptFileState}
              fileDataTemp={fileDataTemp}
              data={data}
              setOpenAdaptFileModal={setOpenAdaptFileModal}
              openInNewTab={openInNewTab}
              setFileData={setFileData}
              regexNameChinese={regexNameChinese}
              handleDataFill={handleDataFill}
              addUserGroupList={addUserGroupList}
              deleteUserGroupList={deleteUserGroupList}
              disableStateUserManagement={disableStateUserManagement}
              handleDeleteUserTargetingGroups={handleDeleteUserTargetingGroups}
              handleDeleteUserGroups={handleDeleteUserGroups}
              disableClickBanner={disableClickBanner}
              handleFileUpload={handleFileUpload}
              uploadingFileButton={uploadingFileButton}
              fileData={fileData}
              getEnableSaveAndExit={getEnableSaveAndExit}
              isCourseIDFetched={isCourseIDFetched}
              disableImageLib={disableImageLib}
              mainImageBackup={mainImageBackup}
              setImageData={setImageData}
              setThumbnailImage169={setThumbnailImage169}
              setCourseMode={setCourseMode}
              setNewCourseData={setNewCourseData}
              setNewCourseWizardState={setNewCourseWizardState}
              uploadImageFromDesktop={uploadImageFromDesktop}
              uploadNewImagePath={uploadNewImagePath}
              setNewThumbnailImageData={setNewThumbnailImageData}
              thumbnailImage169={thumbnailImage169}
              setNewImageData={setNewImageData}
              setMainImageBackup={setMainImageBackup}
              setCroppedImageData={setCroppedImageData}
              setCroppedImage_16_9={setCroppedImage_16_9}
              setCroppedImage_1_1={setCroppedImage_1_1}
              setSelectedImage_16_9_Backup={setSelectedImage_16_9_Backup}
              setSelectedImage_1_1_Backup={setSelectedImage_1_1_Backup}
              previewAdaptFileCreateNewCourse={previewAdaptFileCreateNewCourse}
              setPreviewAdaptFileFlag={setPreviewAdaptFileFlag}
              previewData={previewData}
              categories={categories}
              categoryData={categoryData}
              setCategoryData={setCategoryData}
              skills={skills}
              skillsRef={skillsRef}
              setPillsDataSkills={setPillsDataSkills}
              pillsDataSkills={pillsDataSkills}
              pillsAddAudienceGroup={pillsAddAudienceGroup}
              pillsDataKeyword={pillsDataKeyword}
              filterListById={filterListById}
              handleAddUserTargetingGroups={handleAddUserTargetingGroups}
              setNotificationData={setNotificationData}
              newCourseID={newCourseID}
              addUserGroupListCopy={addUserGroupListCopy}
              publishButtonAfterPreview={publishButtonAfterPreview}
              keyword={keyword}
              keywordsRef={keywordsRef}
              setPillsDataKeyword={setPillsDataKeyword}
              SearchListComponent={SearchListComponent}
              closeCourseWizard={closeCourseWizard}
              isLoading={isLoading}
            />
          )}

          {/* Edit Course screen 3 Started */}
          {editState && (
            <EditWizard
              openAdaptFileModal={openAdaptFileModal}
              adaptFileState={adaptFileState}
              fileDataTemp={fileDataTemp}
              data={data}
              setOpenAdaptFileModal={setOpenAdaptFileModal}
              openInNewTab={openInNewTab}
              setFileData={setFileData}
              regexNameChinese={regexNameChinese}
              handleDataFill={handleDataFill}
              addUserGroupList={addUserGroupList}
              deleteUserGroupList={deleteUserGroupList}
              disableStateUserManagement={disableStateUserManagement}
              handleDeleteUserTargetingGroups={handleDeleteUserTargetingGroups}
              handleDeleteUserGroups={handleDeleteUserGroups}
              disableClickBanner={disableClickBanner}
              handleFileUpload={handleFileUpload}
              uploadingFileButton={uploadingFileButton}
              fileData={fileData}
              isCourseIDFetched={isCourseIDFetched}
              mainImageBackup={mainImageBackup}
              setImageData={setImageData}
              setThumbnailImage169={setThumbnailImage169}
              setCourseMode={setCourseMode}
              setNewCourseData={setNewCourseData}
              setNewCourseWizardState={setNewCourseWizardState}
              uploadImageFromDesktop={uploadImageFromDesktop}
              uploadNewImagePath={uploadNewImagePath}
              setNewThumbnailImageData={setNewThumbnailImageData}
              thumbnailImage169={thumbnailImage169}
              previewAdaptFileCreateNewCourse={previewAdaptFileCreateNewCourse}
              previewData={previewData}
              categories={categories}
              categoryData={categoryData}
              setCategoryData={setCategoryData}
              skills={skills}
              skillsRef={skillsRef}
              setPillsDataSkills={setPillsDataSkills}
              pillsDataSkills={pillsDataSkills}
              pillsAddAudienceGroup={pillsAddAudienceGroup}
              pillsDataKeyword={pillsDataKeyword}
              filterListById={filterListById}
              handleAddUserTargetingGroups={handleAddUserTargetingGroups}
              setNotificationData={setNotificationData}
              newCourseID={newCourseID}
              addUserGroupListCopy={addUserGroupListCopy}
              publishButtonAfterPreview={publishButtonAfterPreview}
              keyword={keyword}
              keywordsRef={keywordsRef}
              setPillsDataKeyword={setPillsDataKeyword}
              SearchListComponent={SearchListComponent}
              fetchSelectedCourse={fetchSelectedCourse}
              setOpenDecommission={setOpenDecommission}
              openDecommission={openDecommission}
              setIsArchive={setIsArchive}
              isArchive={isArchive}
              changeCourseStatus={changeCourseStatus}
              closeCourseWizard={closeCourseWizard}
              isLoading={isLoading}
            />
          )}
          {/* Edit Course screen 3 Ended */}
        </div>
      </div>
      {notificationData?.show && <Notification />}
    </React.Fragment>
  );
};

CourseWizardComponent.props = {};
const mapStateToProps = (state, ownProps) => ({
  allState: state,
  allProps: ownProps,
  notificationData: state && state.uiState && state.uiState.notificationData,
  newCourseData: state && state.courseState && state.courseState.newCourseData,
  newImageData: state && state.courseState && state.courseState.newImageData,
  newThumbnailImage: state && state.courseState && state.courseState.newThumbnailImage,
  mainImageBackup: state && state.croppedImage && state.croppedImage.mainImageBackup,
  newAdaptFile: state && state.courseState && state.courseState.newAdaptFile,
  newCourseWizardState: state && state.courseState && state.courseState.newCourseWizardState,
  newThumbnailId: state && state.courseState && state.courseState.newThumbnailId,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
  thumbnailImage169: state && state.croppedImage && state.croppedImage.thumbnailImage169,
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setMainImageBackup: (options) => dispatch(setMainImageBackup(options)),
  setNewCourseData: (options) => dispatch(setNewCourseData(options)),
  setNewImageData: (options) => dispatch(setNewImageData(options)),
  setNewCourseWizardState: (options) => dispatch(setNewCourseWizardState(options)),
  setNewAdaptFile: (options) => dispatch(setNewAdaptFile(options)),
  setCourseMode: (options) => dispatch(setCourseMode(options)),
  setBlockNavigation: (options) => dispatch(setBlockNavigation(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setSelectedImage_16_9_Backup: (options) => dispatch(setSelectedImage_16_9_Backup(options)),
  setSelectedImage_1_1_Backup: (options) => dispatch(setSelectedImage_1_1_Backup(options)),
  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),
  setCroppedImageData: (options) => dispatch(setCroppedImageData(options)),
  setImageData: (options) => dispatch(setImageData(options)),
  setNewThumbnailImageData: (options) => dispatch(setNewThumbnailImageData(options)),
  setNewThumbnailId: (options) => dispatch(setNewThumbnailId(options)),
  setThumbnailImage169: (options) => dispatch(setThumbnailImage169(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CourseWizardComponent));
