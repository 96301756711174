import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styles from './Drawer.module.scss';
import DrawerFailScreen from '../../shared/drawerFailScreen';
import StatusLabel from '../../shared/StatusLabel';
import CourseTypeBannerViewOnly from '../../shared/CourseTypeBannerViewOnly';
import config from '../../../config';
import DrawerDropDownListWithInfoIcon from '../../shared/DrawerDropDownListWithInfoIcon';
import {
  dummyFunction,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  drawerCalculatedHeight,
  pullbox,
} from '../../utils/commonUtil';
export const handleClickOutside = (props) => {
  const {closeDrawerModal, e} = props;
  // console.log(">>>> >>>> handleClickOutside >>>> props",props)
  const el = document.getElementById('divParentOfFilter');
  if (el !== null) {
    const outside = !el?.contains(e.target);
    if (outside) {
      if (e.target.className.includes('Drawer_custom')) {
        closeDrawerModal();
      }
    }
  }
};

const DrawerComponent = (props) => {
  const {
    drawerDetails = {},
    drawerOpen = false,
    drawerClose,
    isAPIFailed = false,
    drawerTryAgainCall = dummyFunction,
    drawerIdForBrokenLink = '',
  } = props;

  const router = useHistory();
  const {query = {}} = router.location;
  const {currentEnv} = config;

  const [isInfoIisClicked, setInfoIisClicked] = useState(false);
  const [isCoursesDrawerList, setCoursesDrawerList] = useState(true);
  const [width, setWidth] = useState(0);

  const [isInfoIisClickedAudience, setInfoIisClickedAudience] = useState(false);
  const [isLearninngPathDrawerList, setLearninngPathDrawerList] = useState(true);

  const [drawerHeight, setDrawerHeight] = useState('');
  useEffect(() => {
    setCoursesDrawerList(true);
    setLearninngPathDrawerList(true);
  }, [drawerDetails]);
  const closeDrawerModal = () => {
    drawerClose(!drawerOpen);
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      handleClickOutside({e: e, closeDrawerModal: closeDrawerModal});
    });
    setDrawerHeight(140);
  }, [drawerOpen]);

  const openManageCourses = () => {
    router.push({
      pathname: '/ManageCourses',
      query: {
        isManageCourseOpen: true,
        AudienceGroupId: drawerDetails?.audienceGroupId,
        AudienceGrpName: drawerDetails?.audienceGrpName,
        CourseCount: drawerDetails?.courseCount,
      },
    });
  };

  const openManageLearningPath = () => {
    router.push({
      pathname: '/ManageLearningPath',
      query: {
        isManageCourseOpen: true,
        AudienceGroupId: drawerDetails?.audienceGroupId,
        AudienceGrpName: drawerDetails?.audienceGrpName,
        CourseCount: drawerDetails?.learningPathCount,
      },
    });
  };

  useEffect(() => {
    if (drawerHeight >= 600) {
      closeDrawerModal();
    }
  }, [drawerHeight]);

  return (
    <React.Fragment>
      {drawerOpen && (
        <div id="divParentOfFilter" className={[styles.drawer_container, ''].join(' ')}>
          <div
            className={styles.custom_overlay}
            style={drawerOverlayCalculatedHeight({drawerHeight: drawerHeight})}
          ></div>
          <div
            className={styles.drawer_wrapper}
            style={drawerCalculatedHeight({drawerHeight: drawerHeight})}
          >
            <div
              className={styles.mob_bar}
              draggable
              onTouchMove={(e) => {
                pullbox({e: e, setDrawerHeight: setDrawerHeight});
              }}
              onTouchEnd={() => {
                mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
              }}
            >
              <div className={styles.line_bar} onClick={() => closeDrawerModal()}></div>
            </div>
            {!isAPIFailed ? (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>User Group Details</div>
                  <div className={styles.close_wrapper} onClick={() => closeDrawerModal()}>
                    <div className={styles.close_icon} />
                  </div>
                </div>
                <div className={styles.course_title}>{drawerDetails?.audienceGrpName}</div>
                <div className={styles.description_wrapper}>
                  <div className={styles.label}>Description</div>
                  <div className={styles.value}>{drawerDetails?.audienceGrpDesc}</div>
                </div>

                <div
                  className={[styles.img_instances_container, styles.lp_user_grp_mgt, ''].join(' ')}
                >
                  {' '}
                  {/* LP  => add this class lp_user_grp_mgt */}
                  {drawerDetails?.courses?.length > 0 && (
                    <DrawerDropDownListWithInfoIcon
                      openManageCourses={openManageCourses}
                      setCoursesDrawerList={setCoursesDrawerList}
                      isCoursesDrawerList={isCoursesDrawerList}
                      drawerDetailsList={drawerDetails?.courses}
                      drawerDetailsCount={drawerDetails?.courseCount}
                      isInfoIisClicked={isInfoIisClicked}
                      width={width}
                      setInfoIisClicked={setInfoIisClicked}
                      title={'Courses'}
                      headerOfBanner={'Course'}
                    />
                  )}
                  {drawerDetails?.courses?.length === 0 ? (
                    <div className={styles.add_course_btn_wrapper}>
                      <div
                        className={styles.add_course_ag_btn}
                        onClick={() => {
                          openManageCourses();
                        }}
                      >
                        <div className={styles.plus_icon}></div>
                        <div className={styles.txt}>Add Courses to this User Group</div>
                      </div>
                    </div>
                  ) : null}
                  {/* Manage LP Starts */}
                  {drawerDetails?.learningPaths?.length > 0 && (
                    <DrawerDropDownListWithInfoIcon
                      openManageCourses={openManageLearningPath}
                      setCoursesDrawerList={setLearninngPathDrawerList}
                      isCoursesDrawerList={isLearninngPathDrawerList}
                      drawerDetailsList={drawerDetails?.learningPaths}
                      drawerDetailsCount={drawerDetails?.learningPathCount}
                      isInfoIisClicked={isInfoIisClickedAudience}
                      width={width}
                      setInfoIisClicked={setInfoIisClickedAudience}
                      title={'Learning Paths'}
                      headerOfBanner={'Learning Path'}
                    />
                  )}
                  {drawerDetails?.learningPaths?.length === 0 ? (
                    <div className={styles.add_course_btn_wrapper}>
                      <div
                        className={styles.add_course_ag_btn}
                        onClick={() => {
                          openManageLearningPath();
                        }}
                      >
                        <div className={styles.plus_icon}></div>
                        <div className={styles.txt}>Add Learning Paths to this User Group</div>
                      </div>
                    </div>
                  ) : null}
                  {/* Manage LP Ends */}
                </div>
              </div>
            ) : (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>User Group Details</div>
                  <div className={styles.close_wrapper} onClick={() => closeDrawerModal()}>
                    <div className={styles.close_icon} />
                  </div>
                </div>
                <DrawerFailScreen
                  drawerId={drawerIdForBrokenLink}
                  drawerTryAgainCall={(item) => {
                    drawerTryAgainCall(item);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

DrawerComponent.propTypes = {};

export default DrawerComponent;
