export const _initialFilterData = {
  tiles: [
    {
      index: 0,
      id: 'DRAFT',
      title: 'Draft',
      className: 'draft',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 1,
      id: 'LIVE',
      title: 'Live',
      className: 'goLive',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 2,
      id: 'OFFLINE',
      title: 'Offline',
      className: 'goOffline',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 3,
      id: 'ARCHIEVE',
      title: 'Archive',
      className: 'archive',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
  ],
  filtersList: [
    {
      filterType: 'RADIO',
      filterTypeData: {
        sectionTitle: 'Sort By',
        sectionData: [
          {
            index: 0,
            name: 'Recent Activity',
            id: 1,
            checked: true,
            filterParameter: 'name',
            filterParameterType: 'default',
            typeOfFilter: 'RADIO',
          },
          {
            index: 1,
            name: 'A - Z',
            id: 2,
            checked: false,
            filterParameter: 'title',
            filterParameterType: 'ASC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 2,
            name: 'Z - A',
            id: 3,
            checked: false,
            filterParameter: 'title',
            filterParameterType: 'DESC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 3,
            name: 'Newest To Oldest',
            id: 4,
            checked: false,
            filterParameter: 'launch_date',
            filterParameterType: 'TIME-ASC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 4,
            name: 'Oldest To Newest',
            id: 5,
            checked: false,
            filterParameter: 'launch_date',
            filterParameterType: 'TIME-DESC',
            typeOfFilter: 'RADIO',
          },
        ],
      },
    },
    {
      filterType: 'CHECKBOX',
      filterTypeData: {
        sectionTitle: 'Categories',
        sectionData: [],
      },
    },
  ],
};

export const _initialFilterDataUGM = {
  filtersList: [
    {
      filterType: 'RADIO',
      filterTypeData: {
        sectionTitle: 'Sort By',
        sectionData: [
          {
            index: 0,
            name: 'Recent Activity',
            id: 1,
            checked: true,
            filterParameter: 'name',
            filterParameterType: 'default',
            typeOfFilter: 'RADIO',
            paramForAPI: 'Recent_Activity',
          },
          {
            index: 1,
            name: 'A - Z',
            id: 2,
            checked: false,
            filterParameter: 'description',
            filterParameterType: 'ASC',
            typeOfFilter: 'RADIO',
            paramForAPI: 'A_Z',
          },
          {
            index: 2,
            name: 'Z - A',
            id: 3,
            checked: false,
            filterParameter: 'description',
            filterParameterType: 'DESC',
            typeOfFilter: 'RADIO',
            paramForAPI: 'Z_A',
          },
          {
            index: 3,
            name: 'Most Courses',
            id: 4,
            checked: false,
            filterParameter: 'updatedAt',
            filterParameterType: 'TIME-ASC',
            typeOfFilter: 'RADIO',
            paramForAPI: 'most_courses',
          },
          {
            index: 4,
            name: 'Least Courses',
            id: 5,
            checked: false,
            filterParameter: 'updatedAt',
            filterParameterType: 'TIME-DESC',
            typeOfFilter: 'RADIO',
            paramForAPI: 'least_courses',
          },
        ],
      },
    },
  ],
};

export const _initialFilterDataLP = {
  tiles: [
    {
      index: 0,
      id: 'DRAFT',
      title: 'Draft',
      className: 'draft',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 1,
      id: 'LIVE',
      title: 'Live',
      className: 'goLive',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 2,
      id: 'OFFLINE',
      title: 'Offline',
      className: 'goOffline',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
    {
      index: 3,
      id: 'ARCHIEVE',
      title: 'Archive',
      className: 'archive',
      filterParameter: 'course_state',
      typeOfFilter: 'tiles',
      checked: false,
    },
  ],
  filtersList: [
    {
      filterType: 'RADIO',
      filterTypeData: {
        sectionTitle: 'Sort By',
        sectionData: [
          {
            index: 0,
            name: 'Recent Activity',
            id: 1,
            checked: true,
            filterParameter: 'name',
            filterParameterType: 'default',
            typeOfFilter: 'RADIO',
          },
          {
            index: 1,
            name: 'A - Z',
            id: 2,
            checked: false,
            filterParameter: 'title',
            filterParameterType: 'ASC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 2,
            name: 'Z - A',
            id: 3,
            checked: false,
            filterParameter: 'title',
            filterParameterType: 'DESC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 3,
            name: 'Newest To Oldest',
            id: 4,
            checked: false,
            filterParameter: 'launch_date',
            filterParameterType: 'TIME-ASC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 4,
            name: 'Oldest To Newest',
            id: 5,
            checked: false,
            filterParameter: 'launch_date',
            filterParameterType: 'TIME-DESC',
            typeOfFilter: 'RADIO',
          },
        ],
      },
    },
    {
      filterType: 'CHECKBOX',
      filterTypeData: {
        sectionTitle: 'Categories',
        sectionData: [],
      },
    },
  ],
};

export const sortData = [
  {
    name: 'Recent Activity',
    id: 5,
    checked: false,
    paramForAPI: 'Recent_Activity',
  },
  {name: 'A - Z', id: 1, checked: false, paramForAPI: 'A_Z'},
  {name: 'Z - A', id: 2, checked: false, paramForAPI: 'Z_A'},
  {
    name: 'Newest To Oldest',
    id: 3,
    checked: false,
    paramForAPI: 'Newest_To_Oldest',
  },
  {
    name: 'Oldest To Newest',
    id: 4,
    checked: false,
    paramForAPI: 'Oldest_To_Newest',
  },
];
