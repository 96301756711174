import React, {useEffect, useState, useRef} from 'react';
import LoadingShimmerList from '../../shared/LoadingShimmerList';
import styles from './TableComponent.module.scss';
import config from '../../../config';
import {dummyFunction} from '../../utils/commonUtil';
const TableListComponent = (props) => {
  const {
    list,
    EmptyScreenComponent,
    isSearchFilterEmptyState,
    onClickAudienceGrp = dummyFunction,
    stickyHeader = false,
    UGList = false,
    drawerDetails = {},
    drawerOpen = false,
    headerColor = 'black',
    small = false,
    tableHeaderBold = false,
    componentUGM = false,
    drawerIdForBrokenLink = '',
    isLoading,
  } = props;
  //console.log('>>>> >>>> TableListComponent >>>> props',props);
  const [defaultList, setDefaultList] = useState([]);
  const [defaultLoading, setDefaultLoading] = useState(true);
  const [secondElementList, setSecondElementList] = useState([]);
  const [audienceGroupList, setAudienceGroupList] = useState([]);
  const {currentEnv} = config;
  useEffect(() => {
    setDefaultList(list);
    setAudienceGroupList(list);
  }, [list]);
  useEffect(() => {
    setDefaultLoading(isLoading);
  }, [isLoading]);
  return (
    <React.Fragment>
      {audienceGroupList?.length > 0 && !defaultLoading ? (
        <div
          className={[
            styles.table_container,
            stickyHeader ? styles.sticky_header_user_grp : '',
            headerColor == 'white' ? styles.is_white_header : styles.is_drawer_open,
            UGList ? styles.ug_list_wrapper : '',
          ].join(' ')}
        >
          <div
            className={[
              headerColor == 'white' ? styles.tbl_header_white : styles.tbl_header,
              tableHeaderBold ? styles.head_bold : '',
            ].join(' ')}
          >
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              User Group Name
            </div>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              {/* <div
                className={headerColor == 'white' ? styles.courses_icon_white : styles.courses_icon}
              /> */}
              <div
                className={[
                  headerColor == 'white' ? styles.txt_white : styles.txt,
                  styles.pd_0,
                ].join(' ')}
              >
                Instances
              </div>
            </div>
          </div>
          <div className={styles.tbl_body}>
            {audienceGroupList?.map((ele, index) => {
              return (
                <div className={[styles.tbl_row_wrapper].join(' ')} key={index}>
                  <div
                    id={ele?.audienceGroupId}
                    className={[
                      styles.tbl_row,
                      (ele?.audienceGroupId === drawerDetails?.audienceGroupId ||
                        ele?.audienceGroupId === drawerIdForBrokenLink) &&
                      drawerOpen
                        ? styles.selected
                        : '',
                    ].join(' ')}
                    onClick={(e) => onClickAudienceGrp(e, ele?.audienceGroupId)}
                  >
                    <div className={styles.tbl_data}>
                      <div className={[styles.inner_data, 'ellipsis'].join(' ')}>
                        <div className={[styles.txt, styles.pr_5].join(' ')}>
                          {ele?.audienceGrpName}
                        </div>
                      </div>
                    </div>
                    <div className={[styles.tbl_data, styles.highlight_blue].join(' ')}>
                      <div
                        className={styles.inner_data}
                        //    onClick={() => handleCoursesModal()}
                      >
                        <div className={styles.data_wrapper}>
                          <div className={styles.courses_icon} />
                          <div className={[styles.txt].join(' ')}>{ele?.courseCount}</div>
                        </div>
                        <div className={styles.data_wrapper}>
                          <div className={styles.learning_icon} />
                          <div className={[styles.txt].join(' ')}>{ele?.learningPathCount}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : isSearchFilterEmptyState && !defaultLoading ? (
        <EmptyScreenComponent small={small} />
      ) : (
        defaultLoading && (
          <div
            className={[
              styles.table_container,
              stickyHeader ? styles.sticky_header_user_grp : '',
              headerColor == 'white' ? styles.is_white_header : styles.is_drawer_open,
              UGList ? styles.ug_list_wrapper : '',
            ].join(' ')}
          >
            <div
              className={[
                headerColor == 'white' ? styles.tbl_header_white : styles.tbl_header,
                tableHeaderBold ? styles.head_bold : '',
              ].join(' ')}
            >
              <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
                User Group Name
              </div>
              <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
                {/* <div
              className={headerColor == 'white' ? styles.courses_icon_white : styles.courses_icon}
            /> */}
                <div
                  className={[
                    headerColor == 'white' ? styles.txt_white : styles.txt,
                    styles.pd_0,
                  ].join(' ')}
                >
                  Instances
                </div>
              </div>
            </div>
            <div className={styles.tbl_body}>
              <LoadingShimmerList count={30} />
            </div>
          </div>
        )
      )}
    </React.Fragment>
  );
};

TableListComponent.propTypes = {};

export default TableListComponent;
