import React, {useEffect, useState, useRef} from 'react';
import LoadingShimmerList from '../../shared/LoadingShimmerList';
import styles from './TableComponentCST.module.scss';
import {dummyFunction} from '../../utils/commonUtil';
const TableListComponentCST = (props) => {
  const {
    list,
    EmptyScreenComponent,
    isSearchFilterEmptyState,
    onClickAudienceGrp = dummyFunction,
    stickyHeader = false,
    drawerDetails = {},
    drawerOpen = false,
    headerColor = 'black',
    componentClass = '',
    small = false,
    drawerIdForBrokenLink = '',
    isLoading,
  } = props;
  //console.log(">>>> >>>> TableListComponentCST >>>> props",props);
  const [defaultList, setDefaultList] = useState([]);
  const [secondElementList, setSecondElementList] = useState([]);
  const [audienceGroupList, setAudienceGroupList] = useState([]);
  useEffect(() => {
    setDefaultList(list);
    setAudienceGroupList(list);
  }, [list]);
  return (
    <React.Fragment>
      {audienceGroupList?.length > 0 && !isLoading ? (
        <div
          className={[
            styles.table_container,
            stickyHeader ? styles.sticky_header_user_grp : '',
            headerColor == 'white' ? styles.is_white_header : styles.is_drawer_open,
            componentClass == 'category_main_wrapper' ? styles.category_main_wrapper : '',
          ].join(' ')}
        >
          <div className={headerColor == 'white' ? styles.tbl_header_white : styles.tbl_header}>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              Type
            </div>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              Instances
            </div>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              English
            </div>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              Chinese
            </div>
          </div>
          <div className={styles.tbl_body}>
            {audienceGroupList?.map((ele, index) => {
              return (
                <div className={[styles.tbl_row_wrapper].join(' ')} key={index}>
                  <div
                    id={ele?.id}
                    className={[
                      styles.tbl_row,
                      (ele?.id === drawerDetails?.id || ele?.id === drawerIdForBrokenLink) &&
                      drawerOpen
                        ? styles.selected
                        : '',
                    ].join(' ')}
                    onClick={(e) => onClickAudienceGrp(e, ele)}
                  >
                    <div className={styles.tbl_data}>
                      <div className={[styles.inner_data, 'ellipsis'].join(' ')}>
                        <div className={[styles.txt, styles.pr_5].join(' ')}>{ele?.type}</div>
                      </div>
                    </div>
                    <div className={[styles.tbl_data].join(' ')}>
                      <div
                        className={styles.inner_data}
                        //    onClick={() => handleCoursesModal()}
                      >
                        <div className={styles.courses_icon} />
                        <div className={[styles.txt].join(' ')}>{ele?.coursesCount}</div>
                      </div>
                      <div
                        className={styles.inner_data}
                        //    onClick={() => handleCoursesModal()}
                      >
                        <div className={styles.learning_path_icon} />
                        <div className={[styles.txt].join(' ')}>{ele?.learningPathsCount}</div>
                      </div>
                    </div>
                    <div className={styles.tbl_data}>
                      <div className={[styles.inner_data, 'ellipsis'].join(' ')}>
                        <div className={[styles.txt, styles.pr_5].join(' ')}>
                          {ele?.englishTranslation}
                        </div>
                      </div>
                    </div>
                    <div className={styles.tbl_data}>
                      <div className={[styles.inner_data, 'ellipsis'].join(' ')}>
                        <div className={[styles.txt, styles.pr_5].join(' ')}>
                          {ele?.chineseTranslation}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : isSearchFilterEmptyState && !isLoading ? (
        <EmptyScreenComponent small={false} />
      ) : isLoading ? (
        <div
          className={[
            styles.table_container,
            stickyHeader ? styles.sticky_header_user_grp : '',
            headerColor == 'white' ? styles.is_white_header : styles.is_drawer_open,
            componentClass == 'category_main_wrapper' ? styles.category_main_wrapper : '',
          ].join(' ')}
        >
          <div className={headerColor == 'white' ? styles.tbl_header_white : styles.tbl_header}>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              Type
            </div>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              Instances
            </div>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              English
            </div>
            <div className={headerColor == 'white' ? styles.tbl_head_white : styles.tbl_head}>
              Chinese
            </div>
          </div>
          <div className={styles.tbl_body}>
            <LoadingShimmerList count={20} />{' '}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

TableListComponentCST.propTypes = {};

export default TableListComponentCST;
