import React, {createRef, useEffect, useRef, useState} from 'react';
import styles from '../createNewLearningPath/CreateNewLP.module.scss';
import CardNewComponent from '../../shared/cardNewComponent';
import NoteCardComponent from '../../shared/noteCard';
const UserTargetingLP = ({
  deleteUserGroupList,
  addUserGroupList,
  disableStateUserManagement,
  handleDeleteUserTargetingGroups,
  handleDeleteUserGroups,
  disableClickBanner,
  handleAddUserTargetingGroups,
  isLoadingApi,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(isLoadingApi);
  }, [isLoadingApi]);
  return (
    <div className={[styles.info_container, ''].join(' ')}>
      <div
        className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-12', ''].join(
          ' ',
        )}
      >
        <div className={styles.title_wrapper}>
          <div className={styles.title}>User Group Targeting</div>
          <div className={styles.txt}>
            Target this Learning Path to the User Groups mentioned below. If you do not want this
            Learning Path to be accessible to a particular User Group(s), you can remove them from
            the course accessibility list. You can specify the Learning Path type by selecting from
            the options given below for each User Group.
          </div>
        </div>
        <div className={styles.user_grp_row_wrapper}>
          <div className={[styles.form_field, styles.info_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
            {
              <>
                {addUserGroupList?.length == 0 && deleteUserGroupList?.length > 0 && (
                  <div className={styles.note_wrapper}>
                    <NoteCardComponent textName={'Learning Path'} />
                  </div>
                )}
                <div className={styles.card_list_wrapper}>
                  {addUserGroupList?.length > 0 && (
                    <div className={styles.title}>User Groups with Learning Path Access</div>
                  )}
                  <div className={styles.card_wrapper}>
                    {addUserGroupList?.map((element, index) => {
                      return (
                        <CardNewComponent
                          key={index}
                          ugCourseTitle={element?.title}
                          id={element?.id || element?.audGrpId}
                          isDisabled={disableStateUserManagement}
                          audienceGrpDescription={element?.audienceGrpDescription}
                          onHandleClickRadio={(ele, id) => {
                            handleDeleteUserTargetingGroups(
                              {
                                courseType: ele,
                                id,
                                title: element?.title,
                                audienceGrpDescription: element?.audienceGrpDescription,
                              },
                              addUserGroupList,
                            );
                          }}
                          courseType={element?.courseType}
                          onButtonClick={() => {
                            handleDeleteUserGroups({
                              courseType: element?.courseType,
                              id: element?.id || element?.audGrpId,
                              title: element?.title,
                              audienceGrpDescription: element?.audienceGrpDescription,
                            });
                          }}
                          disableClickBanner={() => {
                            disableClickBanner();
                          }}
                          isQuickStart={false}
                          bonusIcon={true}
                        />
                      ); /* single_line_course_type => Use this className for single row radio item */
                    })}
                  </div>
                </div>
              </>
            }
            {
              <>
                <div className={styles.card_list_wrapper}>
                  {deleteUserGroupList?.length > 0 && (
                    <div className={styles.title}>User Groups without Learning Path Access</div>
                  )}
                  <div className={styles.card_wrapper}>
                    {deleteUserGroupList?.map((element, index) => {
                      return (
                        <CardNewComponent
                          key={index}
                          ugCourseTitle={element?.title}
                          withoutCourses={true}
                          onHandleClickRadio={(ele) => {
                            handleAddUserTargetingGroups({
                              courseType: ele,
                              id: element?.id,
                            });
                          }}
                          courseType={element?.courseType}
                          id={element?.id || element?.audGrpId}
                          isDisabled={disableStateUserManagement}
                          onButtonClick={() => {
                            handleAddUserTargetingGroups({
                              courseType: 'General visibility',
                              id: element?.id || element?.audGrpId,
                              title: element?.title,
                              audienceGrpDescription: element?.audienceGrpDescription,
                            });
                          }}
                          disableClickBanner={() => {
                            disableClickBanner();
                          }}
                          audienceGrpDescription={element?.audienceGrpDescription}
                          bonusIcon={true}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

UserTargetingLP.propTypes = {};
export default UserTargetingLP;
