/* eslint-disable  no-undef */
export const configTW = {
  TW: {
    apiConfig: {
      dev: {
        baseURL: process.env.REACT_APP_TW_DEV_BASE_URL,
      },
      qa: {
        baseURL: process.env.REACT_APP_TW_QA_BASE_URL,
      },
      prod: {
        baseURL: process.env.REACT_APP_TW_PROD_BASE_URL,
      },
    },
    oidc: {
      dev: {
        clientId: process.env.REACT_APP_TW_OIDC_DEV_CLIENT_ID,
        issuer: process.env.REACT_APP_TW_OIDC_ISSUER,
        redirectUri: process.env.REACT_APP_TW_OIDC_DEV_REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        useInteractionCode: false,
      },
      qa: {
        clientId: process.env.REACT_APP_TW_OIDC_QA_CLIENT_ID,
        issuer: process.env.REACT_APP_TW_OIDC_ISSUER,
        redirectUri: process.env.REACT_APP_TW_OIDC_QA_REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        useInteractionCode: false,
      },
      prod: {
        clientId: process.env.REACT_APP_TW_OIDC_PROD_CLIENT_ID,
        issuer: process.env.REACT_APP_TW_OIDC_ISSUER,
        redirectUri: process.env.REACT_APP_TW_OIDC_PROD_REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        useInteractionCode: false,
      },
    },
    functionalityFlags: {
      showChinese: true,
    },
  },
};

export const configIN = {
  IN: {
    apiConfig: {
      dev: {
        baseURL: process.env.REACT_APP_IN_DEV_BASE_URL,
      },
      qa: {
        baseURL: process.env.REACT_APP_IN_QA_BASE_URL,
      },
      prod: {
        baseURL: process.env.REACT_APP_IN_PROD_BASE_URL,
      },
    },
    oidc: {
      dev: {
        clientId: process.env.REACT_APP_IN_OIDC_DEV_CLIENT_ID,
        issuer: process.env.REACT_APP_IN_OIDC_ISSUER,
        redirectUri: process.env.REACT_APP_IN_OIDC_DEV_REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        useInteractionCode: false,
      },
      qa: {
        clientId: process.env.REACT_APP_IN_OIDC_QA_CLIENT_ID,
        issuer: process.env.REACT_APP_IN_OIDC_ISSUER,
        redirectUri: process.env.REACT_APP_IN_OIDC_QA_REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        useInteractionCode: false,
      },
      prod: {
        clientId: process.env.REACT_APP_IN_OIDC_PROD_CLIENT_ID,
        issuer: process.env.REACT_APP_IN_OIDC_ISSUER,
        redirectUri: process.env.REACT_APP_IN_OIDC_PROD_REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        useInteractionCode: false,
      },
    },
    functionalityFlags: {
      showChinese: false,
    },
  },
};
