export const ListArraySubheader = [
  {
    title: 'Course Description',
    className: 'home_icon',
    subHeaderClick: true,
  },
  {
    title: 'Categories, Skills, Tags',
    className: 'tag_icon',
    subHeaderClick: false,
  },
];
export const regexNameChinese =
  /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z.。?？:：!！\"”“'‘’ -]+/g;
