import React, {useEffect, useState, useRef} from 'react';
import styles from './CourseAddition.module.scss';
import filter from 'lodash/filter';
import Header from '../../shared/header';
import TopBarComponenent from '../../audienceManagement/TopBar';
import {dateIsoToNormalDate, dateIsoToDate, dateIsoToTime} from '../../utils/commonUtil';
import {ReactComponent as ImageNew} from '../../../styles/images/sample_img6.svg';
import CheckBoxBlue from '../../shared/checkboxBlue';
import EmptyScreenComponent from '../../shared/emptyScreen';
import RadioButtonsComponent from '../../shared/radioButtons';
import {makeRequest} from '../../utils/APIsUtils/httpsUtils';
import findIndex from 'lodash/findIndex';
import LoadingShimmerList from '../../shared/LoadingShimmerList';
import LoadingShimmer from '../../shared/LoadingShimmer';
const CreateCoursesAddition = ({
  setErrorStateTemplate,
  setAddedCourseList,
  addedCourseListNew,
  setNotificationData,
  categories,
  data,
  subheader,
}) => {
  const [coursesAPIResponse, setCoursesAPIResponse] = useState();
  const [isCardView, setIsCardView] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isListView, setIsListView] = useState(false);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [coursesUIData, setCoursesUiData] = useState([]);
  const [isOnline, setOnline] = useState(true);
  const [totalNumberRemovedCourses, setTotalNumberRemovedCourses] = useState([]);
  const [totalNumberAddedCourses, setTotalNumberAddedCourses] = useState([]);
  const [clikedData, setClikedData] = useState([]);
  const [clikedDataCopy, setClikedDataCopy] = useState([]);
  const [coursesUIDataCopy, setCoursesUiDataCopy] = useState([]);
  const [showAddedCourses, setShowAddedCourses] = useState(true);
  const [search, setSearch] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [allCourseSearch, setAllCourseSearch] = useState([]);
  const [addedCourseSearch, setAddedCourseSearch] = useState([]);
  const [predictedSearch, setPredictedSearch] = useState('');
  const [filterAllCoursesData, setFilterAllCoursesData] = useState([]);
  const [filterDropdowncopy, setFilterDropdownopy] = useState(false);
  const [courseListDefaultFilter, setCourseListDefaultFilter] = useState([]);
  const [learningPathState, setLearningPathState] = useState('');
  const [isChecked, setChecked] = useState(true);
  const _initialFilterData = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Draft',
        className: 'draft',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Live',
        className: 'goLive',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Offline',
        className: 'goOffline',
        filterParameter: 'courseState',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launchDate',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: [],
        },
      },
    ],
  };
  const [filterData, setFilterData] = useState(_initialFilterData);
  const [filterDataTemp, setFilterDataTemp] = useState(new Object(_initialFilterData));
  const [courseListCopy, setCourseListCopy] = useState([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const getCoursesUIData = (course) => {
    const {courseId, course_state, description, launch_date, thumbnail, title} = course;
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let dateToShowForListView = ' ';
    let dateToShowForCardView = ' ';
    let timeToShow = ' ';
    if (launch_date) {
      const date = new Date(launch_date);
      const hours = date.getHours();
      const mins = date.getMinutes();
      timeToShow = (hours > 12 ? hours - 12 : hours) + ':' + mins + (hours > 12 ? ' pm' : ' am');
      dateToShowForListView =
        months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
      dateToShowForCardView =
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    } else {
      dateToShowForListView = ' ';
      dateToShowForCardView = ' ';
    }

    let className = '';
    switch (course_state) {
      case 'Live':
        className = 'live';
        break;
      case 'Offline':
        className = 'offline';
        break;
      case 'Draft':
        className = 'draft';
        break;
      case 'Archive':
        className = 'archive';
        break;
    }
    let checked = false;

    return {
      courseId,
      course_state,
      description,
      thumbnail,
      title,
      timeToShow,
      dateToShowForListView,
      dateToShowForCardView,
      className,
      launch_date,
      checked,
    };
  };
  const fetchCoursesList = async () => {
    setIsLoading(true);
    const dataCourses = await makeRequest({
      method: 'POST',
      endpoint: '/admin/api/v1/courses',
    });
    if (dataCourses != 'ERR_NETWORK' && dataCourses) {
      let courseListSorted = [];
      dataCourses?.data?.forEach((course) => {
        if (course.courseState != 'Archive' && data.state == 'Draft') {
          courseListSorted.push(course);
        } else if (course.courseState == 'Archive' && data.state != 'Draft') {
          // courseListSorted.push(course);
        } else if (course.courseState != 'Archive' && data.state != 'Draft') {
          courseListSorted.push(course);
        }
      });
      setCoursesAPIResponse(courseListSorted);
      //setCourseListDefaultFilter(dataCourses?.data);
      setSearchFilterEmptyState(true);
      setCourseListDefaultFilter(courseListSorted);

      setFilterAllCoursesData(courseListSorted);
      setSearchList(courseListSorted);
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
      setIsLoading(false);
    } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    fetchCoursesList();
    setOnline(navigator.onLine);
    if (addedCourseListNew) {
      setClikedData(addedCourseListNew);
      setClikedDataCopy(addedCourseListNew);
      setTotalNumberAddedCourses(addedCourseListNew);
    }
    if (data && data.state) {
      setLearningPathState(data?.state);
    }
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  useEffect(() => {
    if (categories) {
      let _filterData = filterData;
      let _filtersList = _filterData.filtersList;
      let _tempFiltersList = [];
      let index = findIndex(_filtersList, ['filterType', 'CHECKBOX']);
      if (index !== -1) {
        categories?.length &&
          categories?.length > 0 &&
          categories?.map((dataItem, _index) => {
            const {id = '', title = ''} = dataItem;
            _tempFiltersList.push({
              index: _index,
              id: id,
              label: title,
              title: title,
              filterParameter: 'category',
              checked: false,
              typeOfFilter: 'CHECKBOX',
            });
          });
        _filtersList[index].filterTypeData.sectionData = _tempFiltersList;
      }
      setFilterData(_filterData);
    }
  }, [categories]);

  useEffect(() => {
    if (width >= 320 && width < 768) {
      setIsCardView(false);
      setIsListView(true);
    }
  }, [width]);
  // const sortDataList = (listArray) => {
  //   let tempArr = [];

  //   console.log(listArray, coursesUIData, addedCourseListNew);
  //   coursesUIData.forEach((ele)=>{
  //     if(ele.courseId == listArray.courseId)
  //     tempArr.push(listArray)
  //   })
  //   setAddedCourseList()
  // };

  const handleCourseAddRemove = (e) => {
    //For removing course
    // setAaa(e?.currentTarget?.id);
    // setBbb(e?.currentTarget?.id);
    if (clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)) {
      setClikedData(
        clikedData?.filter((item) => {
          //For Count of Removed courses
          if (
            coursesUIDataCopy?.some((ele) => ele?.courseId === e.currentTarget.id) &&
            clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
          ) {
            setTotalNumberRemovedCourses(
              totalNumberRemovedCourses?.filter((item) => {
                return item?.courseId !== e.currentTarget.id;
              }),
            );
          }
          if (
            totalNumberAddedCourses?.some((ele) => ele?.courseId === e.currentTarget.id) &&
            clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
          ) {
            setTotalNumberAddedCourses(
              totalNumberAddedCourses?.filter((item) => {
                return item?.courseId !== e.currentTarget.id;
              }),
            );
            setAddedCourseList(
              totalNumberAddedCourses?.filter((item) => {
                return item?.courseId !== e.currentTarget.id;
              }),
            );
          }
          return item?.courseId !== e.currentTarget.id;
        }),
      );
      setClikedDataCopy(
        clikedDataCopy?.filter((item) => {
          //For Count of Removed courses
          if (
            coursesUIDataCopy?.some((ele) => ele?.courseId === e.currentTarget.id) &&
            clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
          ) {
            setTotalNumberRemovedCourses(
              totalNumberRemovedCourses?.filter((item) => {
                return item?.courseId !== e.currentTarget.id;
              }),
            );
          }
          if (
            totalNumberAddedCourses?.some((ele) => ele?.courseId === e.currentTarget.id) &&
            clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
          ) {
            setTotalNumberAddedCourses(
              totalNumberAddedCourses?.filter((item) => {
                return item?.courseId !== e.currentTarget.id;
              }),
            );
            setAddedCourseList(
              totalNumberAddedCourses?.filter((item) => {
                return item?.courseId !== e.currentTarget.id;
              }),
            );
          }
          return item?.courseId !== e.currentTarget.id;
        }),
      );
    }
    //For Adding Courses
    else if (
      !clikedData?.some((ele) => ele?.courseId === e.currentTarget.id) &&
      clikedData?.length < 20
    ) {
      //For Count of Removed courses
      if (
        coursesUIDataCopy?.some((ele) => ele?.courseId === e.currentTarget.id) &&
        !clikedData?.some((ele) => ele?.courseId === e.currentTarget.id)
      ) {
        let b = coursesUIDataCopy?.filter((item) => {
          return item?.courseId === e.currentTarget.id;
        });
        setTotalNumberRemovedCourses((prev) => [...prev, b[0]]);
        setAddedCourseList((prev) => [...prev, b[0]]);
      }

      if (
        !coursesUIDataCopy?.some((ele) => ele?.courseId === e.currentTarget.id) &&
        !totalNumberAddedCourses?.some((ele) => ele?.courseId === e.currentTarget.id)
      ) {
        let c = coursesAPIResponse?.filter((item) => {
          return item?.courseId === e.currentTarget.id;
        });
        setTotalNumberAddedCourses((prev) => [...prev, c[0]]);
        setAddedCourseList((prev) => [...prev, c[0]]);
      }

      let a = coursesAPIResponse?.filter((item) => {
        return item?.courseId === e.currentTarget.id;
      });
      setClikedData((prev) => [...prev, a[0]]);
      setClikedDataCopy((prev) => [...prev, a[0]]);
    } else if (
      !clikedData?.some((ele) => ele?.courseId === e.currentTarget.id) &&
      clikedData?.length == 20
    ) {
      setNotificationData({
        show: true,
        type: 'FAILURE',
        title: 'Exceeding maximum limit of courses',
        description: `You can only add a maximum of 20 courses in a Learning Path.`,
      });
    }
  };
  useEffect(() => {
    if (coursesAPIResponse && coursesAPIResponse.length && coursesAPIResponse.length > 0) {
      let _coursesUIData = [];
      coursesAPIResponse.map((course) => {
        if (course.courseState !== 'Archive' && data.state == 'Draft') {
          _coursesUIData.push(getCoursesUIData(course));
        } else if (
          data.courseState == 'Archive' &&
          (data.state == 'Live' || data.state == 'Offline')
        ) {
          //_coursesUIData.push(getCoursesUIData(course));
        } else if (data.courseState != 'Archive' && data.state != 'Draft') {
          _coursesUIData.push(getCoursesUIData(course));
        }
      });
      setCoursesUiData(_coursesUIData);
      setCoursesUiDataCopy(_coursesUIData);
    }
    setClikedData(
      coursesAPIResponse?.filter((el) => {
        return addedCourseListNew?.some((element) => {
          return (
            element.courseId === el.courseId &&
            el.courseState !== 'Archive' &&
            element.courseState !== 'Archive'
          );
        });
      }),
    );
    setClikedDataCopy(
      coursesAPIResponse?.filter((el) => {
        return addedCourseListNew?.some((element) => {
          return (
            element.courseId === el.courseId &&
            el.courseState !== 'Archive' &&
            element.courseState !== 'Archive'
          );
        });
      }),
    );
    // setClikedData(addedCourseListNew);
    // setClikedDataCopy(addedCourseListNew);
    setTotalNumberAddedCourses(
      coursesAPIResponse?.filter((el) => {
        return addedCourseListNew?.some((element) => {
          return (
            element.courseId === el.courseId &&
            el.courseState !== 'Archive' &&
            element.courseState !== 'Archive'
          );
        });
      }),
    );
  }, [coursesAPIResponse, addedCourseListNew]);

  useEffect(() => {
    if (showAddedCourses) {
      // All Courses
      if (search?.length > 0) {
        setCoursesUiData(allCourseSearch);

        setSearchList(allCourseSearch);
      } else {
        if (filterAllCoursesData.length > 0) {
          setSearchList(filterAllCoursesData);
          setCoursesUiData(filterAllCoursesData);
        } else {
          setSearchList(coursesAPIResponse);
          setCoursesUiData(coursesAPIResponse);
        }
      }
    } else if (!showAddedCourses) {
      // Added courses Only
      if (search?.length > 0) {
        setSearchList(filterAllCoursesData);
        setCoursesUiData(filterAllCoursesData);
      } else {
        setSearchList(clikedData);
        setCoursesUiData(clikedData);
      }
    }
  }, [showAddedCourses, clikedData, search]);

  useEffect(() => {
    if (search && search.length && search.length >= 1) {
      let allCourseResult;

      allCourseResult = filterAllCoursesData.filter((user) =>
        user.title.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      allCourseResult?.sort(function (one, other) {
        return one?.title?.length - other?.title?.length;
      });
      //for checking the serach char index in audi group name
      allCourseResult?.sort(function (one, other) {
        return (
          one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.title?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });
      let addedCourseResult;
      addedCourseResult = clikedData?.filter((user) =>
        user.title.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      addedCourseResult?.sort(function (one, other) {
        return one?.title?.length - other?.title?.length;
      });
      //for checking the serach char index in audi group name
      addedCourseResult?.sort(function (one, other) {
        return (
          one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.title?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });

      setAddedCourseSearch(addedCourseResult);
      setAllCourseSearch(allCourseResult);
      showAddedCourses ? setCoursesUiData(allCourseResult) : setCoursesUiData(addedCourseResult);
    }
  }, [search, showAddedCourses, clikedData]);
  useEffect(() => {
    if (clikedData?.length === 0) {
      setCoursesUiData(coursesUIDataCopy);
      setShowAddedCourses(true);
    }
  }, [clikedData]);

  const handleToggleSwitch = () => {
    setShowAddedCourses(!showAddedCourses);
    // if (width <= 767 && showAddedCourses) {
    //   setNotificationData({
    //     show: true,
    //     type: 'UPLOADING',
    //     title: 'Showing Only Added Courses',
    //     singleLine: true,
    //   });
    // }
  };
  const handleSearchInput = (event) => {
    setSearch(event?.target?.value);
    setPredictedSearch('');

    let tempPredition = coursesUIData?.map((item) => ({
      id:
        item?.courseId && item?.courseId !== null && item.courseId !== undefined
          ? item?.courseId
          : '',
      label: item?.title && item?.title !== null && item.title !== undefined ? item?.title : '',
    }));
    if (tempPredition.length >= 0 && event?.target?.value?.length >= 0) {
      setPredictedSearch(tempPredition ? tempPredition : {});
    } else {
      setPredictedSearch([]);
    }
  };
  const handleFilterApplyNew = (params) => {
    let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
    const categoryId = [];
    let categoriesArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');
    for (const ele of categoriesArray) {
      ele.checked && categoryId.push(ele.id);
    }
    let bodyVar = {
      categoryId,
    };
    let sortBy = '';
    let sortByName = 'Recent Activity';
    let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');
    radioFilterArray.forEach((ele) => {
      switch (ele?.name) {
        case 'Recent Activity':
          (sortBy = 'Recent_Activity'), (sortByName = 'Recent Activity');
          break;
        case 'A - Z':
          (sortBy = 'A_Z'), (sortByName = 'A - Z');
          break;
        case 'Z - A':
          (sortBy = 'Z_A'), (sortByName = 'Z - A');
          break;
        case 'Newest To Oldest':
          (sortBy = 'Newest_To_Oldest'), (sortByName = 'Newest To Oldest');
          break;
        case 'Oldest To Newest':
          (sortBy = 'Oldest_To_Newest'), (sortByName = 'Oldest To Newest');
          break;
        default:
          sortBy = 'Recent_Activity';
          break;
      }
    });
    let categoriesData = filterData.filtersList[1].filterTypeData.sectionData;
    let sortByData = filterData.filtersList[0].filterTypeData.sectionData;
    sortByData.forEach((data) => {
      if (data?.name == sortByName) {
        data.checked = true;
      } else {
        data.checked = false;
      }
    });
    const _filterData = {
      tiles: tilesFilterArray,
      filtersList: [
        {
          filterType: 'RADIO',
          filterTypeData: {
            sectionTitle: 'Sort By',
            sectionData: sortByData,
          },
        },
        {
          filterType: 'CHECKBOX',
          filterTypeData: {
            sectionTitle: 'Categories',
            sectionData: categoriesData,
          },
        },
      ],
    };
    setFilterData(_filterData);
    setFilterDataTemp(new Object(_filterData));
    fetchCoursesListWithParams(bodyVar, sortBy, tilesFilterArray);
  };

  const fetchCoursesListWithParams = async (body, sortBy, tilesFilterArray) => {
    setIsLoading(true);
    let endpoint = `/admin/api/v1/courses`;

    if (sortBy) {
      endpoint += `?sortBy=${sortBy}`;
    }
    // if (courseState){
    //   endpoint  += `&courseState=${courseState}`
    // }
    const dataCourses = await makeRequest({
      method: 'POST',
      endpoint,
      body,
    });
    if (dataCourses != 'ERR_NETWORK' && dataCourses) {
      let newDataCourses = [];
      dataCourses?.data.forEach((ele) => {
        if (ele.courseState != 'Archive' && data.state == 'Draft') {
          newDataCourses.push(ele);
        } else if (
          ele.courseState == 'Archive' &&
          (data.state == 'Live' || data.state == 'Offline')
        ) {
          newDataCourses.push(ele);
        } else if (ele.courseState != 'Archive' && data.state != 'Draft') {
          newDataCourses.push(ele);
        }
      });
      let tilesLables = [];
      if (tilesFilterArray !== []) {
        for (const ele of tilesFilterArray) {
          if (ele?.checked) {
            tilesLables.push(ele?.title);
          }
        }
        if (tilesLables?.length > 0) {
          newDataCourses = dataCourses?.data.filter((ele) => {
            return tilesLables.includes(ele?.course_state);
          });
        }
      }
      setCoursesAPIResponse(newDataCourses);
      setCourseListDefaultFilter(newDataCourses);
      setSearchFilterEmptyState(true);
      setFilterAllCoursesData(newDataCourses);
      setSearchList(newDataCourses);
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
      if (search && search.length && search.length >= 3) {
        setCoursesUiData(
          filter(newDataCourses, (c) => {
            return (
              c &&
              c.title &&
              c.title.toLowerCase &&
              c.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          }),
        );
        setCoursesUiDataCopy(
          filter(newDataCourses, (c) => {
            return (
              c &&
              c.title &&
              c.title.toLowerCase &&
              c.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          }),
        );
      } else {
        let _coursesUIData = [];
        newDataCourses.map((course) => {
          _coursesUIData.push(getCoursesUIData(course));
        });
        setCoursesUiData(_coursesUIData);
        setCoursesUiDataCopy(_coursesUIData);
      }
      setIsLoading(false);
    } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
      setIsLoading(false);
    }
  };

  const handleCheckClick = () => {
    setChecked(!isChecked);
  };

  return (
    <React.Fragment>
      {/* <div className={[styles.learning_path_main_wrapper, ''].join(' ')}> */}
      <div className={[styles.course_mgt_wrapper, ''].join(' ')}>
        {/* <div className={[styles.audience_wrapper, styles.is_drawer_open].join(" ")}> */}
        <div className={[styles.audience_wrapper, styles.course_inner_wrapper, ''].join(' ')}>
          {/* <SeverErrorComponent /> */}
          <div className={[styles.main_wrapper, ''].join(' ')}>
            {/* Course Addition Title & Description Starts */}
            <div className={styles.course_description_wrapper}>
              <div className={styles.course_title}>Course Addition</div>
              <div className={styles.course_description}>
                To create a Learning Path, it is mandatory to have a minimum of 2 courses. Add
                courses by searching for a particular course or filter them using the Sort by Filter
                option.
                <div className={styles.bold_text}>
                  Note: Once the Learning Path is published, you cannot add or remove courses from a
                  Learning Path. You can only edit the sequence of the courses in the Learning Path.
                </div>
              </div>
            </div>
            {/* Course Addition Title & Description Ends */}

            <TopBarComponenent
              searchPlaceholder="Search Course"
              filterPillAlign="center"
              subheader={subheader}
              showRoundToggler={true}
              showGridListSwitch={true}
              setIsCardView={(data) => setIsCardView(data)}
              setIsListView={(data) => setIsListView(data)}
              isCardView={isCardView}
              isListView={isListView}
              handleToggleSwitch={handleToggleSwitch}
              clikedData={clikedData}
              coursesUIDataCopy={coursesUIDataCopy}
              placeholderName={'Search Course Name'}
              showAddedCourses={showAddedCourses}
              setShowAddedCourses={setShowAddedCourses}
              list={searchList}
              searchListFunction={(e) => {
                if (e != '') {
                  setSearch(e.target.value);
                } else if (e == '') {
                  setSearch('');
                }
              }}
              setPredictedSearch={setPredictedSearch}
              predictedSearch={predictedSearch}
              handleSearchInput={handleSearchInput}
              search={search}
              filterData={filterData}
              handleFilterApply={handleFilterApplyNew}
              isOpenSubCategories={false}
              setFilterDropdownopy={setFilterDropdownopy}
              searchBarFrom={'LEARNING-PATH-COURSE-ADDITION'}
            />
            {/* Notification bar */}
            {/* <div className={}></div> */}
            {/* END of notification bar */}
            <div className={styles.inner_wrapper}>
              {/* Card View Started  */}
              {isCardView ? (
                <div
                  className={[
                    styles.card_wrapper,
                    isSearchFilterEmptyState ? styles.justify_center : '',
                    'bootstrap-wrapper',
                  ].join(' ')}
                >
                  {isLoading ? (
                    <div className={styles.row_wrapper}>
                      <LoadingShimmer count={10} />
                    </div>
                  ) : (
                    <div className={styles.row_wrapper}>
                      {coursesUIData && coursesUIData.length > 0 ? (
                        coursesUIData.map((course, index) => {
                          const {
                            courseId,
                            course_state,
                            description,
                            timeToShow,
                            dateToShowForCardView,
                            thumbnail,
                            title,
                            className,
                            checked,
                          } = course;
                          return (
                            <div
                              key={index}
                              className={[styles.card_holder, ''].join(' ')}
                              id={courseId}
                              onClick={(e) => {
                                if (learningPathState != 'Live' && learningPathState != 'Offline') {
                                  handleCourseAddRemove(e);
                                } else {
                                  setNotificationData({
                                    show: true,
                                    type: 'FAILURE',
                                    title: 'Course Addition disabled',
                                    description:
                                      'You cannot add or remove courses from a Learning Path once its published. ',
                                  });
                                }
                              }}
                            >
                              {/* Selected class should be coditionally added for darker shadow to the card */}
                              <div className={[styles.card, styles.selected].join(' ')}>
                                <div
                                  id={courseId}
                                  className={`${styles.uncheck_card_wrapper}`}
                                  // onClick={(e) => {
                                  //   handleCourseAddRemove(e);
                                  // }}
                                >
                                  <div
                                    className={`${
                                      learningPathState === 'Live' ||
                                      learningPathState === 'Offline'
                                        ? styles.unchecked_box_disable
                                        : styles.unchecked_box
                                    }`}
                                  ></div>
                                </div>
                                {clikedData?.some((e) => e?.courseId === courseId) ? (
                                  <div
                                    id={courseId}
                                    className={`${
                                      learningPathState === 'Live' ||
                                      learningPathState === 'Offline'
                                        ? styles.checked_card_wrapper_disable
                                        : styles.checked_card_wrapper
                                    }`}
                                    // onClick={(e) => {
                                    //   handleCourseAddRemove(e);
                                    // }}
                                  >
                                    <div className={`${styles.checked_box}`}></div>
                                    <div className={`${styles.text}`}>Added</div>
                                  </div>
                                ) : null}
                                <div className={styles.card_top}>
                                  <div className={styles.checkbox_container}>
                                    <div className={styles.txt}></div>
                                  </div>
                                  <div
                                    className={[styles.img_wrapper, styles.empty_state].join(' ')}
                                  >
                                    {thumbnail ? (
                                      <img className={styles.img} src={thumbnail} />
                                    ) : (
                                      <div
                                        className={[styles.img, styles.empty_state_img].join(' ')}
                                      ></div>
                                    )}
                                  </div>
                                </div>
                                <div className={styles.card_bottom}>
                                  <div
                                    className={[styles.status_wrapper, styles.archive_cd].join(' ')}
                                  >
                                    <div
                                      className={`${styles.status} ${
                                        styles[course?.course_state?.toLowerCase()]
                                      }`}
                                    >
                                      <div className={styles.status_txt}>
                                        {course_state || 'NA'}
                                      </div>
                                    </div>
                                    {course_state !== 'Draft' && (
                                      <div className={styles.text_wrapper}>
                                        {/* Please add other different calendar icon for different status */}
                                        <div
                                          className={
                                            course_state === 'Live'
                                              ? styles.calendar_icon
                                              : course_state === 'Offline'
                                              ? styles.offline_cal_icon
                                              : styles.archive_cal_icon
                                          }
                                        ></div>
                                        <div className={styles.date_text}>
                                          {course?.launch_date
                                            ? dateIsoToNormalDate(course?.launch_date)
                                            : 'NA'}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className={styles.description}>{title || 'NA'}</div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : isSearchFilterEmptyState ? (
                        <EmptyScreenComponent />
                      ) : null}
                    </div>
                  )}
                </div>
              ) : (
                <div className={[styles.table_main_wrapper, styles.table_reduced_width].join(' ')}>
                  <div className={styles.table_container}>
                    <div className={styles.tbl_header}>
                      <div className={styles.tbl_head}>Thumbnail</div>
                      <div className={styles.tbl_head_wrapper}>
                        <div className={styles.tbl_head}>Course Name</div>
                        <div className={styles.tbl_head}>Upload date</div>
                        {/* <div className={styles.tbl_head}>
                          <div className={styles.active_user_icon}></div>
                          <span className={styles.user_txt}>Users</span>
                        </div> */}
                        <div className={styles.tbl_head}>Status</div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div className={styles.tbl_body}>
                        <LoadingShimmerList count={20} />
                      </div>
                    ) : (
                      <div className={styles.tbl_body}>
                        <div className={[styles.tbl_row_wrapper].join(' ')}>
                          {/* Please add selected state/class(selected) for row here */}
                          {coursesUIData && coursesUIData.length > 0
                            ? coursesUIData.map((course, index) => {
                                const {
                                  courseId,
                                  course_state,
                                  description,
                                  timeToShow,
                                  dateToShowForCardView,
                                  thumbnail,
                                  title,
                                  className,
                                  checked,
                                } = course;
                                return (
                                  <div
                                    className={[
                                      styles.row_outer_wrapper,
                                      clikedData?.some((e) => e?.courseId === courseId)
                                        ? styles.selected
                                        : '',
                                    ].join(' ')}
                                    key={index}
                                  >
                                    <div
                                      className={[
                                        styles.tbl_row,
                                        // styles.selected,
                                      ].join(' ')}
                                      id={courseId}
                                      onClick={(e) => {
                                        if (
                                          learningPathState != 'Live' &&
                                          learningPathState != 'Offline'
                                        ) {
                                          handleCourseAddRemove(e);
                                        } else {
                                          setNotificationData({
                                            show: true,
                                            type: 'FAILURE',
                                            title: 'Course Addition disabled',
                                            description:
                                              'You cannot add or remove courses from a Learning Path once its published. ',
                                          });
                                        }
                                      }}
                                    >
                                      <div
                                        className={[
                                          styles.checkbox_wrapper,
                                          clikedData?.some((e) => e?.courseId === courseId)
                                            ? styles.active
                                            : '',
                                        ].join(' ')}
                                      >
                                        <div className={`${styles.check_box}`}></div>
                                      </div>

                                      <div className={styles.tbl_data}>
                                        <div className={styles.inner_data}>
                                          {thumbnail ? (
                                            <img className={styles.img} src={thumbnail} />
                                          ) : (
                                            <div
                                              className={[
                                                // styles.img,
                                                styles.empty_state_img,
                                              ].join(' ')}
                                            ></div>
                                          )}
                                          {/* <img
                                      className={styles.img}
                                      src={thumbnail}
                                      alt="image"
                                    />

                                  
                                    <div
                                      className={[styles.empty_state_img].join(" ")}
                                    ></div> */}
                                        </div>
                                      </div>
                                      <div className={styles.tbl_data_wrapper}>
                                        <div className={styles.tbl_data}>
                                          <CheckBoxBlue
                                            isChecked={clikedDataCopy?.some(
                                              (e) => e?.courseId === courseId,
                                            )}
                                            handleCheckClick={handleCheckClick}
                                          />
                                        </div>
                                        <div className={styles.tbl_data}>
                                          <div className={styles.inner_data}>
                                            <div className={[styles.txt, ''].join(' ')}>
                                              {title || 'NA'}
                                            </div>
                                          </div>
                                        </div>

                                        <div className={styles.tbl_data}>
                                          <div className={styles.inner_data}>
                                            <div className={[styles.txt].join(' ')}>
                                              <div>
                                                {' '}
                                                {course?.launch_date || course?.createdDate
                                                  ? dateIsoToTime(
                                                      course?.launch_date || course?.createdDate,
                                                    )
                                                  : 'NA'}
                                              </div>
                                              <div>
                                                {course?.launch_date || course?.createdDate
                                                  ? dateIsoToDate(
                                                      course?.launch_date || course?.createdDate,
                                                    )
                                                  : 'NA'}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={styles.tbl_data}>
                                          <div className={styles.inner_data}>
                                            <div
                                              className={`${styles.status} ${
                                                styles[course?.course_state?.toLowerCase()]
                                              }`}
                                            >
                                              <div className={styles.status_txt}>
                                                {course_state || 'NA'}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

CreateCoursesAddition.propTypes = {};

export default CreateCoursesAddition;
