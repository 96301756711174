import React, {useEffect, useState} from 'react';
import styles from './CheckboxNew.module.scss';

const CheckboxNew = ({isChecked = false, handleCheckClick, isDisabled = false, label = ''}) => {
  const [check, setCheck] = useState(isChecked);

  const handleChange = (value) => {
    setCheck(value);
    handleCheckClick?.(isChecked);
  };

  useEffect(() => {
    setCheck(isChecked);
  }, [isChecked]);

  return isDisabled === true && check ? (
    <div className={styles.outerDiv}>
      <div className={styles.disabledTrueCheckbox}></div> <div>{label}</div>
    </div>
  ) : isDisabled === true ? (
    <div className={styles.outerDiv}>
      <div className={styles.disabledCheckbox}></div> <div>{label}</div>
    </div>
  ) : check ? (
    <div className={styles.outerDiv} onClick={() => handleChange(false)}>
      <div className={styles.selectedCheckbox}></div>
      <div>{label}</div>
    </div>
  ) : (
    <div className={styles.outerDiv} onClick={() => handleChange(true)}>
      <div className={styles.unselectedCheckbox}></div>
      <div>{label}</div>
    </div>
  );
};

export default CheckboxNew;
