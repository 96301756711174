import {makeRequest} from '../../components/utils/APIsUtils/httpsUtils';

/* Export action types below this. */
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';
export const SET_DASHBOARD_NOTIFICATION_SWITCH = 'SET_DASHBOARD_NOTIFICATION_SWITCH';
export const SET_DASHBOARD_NOTIFICATION_COUNT = 'SET_DASHBOARD_NOTIFICATION_COUNT';
export const SET_COURSE_MODE = 'SET_COURSE_MODE';
export const SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST';
export const SET_SELECTED_NAVIGATION_OPTION = 'SET_SELECTED_NAVIGATION_OPTION';
export const SET_CREATE_USER_DATA = 'SET_CREATE_USER_DATA';
export const SET_IS_USER_LOGGED_IN = 'SET_IS_USER_LOGGED_IN';
export const ERROR_STATE = 'ERROR_STATE';
export const NOTIFICATION_ERROR_STATE = 'NOTIFICATION_ERROR_STATE';
export const SET_LEARNIG_PATH_WIAZRD = 'SET_LEARNIG_PATH_WIAZRD';

/* Export action types above this. */

/* export action creators here */
export const setNotificationData = (options) => {
  return {
    type: SET_NOTIFICATION_DATA,
    payload: options,
  };
};
export const setDashboardNotificationSwitch = (options) => {
  return {
    type: SET_DASHBOARD_NOTIFICATION_SWITCH,
    payload: options,
  };
};
export const setDashboardNotificationCount = (options) => {
  return {
    type: SET_DASHBOARD_NOTIFICATION_COUNT,
    payload: options,
  };
};

export const setNotificationList = (options) => {
  return {
    type: SET_NOTIFICATION_LIST,
    payload: options,
  };
};

export const setDashboardNotificationCountApi = () => {
  const currentUserId = window.localStorage.getItem('am_id');
  return async (dispatch) => {
    const dataNotificationCount = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/users/${currentUserId}/notifications/count`,
    });
    if (dataNotificationCount?.data) {
      dispatch({
        type: SET_DASHBOARD_NOTIFICATION_COUNT,
        payload: dataNotificationCount?.data?.count,
      });
    }
  };
};

export const setNotificationListApi = () => {
  const currentUserId = window.localStorage.getItem('am_id');
  return async (dispatch) => {
    const dataNotifications = await makeRequest({
      method: 'GET',
      endpoint: `/admin/api/v1/users/${currentUserId}/notifications`,
    });
    if (dataNotifications?.data) {
      dispatch({
        type: SET_NOTIFICATION_LIST,
        payload: dataNotifications?.data,
      });
    }
  };
};

export const setCourseMode = (options) => {
  return {
    type: SET_COURSE_MODE,
    payload: options,
  };
};
export const setLearningPath = (options) => {
  return {
    type: SET_LEARNIG_PATH_WIAZRD,
    payload: options,
  };
};

export const setSelectedNavigationOption = (options) => {
  return {
    type: SET_SELECTED_NAVIGATION_OPTION,
    payload: options,
  };
};

export const setCurrentUserId = (options) => {
  return {
    type: SET_CREATE_USER_DATA,
    payload: options,
  };
};

export const setIsUserLoggedIn = (options) => {
  return {
    type: SET_IS_USER_LOGGED_IN,
    payload: options,
  };
};
export const setErrorStateTemplate = (options) => {
  return {
    type: ERROR_STATE,
    payload: options,
  };
};
export const setNotificationErrorState = (options) => {
  return {
    type: NOTIFICATION_ERROR_STATE,
    payload: options,
  };
};
