import React from 'react';
import styles from '../Portal.module.scss';
import ViewAllComponent from '../ViewAllComponent';
const AudienceSectionView = (props) => {
  const {
    isLoading,
    audience,
    searchUserGroup,
    predictedSearchUGM,
    Hint,
    handleSearchInputUGM,
    setSearchUserGroup,
    setPredictedSearchUGM,
    audienceGroupList,
    setNotificationData,
    setSearch,
    setCourses,
    courses,
    setLearning,
    setAudience,
    learning,
    handleSearch,
    filterDataUGM,
    setFilterDataUGM,
    setFilterDataTempUGM,
    makeRequest,
    setSearchFilterEmptyState,
    setAudienceGroupListCopy,
    setAudienceGroupList,
    setErrorStateTemplate,
    isOnline,
    sortAndFilterFlyoutUGM,
    filterCourses,
    filterDropdownUGM,
    setFilterDropdown,
    setFilterDropdownLP,
    setFilterDropdownUGM,
    handleFilterApplyUGM,
    FilterComponent,
    sortData,
    isSearchFilterEmptyState,
    TableListComponent,
    EmptyScreenComponent,
    setSelectedNavigationOption,
    router,
    redirectUserGroup,
    showEmptyTemplateFor,
    componentUGM,
    setIsLoading,
    height,
  } = props;

  return (
    <React.Fragment>
      <div
        style={{height: (30 / 100) * (height - 95) + 'px'}}
        className={[styles.card_wrapper, styles.audience_section].join(' ')}
      >
        <div className={styles.header_wrapper}>
          <div className={styles.txt}>User Group Management</div>
          <div className={styles.action_wrapper}>
            {/* Added class "outline" for search when user type border should highlighted */}
            {audience ? (
              <div
                className={[
                  styles.search_wrapper,
                  searchUserGroup.length > 0 ? styles.outline : null,
                ].join(' ')}
              >
                <Hint options={predictedSearchUGM} allowTabFill={true}>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchUserGroup}
                    onChange={(event) => {
                      handleSearchInputUGM({
                        event,
                        setSearchUserGroup,
                        setPredictedSearchUGM,
                        audienceGroupList,
                      });
                    }}
                    onKeyPress={(event) =>
                      handleSearchInputUGM({
                        event,
                        setSearchUserGroup,
                        setPredictedSearchUGM,
                        audienceGroupList,
                      })
                    }
                    className={styles.search_input}
                    onKeyDown={(e) => {
                      if (
                        e.keyCode == 13 &&
                        searchUserGroup?.length > 0 &&
                        searchUserGroup?.length < 3
                      ) {
                        setNotificationData({
                          show: false,
                          type: 'WARNING',
                          title: 'Insufficient Search Input',
                          description: 'To search for any User group, enter 3 or more characters.',
                        });
                      }
                    }}
                  />
                </Hint>
                <div className={styles.icon_wrapper}>
                  {/* here we added cross icon in search and it should be conditional once user                                                                                                                                                                                       start typing */}
                  <div
                    className={[
                      styles.search,
                      searchUserGroup.length > 0 ? styles.cross_icon : null,
                    ].join(' ')}
                    onClick={() => {
                      handleSearch({
                        value: 'audience',
                        setSearch,
                        setCourses,
                        courses,
                        setLearning,
                        setAudience,
                        learning,
                        audience,
                      });
                      setSearchUserGroup('');
                      setPredictedSearchUGM('');
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.icon_wrapper}>
                <div
                  className={styles.search_white}
                  onClick={() =>
                    handleSearch({
                      value: 'audience',
                      setSearch,
                      setCourses,
                      courses,
                      setLearning,
                      setAudience,
                      learning,
                      audience,
                    })
                  }
                />
              </div>
            )}
            <div
              className={styles.filter_main_wrapper}
              ref={sortAndFilterFlyoutUGM}
              id="divParentOfFilterUGM"
            >
              <div
                className={[styles.icon_wrapper, filterCourses ? styles.selected : ''].join(' ')}
              >
                <div
                  className={styles.filter}
                  onClick={(e) => {
                    setFilterDropdownUGM(!filterDropdownUGM);
                    setFilterDropdown(false);
                    setFilterDropdownLP(false);
                  }}
                />
              </div>

              {filterDropdownUGM ? (
                <FilterComponent
                  //isPills={true}
                  isPills={false}
                  //headingName={headingNameUGM}
                  //isLandingPage={true}
                  col6={true}
                  filterData={filterDataUGM}
                  sortData={sortData}
                  handleFilterApply={(params) => {
                    handleFilterApplyUGM({
                      params,
                      filterDataUGM,
                      setFilterDataUGM,
                      setFilterDataTempUGM,
                      makeRequest,
                      setSearchFilterEmptyState,
                      searchUserGroup,
                      setAudienceGroupListCopy,
                      setAudienceGroupList,
                      setErrorStateTemplate,
                      isOnline,
                      setIsLoading,
                    });
                  }}
                  handleFilterCancle={() => setFilterDropdownUGM(false)}
                  isOpenSubCategories={true}
                />
              ) : null}
            </div>
          </div>
        </div>

        {1 == 2 && (
          <div className={[styles.audience_image_container].join(' ')}>
            <div className={styles.audience_middle_section}>
              <div className={styles.audience_image_area}>
                <div
                  className={[styles.audience_empty_screen_icon, styles.audience_path_icon].join(
                    ' ',
                  )}
                ></div>
                <div className={styles.empty_screen_path_detail}>
                  <div className={styles.error_label}>User Group Management</div>
                  <div className={styles.error_content}>
                    Ability to target courses at specific groups of ABOs coming later in 2022.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={[styles.audience_details_wrapper, styles.user_grp_wrapper, ''].join(' ')}>
          <TableListComponent
            isLoading={isLoading}
            list={audienceGroupList}
            EmptyScreenComponent={EmptyScreenComponent}
            isSearchFilterEmptyState={isSearchFilterEmptyState}
            onClickAudienceGrp={(e, item) => {
              redirectUserGroup({
                id: item,
                viewType: 'card',
                setSelectedNavigationOption,
                router,
              });
            }}
            stickyHeader={true}
            drawerOpen={false}
            headerColor={'white'}
            componentUGM={componentUGM}
            small={true}
          />
          <ViewAllComponent
            isLoading={isLoading}
            showEmptyTemplateFor={showEmptyTemplateFor}
            list={audienceGroupList}
            SelectedNavigationOptionText={'AUDIENCE-MANAGEMENT'}
            pageName="User Groups"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

AudienceSectionView.propTypes = {};

export default AudienceSectionView;
