import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styles from './DrawerCST.module.scss';
import DrawerFailScreen from '../../shared/drawerFailScreen';
import StatusLabel from '../../shared/StatusLabel';
import SearchListComponent from '../../shared/searchListComponent/index';
import LoadingShimmerCustom from '../../shared/LoadingShimmerCustom';
import {
  handleErrorStatesChinese,
  handleErrorStatesEnglish,
} from '../../utils/CategoriesSkillsTagsManagementUtils/CSTLandingPage';
import {dummyFunction} from '../../utils/commonUtil';
import {
  drawerCalculatedHeight,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  pullbox,
} from '../../utils/commonUtil';

const DrawerComponentCST = (props) => {
  const {
    drawerDetails = {},
    drawerOpen = false,
    drawerClose,
    isAPIFailed = false,
    drawerTryAgainCall = dummyFunction,
    drawerIdForBrokenLink = '',
    translationNames,
    handleTranslationChange,
    refreshList,
    learningPathsDrawerList,
    coursesDrawerList,
    isLoadingCoursesList = false,
    isLoadingLearningPathList = false,
    handleCSTTranslationChange,
    closeDrawerModal,
    isChineseTranslationEmpty,
    isEnglishTranslationEmpty,
    setChineseTranslationEmpty,
    setEnglishTranslationEmpty,
    translationNamesIntialCopy,
    handleCloseCST,
    handleOnClickEditCourse,
    handleOnClickEditLearningPath,
    list,
    setEnglishTranslationDuplicetName,
    englishTranslationDuplicetName,
    selctedCST,
    nameErrorStateEnglish,
    nameErrorStateChinese,
    setNameErrorStateEnglish,
    setNameErrorStateChinese,
    englishTranslationInvalidCharacter,
    setEnglishTranslationInvalidCharacter,
    chineseTranslationInvalidCharacter,
    setChineseTranslationInvalidCharacter,
    setNotificationData,
  } = props;

  //console.log(">>>> >>>> DrawerComponentCST >>>> props",props)
  const router = useHistory();
  const {query = {}} = router.location;

  const [isInfoIisClicked, setInfoIisClicked] = useState(false);
  const [isCoursesDrawerList, setCoursesDrawerList] = useState(true);
  const [isLearningPathsDrawerList, setLearningPathsDrawerList] = useState(true);

  // Error States
  const [englishTranslationLength, setEnglishTranslationLength] = useState('');
  const [chineseTranslationLength, setChineseTranslationLength] = useState('');

  const [maxCharErrorMessageEnglish, setMaxCharErrorMessageEnglish] = useState(false);
  const [maxCharErrorMessageChinese, setMaxCharErrorMessageChinese] = useState(false);

  const [drawerHeight, setDrawerHeight] = useState('');

  const regexNameChinese = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z:： -]+/g;
  const regexNameChinese2 = /[!^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z:： -]+/g;

  useEffect(() => {
    setCoursesDrawerList(true);
    setLearningPathsDrawerList(true);
    setEnglishTranslationEmpty(false);
    setChineseTranslationEmpty(false);
    setMaxCharErrorMessageChinese(false);
    setMaxCharErrorMessageEnglish(false);
    setNameErrorStateEnglish(false);
    setNameErrorStateChinese(false);
    setEnglishTranslationDuplicetName(false);
  }, [drawerDetails]);

  useEffect(() => {
    setDrawerHeight(140);
  }, [drawerOpen]);

  useEffect(() => {
    if (drawerHeight >= 600) {
      closeDrawerModal({
        englishTranslationLength,
        chineseTranslationLength,
        drawerClose,
        drawerOpen,
        drawerDetails,
        setChineseTranslationEmpty,
        setEnglishTranslationEmpty,
        translationNamesIntialCopy,
        translationNames,
        setEnglishTranslationDuplicetName,
        englishTranslationDuplicetName,
      });
    }
  }, [drawerHeight]);

  const openCourseWizard = (id, subheader) => {
    const url = '/create-course-wizard';
    // console.log(window.location.href);
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');

    router.push({
      pathname: url,
      search: `?id=${id}&sub_header=${subheader}`,
      //query: {actionName: ""},
    });
  };
  return (
    <React.Fragment>
      {drawerOpen && (
        <div id="divParentOfFilter" className={[styles.drawer_container, ''].join(' ')}>
          <div
            onClick={() => {
              closeDrawerModal({
                englishTranslationLength,
                chineseTranslationLength,
                drawerClose,
                drawerOpen,
                drawerDetails,
                setChineseTranslationEmpty,
                setEnglishTranslationEmpty,
                translationNamesIntialCopy,
                translationNames,
                setEnglishTranslationDuplicetName,
                englishTranslationDuplicetName,
              });
            }}
            className={styles.custom_overlay}
            style={drawerOverlayCalculatedHeight({drawerHeight: drawerHeight})}
          ></div>
          <div
            className={styles.drawer_wrapper}
            style={drawerCalculatedHeight({drawerHeight: drawerHeight})}
          >
            <div
              className={styles.mob_bar}
              draggable
              onTouchMove={(e) => {
                pullbox({e: e, setDrawerHeight: setDrawerHeight});
              }}
              onTouchEnd={() => {
                mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
              }}
              onClick={() =>
                  closeDrawerModal({
                    englishTranslationLength,
                    chineseTranslationLength,
                    drawerClose,
                    drawerOpen,
                    drawerDetails,
                    setChineseTranslationEmpty,
                    setEnglishTranslationEmpty,
                    translationNamesIntialCopy,
                    translationNames,
                    setEnglishTranslationDuplicetName,
                    englishTranslationDuplicetName,
                  })
                }
            >
              <div
                className={styles.line_bar}
              ></div>
            </div>
            {!isAPIFailed ? (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>{drawerDetails?.type}</div>

                  <div
                    className={styles.close_wrapper}
                    onClick={() =>
                      closeDrawerModal({
                        englishTranslationLength,
                        chineseTranslationLength,
                        drawerClose,
                        drawerOpen,
                        drawerDetails,
                        setChineseTranslationEmpty,
                        setEnglishTranslationEmpty,
                        translationNamesIntialCopy,
                        translationNames,
                        setEnglishTranslationDuplicetName,
                        englishTranslationDuplicetName,
                      })
                    }
                  >
                    <div className={styles.close_icon} />
                  </div>
                </div>

                {/* Form Fields */}
                <div className={styles.form_main_wrapper}>
                  {/* <div className={[styles.form_field, styles.category_dropdown].join(' ')}>
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>Category/Skill/Tag</div>
                    </div>
                  </div> */}

                  <div
                    className={[
                      styles.form_field,
                      nameErrorStateEnglish ||
                      isEnglishTranslationEmpty ||
                      englishTranslationDuplicetName ||
                      maxCharErrorMessageEnglish
                        ? styles.error_wrapper
                        : null,
                    ].join(' ')}
                  >
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>English translation*</div>
                      <div className={styles.tag_text}>Max 30 characters</div>
                    </div>

                    <div className={styles.input_wrapper}>
                      <input
                        type={'text'}
                        placeholder={'Enter English translation'}
                        maxLength="30"
                        className={[
                          styles.input,
                          maxCharErrorMessageEnglish ? styles.input_error_black : null,
                        ].join(' ')}
                        value={translationNames?.english_Translation}
                        onChange={(e) => {
                          setEnglishTranslationLength(e.target.value.length);
                          handleTranslationChange({e, variant: 'courses'});
                          handleErrorStatesEnglish({
                            e: e?.target?.value?.replaceAll(/\s+/g, ' '),
                            setNameErrorStateEnglish,
                            regexNameChinese2,
                            setMaxCharErrorMessageEnglish,
                            regexNameChinese,
                            list,
                            drawerDetails,
                            setEnglishTranslationDuplicetName,
                            setEnglishTranslationInvalidCharacter,
                          });
                          setEnglishTranslationEmpty(false);
                        }}
                        // onBlur={() => {
                        //   handleCSTTranslationChange({
                        //     type: drawerDetails?.type,
                        //     CSTId: drawerDetails?.id,
                        //   });
                        // }}
                      />

                      {maxCharErrorMessageEnglish && !nameErrorStateEnglish ? (
                        <div className={styles.error_message}>Maximum character limit reached</div>
                      ) : nameErrorStateEnglish &&
                        !maxCharErrorMessageEnglish &&
                        !isEnglishTranslationEmpty ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>
                            Valid characters include A-z, 0-9, :, -
                            {/* Invalid characters: {englishTranslationInvalidCharacter} */}
                          </div>
                        </>
                      ) : isEnglishTranslationEmpty ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>English Title is mandatory</div>
                        </>
                      ) : null}
                      {englishTranslationDuplicetName ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>Name already exists</div>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div
                    className={[
                      styles.form_field,
                      nameErrorStateChinese ||
                      isChineseTranslationEmpty ||
                      maxCharErrorMessageChinese
                        ? styles.error_wrapper
                        : null,
                    ].join(' ')}
                  >
                    <div className={styles.label_wrapper}>
                      <div className={styles.label_text}>Chinese translation*</div>
                      <div className={styles.tag_text}>Max 30 characters</div>
                    </div>

                    <div className={styles.input_wrapper}>
                      <input
                        type={'text'}
                        placeholder={'Enter Chinese translation'}
                        maxLength="30"
                        className={[
                          styles.input,
                          maxCharErrorMessageChinese ? styles.input_error_black : null,
                        ].join(' ')}
                        value={translationNames?.chinese_Translation}
                        onChange={(e) => {
                          handleTranslationChange({e, variant: 'learningPaths'});
                          handleErrorStatesChinese({
                            e,
                            setMaxCharErrorMessageChinese,
                            regexNameChinese,
                            setNameErrorStateChinese,
                            regexNameChinese2,
                            setChineseTranslationInvalidCharacter,
                          });
                          setChineseTranslationLength(
                            e.target.value?.toString()?.replaceAll(regexNameChinese, '')?.length,
                          );
                          setChineseTranslationEmpty(false);
                        }}
                        // onBlur={() => {
                        //   handleCSTTranslationChange({
                        //     type: drawerDetails?.type,
                        //     CSTId: drawerDetails?.id,
                        //   });
                        // }}
                      />

                      {maxCharErrorMessageChinese && !nameErrorStateChinese ? (
                        <div className={styles.error_message}>Maximum character limit reached</div>
                      ) : nameErrorStateChinese &&
                        !maxCharErrorMessageChinese &&
                        !isChineseTranslationEmpty ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>
                            Valid characters include A-z, 0-9, :, -
                            {/* Invalid characters: {chineseTranslationInvalidCharacter} */}
                          </div>
                        </>
                      ) : isChineseTranslationEmpty ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>Chinese Title is mandatory</div>
                        </>
                      ) : null}
                      {/* {isChineseTranslationEmpty ? (
                        <>
                          <div className={styles.error_icon_red}></div>
                          <div className={styles.error_message}>Chinese Title is mandatory</div>
                        </>
                      ) : null} */}
                    </div>
                    {/* {maxCharErrorMessageChinese ? <div className={styles.max_char_msg}>Maximum character limit reached</div> : null } */}
                  </div>
                </div>

                {/* Courses */}
                {coursesDrawerList?.length > 0 ? (
                  <>
                    <div className={styles.refresh_wrapper}>
                      <div
                        className={styles.refresh_txt}
                        onClick={() => {
                          refreshList({
                            variant: 'courses',
                            drawerId: drawerDetails?.id,
                            type: drawerDetails?.type,
                          });
                        }}
                      >
                        Refresh List
                      </div>
                      <div
                        className={styles.refresh_icon}
                        onClick={() => {
                          refreshList({
                            variant: 'courses',
                            drawerId: drawerDetails?.id,
                            type: drawerDetails?.type,
                          });
                        }}
                      />
                    </div>

                    <div className={[styles.img_instances_container, ''].join('')}>
                      <div
                        className={styles.dropdown_container}
                        // onClick={() => handleReplaceAllInstance()}
                      >
                        <div className={styles.top_bar}>
                          <div
                            className={styles.title}
                            onClick={() => {
                              setCoursesDrawerList(!isCoursesDrawerList);
                            }}
                          >
                            Courses in which this {drawerDetails?.type} is used (
                            {coursesDrawerList?.length}){' '}
                            {/* {courseDetails?.length > 0
                           ? `(${courseDetails?.length})`
                           : ""} */}
                          </div>
                          {/* Added "isClick" class here to open banner on click of info btn on drawer */}

                          <div
                            className={[
                              styles.toggle_arrow,
                              styles.big_icon,
                              isCoursesDrawerList ? styles.up_arrow : styles.down_arrow,
                            ].join(' ')}
                            onClick={() => {
                              setCoursesDrawerList(!isCoursesDrawerList);
                            }}
                          ></div>
                        </div>
                        {!isLoadingCoursesList ? (
                          isCoursesDrawerList && (
                            <div className={styles.list_wrapper}>
                              {coursesDrawerList?.map((item, index) => {
                                return (
                                  <div className={styles.list} key={index}>
                                    {/* keep below both class as it is "mt_instances". */}
                                    <div
                                      className={[styles.item_wrapper, styles.mt_instances].join(
                                        ' ',
                                      )}
                                    >
                                      <div
                                        className={[
                                          styles.course_type_icon,
                                          styles.courses_icon,
                                          styles.mt_class,
                                        ].join(' ')}
                                      ></div>
                                      <div className={styles.item}>{item?.title}</div>
                                    </div>

                                    <div className={styles.status_wrapper}>
                                      <StatusLabel item={item} large={true} />
                                    </div>

                                    <div
                                      className={
                                        item?.state != 'Archive'
                                          ? styles.edit_wrapper
                                          : styles.edit_icon_disabled
                                      }
                                      onClick={() => {
                                        item?.state != 'Archive' &&
                                          handleOnClickEditCourse({
                                            id: item?.id,
                                            state: item?.state,
                                          });
                                        item?.state == 'Archive' &&
                                          setNotificationData({
                                            show: true,
                                            type: 'WARNING',
                                            title: 'Course Archived.',
                                            description: `${item?.title} has been archived and cannot be edited.`,
                                          });
                                      }}
                                    >
                                      <div className={styles.edit_icon}></div>
                                    </div>
                                    {/* <div
                                  className={[
                                    styles.instance_status,
                                    item?.courseState == 'Live'
                                      ? styles.live_sts
                                      : item?.courseState == 'Draft'
                                      ? styles.draft_sts
                                      : item.courseState == 'Archive'
                                      ? styles.archive_sts
                                      : item.courseState == 'Offline'
                                      ? styles.offline_sts
                                      : '',
                                  ].join(' ')}
                                >
                                  {item?.courseState}
                                </div> */}
                                  </div>
                                );
                              })}
                            </div>
                          )
                        ) : (
                          <>
                            {(() => {
                              const arr = [];
                              for (let i = 0; i < 4; i++) {
                                {
                                  arr.push(
                                    <div className={[styles.list_wrapper, ''].join(' ')}>
                                      <LoadingShimmerCustom
                                        className={[styles.list, ''].join(' ')}
                                      />
                                    </div>,
                                  );
                                }
                              }
                              return arr;
                            })()}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}

                {/* Learning Path */}
                {learningPathsDrawerList?.length > 0 ? (
                  <>
                    {/* Refresh Button */}
                    <div className={styles.refresh_wrapper}>
                      <div
                        className={styles.refresh_txt}
                        onClick={() => {
                          refreshList({
                            variant: 'learningPaths',
                            drawerId: drawerDetails?.id,
                            type: drawerDetails?.type,
                          });
                        }}
                      >
                        Refresh List
                      </div>
                      <div
                        className={styles.refresh_icon}
                        onClick={() => {
                          refreshList({
                            variant: 'learningPaths',
                            drawerId: drawerDetails?.id,
                            type: drawerDetails?.type,
                          });
                        }}
                      />
                    </div>
                    {/* Refresh Button */}
                    <div className={[styles.img_instances_container, ''].join('')}>
                      <div
                        className={styles.dropdown_container}
                        // onClick={() => handleReplaceAllInstance()}
                      >
                        <div className={styles.top_bar}>
                          <div
                            className={styles.title}
                            onClick={() => {
                              setLearningPathsDrawerList(!isLearningPathsDrawerList);
                            }}
                          >
                            Learning Paths in which this {drawerDetails?.type} is used (
                            {learningPathsDrawerList?.length}){' '}
                            {/* {courseDetails?.length > 0
                           ? `(${courseDetails?.length})`
                           : ""} */}
                          </div>
                          {/* Added "isClick" class here to open banner on click of info btn on drawer */}

                          <div
                            className={[
                              styles.toggle_arrow,
                              styles.big_icon,
                              isLearningPathsDrawerList ? styles.up_arrow : styles.down_arrow,
                            ].join(' ')}
                            onClick={() => {
                              setLearningPathsDrawerList(!isLearningPathsDrawerList);
                            }}
                          ></div>
                        </div>
                        {!isLoadingLearningPathList ? (
                          isLearningPathsDrawerList && (
                            <div className={styles.list_wrapper}>
                              {learningPathsDrawerList?.map((item, index) => {
                                return (
                                  <div className={styles.list} key={index}>
                                    {/* keep below both class as it is "mt_instances". */}
                                    <div
                                      className={[styles.item_wrapper, styles.mt_instances].join(
                                        ' ',
                                      )}
                                    >
                                      <div
                                        className={[
                                          styles.course_type_icon,
                                          styles.learning_path_icon,
                                          styles.mt_class,
                                        ].join(' ')}
                                      ></div>
                                      <div className={styles.item}>{item?.title}</div>
                                    </div>

                                    <div className={styles.status_wrapper}>
                                      <StatusLabel item={item} large={true} />
                                    </div>

                                    <div
                                      className={
                                        item?.state != 'Archive'
                                          ? styles.edit_wrapper
                                          : styles.edit_icon_disabled
                                      }
                                      onClick={() => {
                                        item?.state != 'Archive' &&
                                          handleOnClickEditLearningPath({
                                            id: item?.id,
                                            state: item?.state,
                                          });
                                        item?.state == 'Archive' &&
                                          setNotificationData({
                                            show: true,
                                            type: 'WARNING',
                                            title: 'Learning Path Archived.',
                                            description: `${item?.title} has been archived and cannot be edited.`,
                                          });
                                      }}
                                    >
                                      <div className={styles.edit_icon}></div>
                                    </div>
                                    {/* <div
                                  className={[
                                    styles.instance_status,
                                    item?.courseState == 'Live'
                                      ? styles.live_sts
                                      : item?.courseState == 'Draft'
                                      ? styles.draft_sts
                                      : item.courseState == 'Archive'
                                      ? styles.archive_sts
                                      : item.courseState == 'Offline'
                                      ? styles.offline_sts
                                      : '',
                                  ].join(' ')}
                                >
                                  {item?.courseState}
                                </div> */}
                                  </div>
                                );
                              })}
                            </div>
                          )
                        ) : (
                          <>
                            {(() => {
                              const arr = [];
                              for (let i = 0; i < 4; i++) {
                                {
                                  arr.push(
                                    <div className={[styles.list_wrapper, ''].join(' ')}>
                                      <LoadingShimmerCustom
                                        className={[styles.list, ''].join(' ')}
                                      />
                                    </div>,
                                  );
                                }
                              }
                              return arr;
                            })()}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : null}
                {/* Category Message Starts */}
                {coursesDrawerList?.length === 0 && learningPathsDrawerList?.length === 0 ? (
                  <div className={[styles.category_message, styles.default].join(' ')}>
                    <div className={styles.category}>
                      {drawerDetails?.type} not associated with any Instance
                    </div>
                  </div>
                ) : null}
                {/* Category Message Ends */}

                {/* Delete Button */}
                <div
                  className={[
                    styles.btn_wrapper,
                    coursesDrawerList?.length !== 0 || learningPathsDrawerList?.length !== 0
                      ? styles.disabled
                      : null,
                  ].join(' ')}
                >
                  <div
                    className={styles.btn}
                    onClick={() => {
                      handleCloseCST();
                    }}
                  >
                    Delete
                    <div className={styles.delete_icon} />
                  </div>
                </div>

                {/* Category Message Starts */}
                {coursesDrawerList?.length !== 0 || learningPathsDrawerList?.length !== 0 ? (
                  <div className={[styles.category_message, styles.warning].join(' ')}>
                    <div className={styles.category}>
                      {drawerDetails?.type} cannot be deleted while in use by Courses or Learning
                      Paths.
                    </div>
                  </div>
                ) : null}
                {/* Category Message Ends */}
              </div>
            ) : (
              <div className={[styles.middle_container, ''].join(' ')}>
                <div className={styles.header}>
                  <div className={styles.title}>{selctedCST}</div>
                  <div
                    className={styles.close_wrapper}
                    onClick={() =>
                      closeDrawerModal({
                        englishTranslationLength,
                        chineseTranslationLength,
                        drawerClose,
                        drawerOpen,
                        drawerDetails,
                        setChineseTranslationEmpty,
                        setEnglishTranslationEmpty,
                        translationNamesIntialCopy,
                        translationNames,
                        setEnglishTranslationDuplicetName,
                        englishTranslationDuplicetName,
                      })
                    }
                  >
                    <div className={styles.close_icon} />
                  </div>
                </div>
                <DrawerFailScreen
                  drawerId={drawerIdForBrokenLink}
                  drawerTryAgainCall={(item) => {
                    drawerTryAgainCall(item);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

DrawerComponentCST.propTypes = {};

export default DrawerComponentCST;
