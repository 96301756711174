import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import styles from './Course.module.scss';
import Header from '../shared/header';
import DeleteModal from '../audienceManagement/DeleteModal';
import FilterComponent from '../shared/filter';
import CourseWizardComponent from './CourseWizard';
import {
  setNotificationData,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  setSelectedNavigationOption,
  setIsUserLoggedIn,
} from '../../redux/actions/uiActions';
import {setErrorStateTemplate} from '../../redux/actions/uiActions';
import {
  setReplacedImage,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setImageData,
  setMainImageBackup,
  setCroppedImageData,
  setCroppedImageData169,
  setCroppedImageData11,
  setThumbnailImage169,
} from '../../redux/actions/imageCrop/imageCropAction';
import {
  setNewCourseWizardState,
  setNewCourseData,
  setNewThumbnailId,
  setBlockNavigation,
} from '../../redux/actions/newCourseActions';
import {connect} from 'react-redux';
import Notification from '../shared/notification';
import {setCourseMode} from '../../redux/actions/uiActions';
import {Hint} from 'react-autocomplete-hint';
import EmptyScreenComponent from '../shared/emptyScreen';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
import DrawerFailScreen from '../shared/drawerFailScreen';
import LoadingShimmer from '../shared/LoadingShimmer';
import LoadingShimmerList from '../shared/LoadingShimmerList';
import {
  drawerCalculatedHeight,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  pullbox,
} from '../utils/commonUtil';
// import SeverErrorComponent from "../shared/serverErrorScreen"

const CourseManagementComponent = ({
  setOpenImageLib,
  thumbnailId,
  newCourse,
  setNotificationData,
  notificationData,
  newCourseWizardState,
  setNewCourseWizardState,
  setNewCourseData,
  courseMode,
  setCourseMode,
  setNotificationListApi,
  setDashboardNotificationCountApi,
  currentUserId,
  setMainImageBackup,
  setReplacedImage,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setImageData,
  setCroppedImageData,
  setCroppedImageData169,
  setCroppedImageData11,
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setErrorStateTemplate,
  setNewThumbnailId,
  setThumbnailImage169,
  setBlockNavigation,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const [isCardView, setIsCardView] = useState(true);
  const [isListView, setIsListView] = useState(false);
  const [search, setSearch] = useState('');
  const [isDelete, setDelete] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showCreateNewCoursesWizard, setCreateNewCoursesWizard] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // API states
  const [coursesAPIResponse, setCoursesAPIResponse] = useState([]);
  const [coursesUIData, setCoursesUiData] = useState([]);
  const [courseListDefaultFilter, setCourseListDefaultFilter] = useState([]);

  const [changeCourseList, setChangeCourseList] = useState(false);
  const [tempCourseList, setTempCourseList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [predictedSearch, setPredictedSearch] = useState('');
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [openNewCourseState, setOpenNewCourseState] = useState(false);
  const [isOnline, setOnline] = useState(true);
  const [isDrawerAPIFailed, setDrawerAPIFailed] = useState(false);
  const [drawerIdForBrokenLink, setDrawerIdForBrokenLink] = useState('');
  const [courseListCopy, setCourseListCopy] = useState([]);
  const [drawerHeight, setDrawerHeight] = useState('');
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));

  window.addEventListener('online', () => {
    setOnline(true);
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
  });
  const _initialFilterData = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Draft',
        className: 'draft',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Live',
        className: 'goLive',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Offline',
        className: 'goOffline',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 3,
        id: 'ARCHIEVE',
        title: 'Archive',
        className: 'archive',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launch_date',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launch_date',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: [],
        },
      },
    ],
  };
  const [filterData, setFilterData] = useState(_initialFilterData);
  const [filterDataTemp, setFilterDataTemp] = useState(new Object(_initialFilterData));

  const updateCourseList = (list) => {
    const tempList = new Array(...list);
    setTempCourseList(tempList);
    setShowFilters(false);
  };

  const handleFilterApply = (params) => {
    setCoursesUiData([]);
    setChangeCourseList(true);

    let _coursesUIData = [];

    let _coursesAPIResponse = [];
    if (params?.find((ele) => ele?.name === 'Recent Activity')) {
      _coursesAPIResponse = [...courseListDefaultFilter];
    } else {
      _coursesAPIResponse = [...coursesAPIResponse];
    }

    let checkBoxFilterArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');
    let checkBoxLables = [];
    let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
    let tilesLables = [];
    let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');

    if (checkBoxFilterArray !== []) {
      for (const ele of checkBoxFilterArray) {
        if (ele?.checked) {
          checkBoxLables.push(ele?.title);
        }
      }
      let tempSectionData = new Array(...filterData.filtersList[1].filterTypeData.sectionData);
      tempSectionData?.forEach((x) => {
        if (checkBoxLables?.includes(x?.title)) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
      filterData.filtersList[1].filterTypeData.sectionData = tempSectionData;
      setFilterData(filterData);
    }

    if (tilesFilterArray !== []) {
      for (const ele of tilesFilterArray) {
        if (ele?.checked) {
          tilesLables.push(ele?.title);
        }
      }

      const tempSectionData = new Array(...filterData.tiles);

      filterData.tiles = tempSectionData;
      setFilterData(filterData);
    }

    if (checkBoxLables?.length > 0) {
      _coursesUIData = _coursesAPIResponse.filter((ele) => {
        return checkBoxLables.includes(ele?.category);
      });
    } else {
      _coursesUIData = _coursesAPIResponse;
    }

    if (tilesLables?.length > 0) {
      _coursesUIData = _coursesUIData.filter((ele) => {
        return tilesLables.includes(ele?.course_state);
      });
    }

    if (radioFilterArray !== []) {
      for (const ele of radioFilterArray) {
        const tempSectionData = new Array(...filterData.filtersList[0].filterTypeData.sectionData);
        tempSectionData?.forEach((x) => {
          if (ele?.name === x?.name) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        filterData.filtersList[0].filterTypeData.sectionData = tempSectionData;
        setFilterData(filterData);

        switch (ele?.filterParameterType) {
          case 'ASC':
            if (_coursesUIData?.length > 0) {
              _coursesUIData = _coursesUIData.sort((a, b) => {
                if (a[ele?.filterParameter]?.toLowerCase() > b[ele?.filterParameter]?.toLowerCase())
                  return 1;
                else if (
                  a[ele?.filterParameter]?.toLowerCase() < b[ele?.filterParameter]?.toLowerCase()
                )
                  return -1;
                else return 0;
              });
            }
            break;

          case 'DESC':
            if (_coursesUIData?.length > 0) {
              _coursesUIData = _coursesUIData.sort((a, b) => {
                if (a[ele?.filterParameter]?.toLowerCase() < b[ele?.filterParameter]?.toLowerCase())
                  return 1;
                else if (
                  a[ele?.filterParameter]?.toLowerCase() > b[ele?.filterParameter]?.toLowerCase()
                )
                  return -1;
                else return 0;
              });
            }
            break;

          case 'TIME-ASC':
            if (_coursesUIData?.length > 0) {
              _coursesUIData = _coursesUIData.sort((a, b) => {
                if (
                  a[ele?.filterParameter] === undefined ||
                  a?.course_state?.toLowerCase() === 'draft'
                )
                  return 1;
                else if (
                  b[ele?.filterParameter] === undefined ||
                  b?.course_state?.toLowerCase() === 'draft'
                )
                  return -1;
                else if (a[ele?.filterParameter] < b[ele?.filterParameter]) return 1;
                else if (a[ele?.filterParameter] > b[ele?.filterParameter]) return -1;
                else return 0;
              });
            }
            break;

          case 'TIME-DESC':
            if (_coursesUIData?.length > 0) {
              _coursesUIData = _coursesUIData.sort((a, b) => {
                if (
                  a[ele?.filterParameter] === undefined ||
                  a?.course_state?.toLowerCase() === 'draft'
                )
                  return 1;
                else if (
                  b[ele?.filterParameter] === undefined ||
                  b?.course_state?.toLowerCase() === 'draft'
                )
                  return -1;
                else if (a[ele?.filterParameter] > b[ele?.filterParameter]) return 1;
                else if (a[ele?.filterParameter] < b[ele?.filterParameter]) return -1;
                else return 0;
              });
            }
            break;

          case 'default':
            _coursesUIData = _coursesUIData;
            break;
        }
      }
    }

    updateCourseList(_coursesUIData);
  };
  const handleFilterApplyNew = async (params) => {
    setIsLoading(true);
    let tilesFilterArray = params.filter((ele) => ele.typeOfFilter === 'tiles');
    const categoryId = [];
    let categoriesArray = params.filter((ele) => ele.typeOfFilter === 'CHECKBOX');
    for (const ele of categoriesArray) {
      ele.checked && categoryId.push(ele.id);
    }
    let bodyVar = {
      categoryId,
    };
    let sortBy = '';
    let sortByName = 'Recent Activity';
    let radioFilterArray = params.filter((ele) => ele.typeOfFilter === 'RADIO');
    radioFilterArray.forEach((ele) => {
      switch (ele?.name) {
        case 'Recent Activity':
          (sortBy = 'Recent_Activity'), (sortByName = 'Recent Activity');
          break;
        case 'A - Z':
          (sortBy = 'A_Z'), (sortByName = 'A - Z');
          break;
        case 'Z - A':
          (sortBy = 'Z_A'), (sortByName = 'Z - A');
          break;
        case 'Newest To Oldest':
          (sortBy = 'Newest_To_Oldest'), (sortByName = 'Newest To Oldest');
          break;
        case 'Oldest To Newest':
          (sortBy = 'Oldest_To_Newest'), (sortByName = 'Oldest To Newest');
          break;
        default:
          sortBy = 'Recent_Activity';
          break;
      }
    });
    let categoriesData = filterData.filtersList[1].filterTypeData.sectionData;
    let sortByData = filterData.filtersList[0].filterTypeData.sectionData;
    sortByData.forEach((data) => {
      if (data?.name == sortByName) {
        data.checked = true;
      } else {
        data.checked = false;
      }
    });
    const _filterData = {
      tiles: tilesFilterArray,
      filtersList: [
        {
          filterType: 'RADIO',
          filterTypeData: {
            sectionTitle: 'Sort By',
            sectionData: sortByData,
          },
        },
        {
          filterType: 'CHECKBOX',
          filterTypeData: {
            sectionTitle: 'Categories',
            sectionData: categoriesData,
          },
        },
      ],
    };
    setFilterData(_filterData);
    setFilterDataTemp(new Object(_filterData));
    await fetchCoursesListWithParams(bodyVar, sortBy, tilesFilterArray);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const fetchCoursesListWithParams = async (body, sortBy, tilesFilterArray) => {
    setIsLoading(true);
    let endpoint = `/admin/api/v1/courses`;

    if (sortBy) {
      endpoint += `?sortBy=${sortBy}`;
    }
    // if (courseState){
    //   endpoint  += `&courseState=${courseState}`
    // }
    const dataCourses = await makeRequest({
      method: 'POST',
      endpoint,
      body,
    });
    if (dataCourses != 'ERR_NETWORK' && dataCourses) {
      let newDataCourses = [];
      newDataCourses = dataCourses?.data;
      let tilesLables = [];
      if (tilesFilterArray !== []) {
        for (const ele of tilesFilterArray) {
          if (ele?.checked) {
            tilesLables.push(ele?.title);
          }
        }
        if (tilesLables?.length > 0) {
          newDataCourses = dataCourses?.data.filter((ele) => {
            return tilesLables.includes(ele?.course_state);
          });
        }
      }
      setCoursesAPIResponse(newDataCourses);
      setCourseListDefaultFilter(newDataCourses);
      setSearchFilterEmptyState(true);
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
      if (search && search.length && search.length >= 3) {
        setCoursesUiData(
          filter(newDataCourses, (c) => {
            return (
              c &&
              c.title &&
              c.title.toLowerCase &&
              c.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          }),
        );
        setCourseListCopy(
          filter(newDataCourses, (c) => {
            return (
              c &&
              c.title &&
              c.title.toLowerCase &&
              c.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          }),
        );
      } else {
        let _coursesUIData = [];
        newDataCourses.map((course) => {
          _coursesUIData.push(getCoursesUIData(course));
        });
        setCoursesUiData(_coursesUIData);
        setCourseListCopy(_coursesUIData);
      }
    } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 250);
  };
  const mobileDrawerOutsideClick = (event) => {
    if (
      event.target.className.includes('Course_custom_overlay') ||
      event.target.className.includes('Course_mob_bar') ||
      event.target.className.includes('Course_line_bar')
    ) {
      setSelectedCourse(null);
    }
  };

  const handleClickOutside = (e) => {
    const el = document.getElementById('divParentOfFilter');

    if (el !== null) {
      const outside = !el?.contains(e.target);
      if (outside) {
        if (
          !e.target.className.includes('Course_filter') &&
          !e.target.className.includes('Filter') &&
          !e.target.className.includes('RadioButtons') &&
          !e.target.className.includes('Checkbox') &&
          !e.target.className.includes('RadioButtons_container')
        ) {
          setShowFilters(false);
        }
      }
    }
  };

  const dateIsoToDate = (dateIso) => {
    const monthArray = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];
    const date = new Date(dateIso);
    return `${monthArray[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const dateIsoToTime = (dateIso) => {
    const date = new Date(dateIso);
    let hours =
      Number(date.getHours().toString()) > 12
        ? Number(date.getHours().toString()) - 12
        : date.getHours();

    hours = hours > 9 ? hours : '0' + hours;
    let minutes =
      Number(date.getMinutes()) > 9 ? Number(date.getMinutes()) : '0' + Number(date.getMinutes());
    const timeFormat = Number(date.getHours().toString()) > 12 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${timeFormat}`;
  };

  const dateIsoToNormalDate = (dateIso) => {
    const date = new Date(dateIso);
    let dd = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate();
    let mm = date.getMonth() <= 8 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    return `${dd}/${mm}/${date.getFullYear()}`;
  };

  const fetchSelectedCourse = async (courseId) => {
    //setSelectedCourse({courseId: courseId});
    //setDrawerIdForBrokenLink(courseId);
    if (
      courseId != 0 &&
      courseId != '0' &&
      courseId != null &&
      courseId != 'null' &&
      courseId != undefined &&
      courseId != 'undefined' &&
      courseId != '0&sub_header'
    ) {
      const dataCourseId = await makeRequest({
        method: 'GET',
        endpoint: `/admin/api/v1/courses/${courseId}`,
      });
      if (dataCourseId != 'ERR_NETWORK' && dataCourseId) {
        setDrawerAPIFailed(false);
        let respData = {};

        if (dataCourseId?.data) {
          respData = {
            ...dataCourseId?.data,
            course_state: dataCourseId?.data?.courseState,
          };
        }
        setSelectedCourse(respData);
        var element = document.getElementById(courseId);
        element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
      } else if (dataCourseId == 'ERR_NETWORK') {
        setDrawerAPIFailed(true);
        if (courseId != undefined && courseId !== drawerIdForBrokenLink) {
          setDrawerIdForBrokenLink(courseId);
        }
        // setSelectedCourse({});
        setSelectedCourse({courseId: drawerIdForBrokenLink});
      }
    }
  };

  const fetchCoursesList = async () => {
    setIsLoading(true);
    const dataCourses = await makeRequest({
      method: 'POST',
      endpoint: '/admin/api/v1/courses',
    });
    if (dataCourses != 'ERR_NETWORK' && dataCourses) {
      setCoursesAPIResponse(dataCourses?.data);
      setCourseListDefaultFilter(dataCourses?.data);
      setSearchFilterEmptyState(true);
      setErrorStateTemplate({
        variant: '',
        status: false,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    } else if (dataCourses == 'ERR_NETWORK' && isOnline) {
      setErrorStateTemplate({
        variant: 'serverError',
        status: true,
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 250);
    }
  };

  const changeCourseStatus = async (courseId, status, selectedCourse) => {
    const dataCourses = await makeRequest({
      method: 'PATCH',
      endpoint: `/admin/api/v1/courses/${courseId}/${status}`,
    });
    if (dataCourses !== 'ERR_NETWORK') {
      await fetchCoursesList();
      await fetchSelectedCourse(courseId);
      status == 'live' &&
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'Course turned Live',
          description: `${selectedCourse.englishTitle} has been turned live by the ${oktaToken.idToken.claims.fullName}`,
        });
      status == 'offline' &&
        setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: `Course turned Offline`,
          description: `${selectedCourse.englishTitle} has been turned offline by the ${oktaToken.idToken.claims.fullName}`,
        });
    }
    setNotificationListApi(currentUserId);
    setDashboardNotificationCountApi(currentUserId);
  };

  const handleSearchInput = (event) => {
    setSearch(event?.target?.value);
    setPredictedSearch('');

    // if (event?.target?.value.length > 2) {
    //   setSearchDropdown(true);
    // } else {
    //   setSearchDropdown(false);
    //   setPredictedSearch("");
    // }

    /// Bug raised by QA for search input changes 27-02-2023 commenting below search code

    // if (event.charCode == 13 && event?.target?.value.length <= 2) {
    //   setNotificationData({
    //     show: false,
    //     type: 'WARNING',
    //     title: 'Search requires 3 or more characters',
    //     description: 'Please enter a longer search term.',
    //   });
    // }
    // const predictedResult = ImageList.filter((item) => {
    //   return item.fileName.toLowerCase().search(event?.target?.value?.toLowerCase()) !== -1;
    // });
    let tempPredition = coursesUIData.map((item) => ({
      id:
        item?.courseId && item?.courseId !== null && item.courseId !== undefined
          ? item?.courseId
          : '',
      label: item?.title && item?.title !== null && item.title !== undefined ? item?.title : '',
    }));

    if (tempPredition.length > 0 && event?.target?.value?.length >= 0) {
      setPredictedSearch(tempPredition ? tempPredition : {});
    } else {
      setPredictedSearch([]);
    }
  };

  const deleteCourse = async () => {
    const categoryId = [];
    let categoriesArray = filterData?.filtersList[1]?.filterTypeData?.sectionData?.filter(
      (ele) => ele.typeOfFilter === 'CHECKBOX',
    );
    for (const ele of categoriesArray) {
      ele.checked && categoryId.push(ele.id);
    }

    let sortBy = '';
    let radioFilterArray = filterData?.filtersList[0]?.filterTypeData?.sectionData?.filter(
      (ele) => ele.checked === true,
    );
    radioFilterArray.forEach((ele) => {
      switch (ele?.name) {
        case 'Recent Activity':
          sortBy = 'Recent_Activity';
          break;
        case 'A - Z':
          sortBy = 'A_Z';
          break;
        case 'Z - A':
          sortBy = 'Z_A';
          break;
        case 'Newest To Oldest':
          sortBy = 'Newest_To_Oldest';
          break;
        case 'Oldest To Newest':
          sortBy = 'Oldest_To_Newest';
          break;
        default:
          sortBy = 'Recent_Activity';
          break;
      }
    });
    let tempArr = [];
    let tempObj = {};
    const courseId = selectedCourse?.courseId;
    selectedCourse?.audienceGroups?.forEach((data) => {
      tempArr.push({
        audGrpId: data.id,
        courseType: data.courseType,
      });
    });
    tempObj = {
      audienceGroups: {
        // addAudGrpIds : [],
        deleteAudGrpIds: tempArr,
      },
    };
    const dataCourses = await makeRequest({
      method: 'DELETE',
      endpoint: `/admin/api/v1/courses/${courseId}`,
      body: tempObj,
    });
    if (dataCourses) {
      // await fetchCoursesList();
      await fetchCoursesListWithParams({categoryId: categoryId}, sortBy, filterData?.tiles);
      setNotificationData({
        show: true, // boolean to show-hide the notification. At the time of hiding the notification, pass only show: false. No other params are reqd
        title: 'Course Deleted',
        description: `${selectedCourse?.englishTitle} which was in Draft state has been deleted.`,
        type: 'SUCCESS',
      });
      setSelectedCourse(null);
    }
    setNotificationListApi(currentUserId);
    setDashboardNotificationCountApi(currentUserId);
    setDelete(false);
  };

  const fetchCategories = async () => {
    const dataCategories = await makeRequest({
      method: 'GET',
      endpoint: '/api/v1/categories',
    });
    if (dataCategories) {
      let _filterData = filterData;
      let _filtersList = _filterData.filtersList;
      let _tempFiltersList = [];
      let index = findIndex(_filtersList, ['filterType', 'CHECKBOX']);
      if (index !== -1) {
        dataCategories?.data?.length &&
          dataCategories?.data?.length > 0 &&
          dataCategories?.data?.map((dataItem, _index) => {
            const {id = '', title = ''} = dataItem;
            _tempFiltersList.push({
              index: _index,
              id: id,
              label: title,
              title: title,
              filterParameter: 'category',
              checked: false,
              typeOfFilter: 'CHECKBOX',
            });
          });
        _filtersList[index].filterTypeData.sectionData = _tempFiltersList;
      }

      setFilterData(_filterData);
    }
  };

  useEffect(async () => {
    setIsUserLoggedIn(true);
    setSelectedNavigationOption('COURSES-MANAGEMENT');
    setBlockNavigation(false);
    if (query?.viewType === 'card') {
      setIsCardView(true);
      setIsListView(false);
    }

    if (query?.viewType === 'list') {
      setIsCardView(false);
      setIsListView(true);
    }
    await fetchCoursesList();
    if (query?.id) {
      await fetchSelectedCourse(query?.id);
    }

    if (router.location.search) {
      let stringId = router.location.search;
      const stringArray = stringId.split('=');
      stringArray &&
        stringArray[1] &&
        stringArray[1] != '0' &&
        (await fetchSelectedCourse(stringArray[1]));
    }

    // fetch filter categories
    fetchCategories();

    setCourseMode('');
  }, []);

  useEffect(() => {
    setCoursesUiData(tempCourseList);
    setCourseListCopy(tempCourseList);
  }, [tempCourseList]);

  useEffect(() => {
    if (changeCourseList && tempCourseList !== coursesUIData) {
      setCoursesUiData(tempCourseList);
      setCourseListCopy(tempCourseList);
      if (tempCourseList === coursesUIData) {
        setChangeCourseList(false);
      }
    }
  }, [coursesUIData]);

  useEffect(() => {
    if (coursesAPIResponse && coursesAPIResponse.length && coursesAPIResponse.length > 0) {
      let _coursesUIData = [];
      coursesAPIResponse.map((course) => {
        _coursesUIData.push(getCoursesUIData(course));
      });
      setCoursesUiData(_coursesUIData);
      setCourseListCopy(_coursesUIData);
    }
  }, [coursesAPIResponse]);

  // useEffect(() => {
  //   if (search && search.length && search.length >= 3) {
  //     setCoursesUiData(
  //       filter(coursesUIData, (c) => {
  //         return (
  //           c &&
  //           c.title &&
  //           c.title.toLowerCase &&
  //           c.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
  //         );
  //       }),
  //     );
  //   } else {
  //     let _coursesUIData = [];
  //     coursesAPIResponse.map((course) => {
  //       _coursesUIData.push(getCoursesUIData(course));
  //     });
  //     setCoursesUiData(_coursesUIData);
  //   }
  // }, [search]);

  useEffect(() => {
    if (search && search.length && search.length >= 1) {
      let result;
      result = courseListCopy.filter((user) =>
        user.title.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of audi group name
      result?.sort(function (one, other) {
        return one?.title?.length - other?.title?.length;
      });
      //for checking the serach char index in audi group name
      result?.sort(function (one, other) {
        return (
          one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.title?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });
      setCoursesUiData(result);
    } else {
      setCoursesUiData(courseListCopy);
    }
  }, [search]);

  const getCoursesUIData = (course) => {
    const {courseId, course_state, description, launch_date, thumbnail, title, bannerImage} =
      course;
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    let dateToShowForListView = ' ';
    let dateToShowForCardView = ' ';
    let timeToShow = ' ';
    if (launch_date) {
      const date = new Date(launch_date);
      const hours = date.getHours();
      const mins = date.getMinutes();
      timeToShow = (hours > 12 ? hours - 12 : hours) + ':' + mins + (hours > 12 ? ' pm' : ' am');
      dateToShowForListView =
        months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
      dateToShowForCardView =
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    } else {
      dateToShowForListView = ' ';
      dateToShowForCardView = ' ';
    }

    let className = '';
    switch (course_state) {
      case 'Live':
        className = 'live';
        break;
      case 'Offline':
        className = 'offline';
        break;
      case 'Draft':
        className = 'draft';
        break;
      case 'Archive':
        className = 'archive';
        break;
    }

    return {
      courseId,
      course_state,
      description,
      thumbnail,
      title,
      bannerImage,
      timeToShow,
      dateToShowForListView,
      dateToShowForCardView,
      className,
      launch_date,
    };
  };

  const handleToggleSwitch = (value) => {
    if (value === 'listView') {
      setIsCardView(false);
      setIsListView(true);
    } else {
      setIsCardView(true);
      setIsListView(false);
    }
  };

  const handleDeleteModal = () => {
    setDelete(!isDelete);
  };

  // const [width, setWidth] = useState(0);
  // const [height, setHeight] = useState(0);

  const updateWindowDimensions = () => {
    // setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
  };
  useEffect(() => {
    setOnline(navigator.onLine);

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    document.addEventListener('mousedown', handleClickOutside, true);
    document.addEventListener('mousedown', mobileDrawerOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('click', mobileDrawerOutsideClick, true);
      document.removeEventListener('mousedown', mobileDrawerOutsideClick, true);
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const handleOnClickNewCourse = async (courseState) => {
    if (selectedCourse?.courseState === 'Archive') {
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Course Archive',
        description: `${
          selectedCourse?.englishTitle
            ? selectedCourse.englishTitle
            : selectedCourse?.chineseTitle
            ? selectedCourse.chineseTitle
            : ''
        } has been archived and cannot be edited.`,
      });
    } else if (courseState === 'edit') {
      openCourseWizard(selectedCourse?.courseId, 'edit');
      setNewCourseWizardState({
        courseWizardOpen: true,
        courseWizardStateOpen: 'edit',
        section: '',
      });
      setCreateNewCoursesWizard(true);
    } else if (courseState === 'main') {
      openCourseWizard(selectedCourse?.courseId, 'course_description');
      setNewCourseWizardState({
        courseWizardOpen: true,
        courseWizardStateOpen: 'main',
        section: '',
      });
      setCreateNewCoursesWizard(true);
    }
  };

  const getCourseButtonStatus = () => {
    const status = selectedCourse?.course_state?.toString()?.toLowerCase();
    return status === 'live' ? 'Go Offline' : status === 'offline' ? 'Go Live' : 'Delete Course';
  };

  useEffect(() => {
    newCourse && setCreateNewCoursesWizard(true);
  }, [newCourse]);

  useEffect(() => {
    if (selectedCourse && selectedCourse.redirectToWizard) {
      setThumbnailImage169(null);
      handleOnClickNewCourse('main');
      setMainImageBackup(null);
      setReplacedImage(null);
      setCroppedImage_16_9(null);
      setCroppedImage_1_1(null);
      setImageData(null);
      setCroppedImageData(null);
      setCroppedImageData169(null);
      setCroppedImageData11(null);
    }
    setDrawerHeight(140);
  }, [selectedCourse]);

  useEffect(() => {
    // closeSortAndFilterWhenClickedOutside();
    if (courseMode === 'FROM_SIDE_NAVIGATION') {
      //closeSortAndFilterWhenClickedOutside();
      if (newCourseWizardState && newCourseWizardState?.courseWizardOpen) {
        setCreateNewCoursesWizard(true);
      } else {
        setCreateNewCoursesWizard(false);
      }
      fetchCoursesList();

      // if (query?.id) {
      //   fetchSelectedCourse(query?.id);
      // }

      // fetch filter categories
      fetchCategories();
      setCourseMode('');
    }
  }, [courseMode]);
  useEffect(() => {
    if (newCourseWizardState && newCourseWizardState?.courseWizardOpen) {
      setCreateNewCoursesWizard(newCourseWizardState.courseWizardOpen);
    } else {
      setCreateNewCoursesWizard(false);
    }
  }, [newCourseWizardState]);
  const allCheckFunc = (data) => {
    if (
      data &&
      data?.englishTitle?.length > 0 &&
      data?.englishTitle !== '' &&
      data?.chineseTitle?.length > 0 &&
      data?.chineseTitle !== '' &&
      data?.englishDescription?.length > 0 &&
      data?.englishDescription !== '' &&
      data?.chineseDescription?.length > 0 &&
      data?.chineseDescription !== '' &&
      ((data?.author?.length && data?.author !== '' > 0) ||
        (data?.englishSpeaker?.length && data?.englishSpeaker !== '' > 0)) &&
      (data?.thumbnailId !== '' || data?.thumbnail !== '') &&
      data?.thumbnailId !== '' &&
      data?.category !== '' &&
      data?.category?.length > 0 &&
      data?.duration + '' !== '' &&
      data?.duration > 0 &&
      //data?.skills?.length > 0 &&
      //data?.keywords?.length > 0 &&

      data &&
      data?.adaptFileName?.length > 0
      //   &&
      // publishButtonAfterPreview === false
    ) {
      //setNextButtonDisable(false);
      return true;
    } else {
      //setNextButtonDisable(true);
      return false;
    }
  };

  const closeDrawer = () => {
    setSelectedCourse(null);
    setDrawerAPIFailed(false);
  };
  const toggleSwitch = (cardType = 'cardView') => {
    setIsLoading(true);
    handleToggleSwitch(cardType);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const openCourseWizard = (id, subheader) => {
    const url = '/create-course-wizard';
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');

    router.push({
      pathname: url,
      search: `?id=${id}&sub_header=${subheader}`,
      //query: {actionName: ""},
    });
  };

  useEffect(() => {
    if (drawerHeight >= 600) {
      closeDrawer();
    }
  }, [drawerHeight]);
  return (
    <React.Fragment>
      {/* Course management */}
      {!showCreateNewCoursesWizard && (
        <div className={[styles.course_mgt_wrapper, ''].join(' ')}>
          {/* Header Component */}
          <Header
            headerName="Course Management"
            headerXSName=" "
            btnName1="Cancel"
            btnName2="Save changes"
            isHeaderXS={false}
            isBtn1={false}
            isBtn2={false}
            isProgressBar={false}
          />
          {/* Header Component */}

          {/* <div className={[styles.audience_wrapper, styles.is_drawer_open].join(" ")}> */}
          <div
            className={
              !selectedCourse?.courseId
                ? [styles.audience_wrapper, styles.course_inner_wrapper, ''].join(' ')
                : [styles.audience_wrapper, styles.is_drawer_open, ''].join(' ')
            }
          >
            {/* <SeverErrorComponent /> */}
            <div className={[styles.main_wrapper, ''].join(' ')}>
              <div className={styles.top_area_wrapper}>
                <div id="divParentOfFilter" className={styles.sort_by_wrapper}>
                  {/* <div className={styles.txt}>Filter Table</div> */}
                  <div className={[styles.icon_wrapper].join(' ')}>
                    <div className={styles.filter} onClick={() => setShowFilters(!showFilters)} />
                    {showFilters && (
                      <FilterComponent
                        isPills={true}
                        filterData={filterData}
                        col6={true}
                        handleFilterApply={handleFilterApplyNew}
                        handleFilterCancle={() => setShowFilters(false)}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={[
                    styles.search_main_wrapper,
                    search.length > 3 ? styles.active : '',
                  ].join(' ')}
                >
                  {/* Added class "outline" for search when user type border should highlighted */}
                  <div
                    className={[
                      styles.search_wrapper,
                      search.length > 0 ? styles.outline : '',
                    ].join(' ')}
                  >
                    <Hint options={predictedSearch} allowTabFill={true}>
                      <input
                        type="text"
                        placeholder="Search Course Name"
                        value={search}
                        onChange={(e) => handleSearchInput(e)}
                        onKeyPress={(e) => handleSearchInput(e)}
                        className={styles.search_input}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13 && search?.length < 3 && search?.length > 0) {
                            setNotificationData({
                              show: false,
                              type: 'WARNING',
                              title: 'Insufficient Search Input',
                              description: 'To search for any Course, enter 3 or more characters.',
                            });
                          }
                        }}
                      />
                    </Hint>
                    {search?.length === 0 && (
                      <div className={styles.icon_wrapper}>
                        <div className={styles.search} />
                      </div>
                    )}
                    {search?.length > 0 && (
                      <div className={styles.icon_wrapper} onClick={() => setSearch('')}>
                        <div className={styles.cross_icon} />
                      </div>
                    )}
                  </div>
                  {/* {search.length > 3 ? (
                    <div className={styles.dropdown_container}>
                      <div className={styles.list_wrapper}>
                        <div className={styles.list}>Nutrilite customers</div>
                        <div className={styles.list}>Selling Nutrilite products</div>
                        <div className={styles.list}>Contextual suggestive search</div>
                        <div className={styles.list}>Contextual suggestive search</div>
                        <div className={styles.list}>Contextual suggestive search</div>
                        <div className={styles.list}>Contextual suggestive search</div>
                      </div>
                    </div>
                  ) : null} */}
                </div>
                {/* NEW */}
                <div className={styles.toggle_section_wapper}>
                  <div className={styles.toggle_wrapper}>
                    <div
                      className={[
                        styles.toggle_section,
                        styles.card_radius,
                        isCardView ? styles.card_selected : '',
                      ].join(' ')}
                      onClick={() => toggleSwitch('cardView')}
                    >
                      <div className={styles.card_view} />
                    </div>
                    <div
                      className={[
                        styles.toggle_section,
                        styles.view_radius,
                        isListView ? styles.view_selected : '',
                      ].join(' ')}
                      onClick={() => toggleSwitch('listView')}
                    >
                      <div className={styles.list_view} />
                    </div>
                  </div>
                </div>
                {/* New toggle */}
                {/* {!selectedCourse && (
                  <div className={styles.toggle_wrapper}>
                    <div
                      className={[
                        styles.toggle_section,
                        styles.card_radius,
                        isCardView ? styles.card_selected : "",
                      ].join(" ")}
                      onClick={() => handleToggleSwitch("cardView")}
                    >
                      <div className={styles.card_view} />
                    </div>
                    <div
                      className={[
                        styles.toggle_section,
                        styles.view_radius,
                        isListView ? styles.view_selected : "",
                      ].join(" ")}
                      onClick={() => handleToggleSwitch("listView")}
                    >
                      <div className={styles.list_view} />
                    </div>
                  </div>
                )} */}
                <div
                  className={[styles.btn_wrapper, styles.btn_wrapper_xs].join(' ')}
                  onClick={() => {
                    // setSelectedCourse({redirectToWizard: true});
                    // setOpenNewCourseState(true);
                    openCourseWizard(0, 'course_description');
                  }}
                >
                  <div className={styles.btn}>
                    <div className={styles.add} />
                    <div className={styles.txt}>New Course</div>
                  </div>
                </div>
              </div>
              <div className={styles.inner_wrapper}>
                {/* Card View Started  */}
                {isCardView ? (
                  <div
                    className={[
                      selectedCourse && selectedCourse?.courseId
                        ? styles.card_wrapper_with_drawer
                        : styles.card_wrapper,
                      'bootstrap-wrapper',
                    ].join(' ')}
                  >
                    {isLoading ? (
                      <div className={styles.row_wrapper}>
                        <LoadingShimmer count={20} />
                      </div>
                    ) : (
                      <div className={styles.row_wrapper}>
                        {coursesUIData && coursesUIData.length > 0 ? (
                          coursesUIData.map((course, index) => {
                            const {courseId, course_state, bannerImage, title} = course;
                            return (
                              <div
                                className={[styles.card_holder, ''].join(' ')}
                                onClick={() => fetchSelectedCourse(courseId)}
                                key={index}
                                id={courseId}
                              >
                                <div className={[styles.card, styles.selected].join(' ')}>
                                  <div className={styles.card_top}>
                                    {selectedCourse?.courseId === courseId && (
                                      <div className={styles.view_details}>
                                        <div className={styles.vd_icon}></div>
                                      </div>
                                    )}
                                    <div
                                      className={[styles.img_wrapper, styles.empty_state].join(' ')}
                                    >
                                      {bannerImage ? (
                                        <img
                                          className={styles.img}
                                          src={bannerImage}
                                          alt="thumbnail"
                                        />
                                      ) : (
                                        <div
                                          className={[styles.img, styles.empty_state_img].join(' ')}
                                        ></div>
                                      )}
                                    </div>
                                  </div>
                                  <div className={styles.card_bottom}>
                                    <div
                                      className={[styles.status_wrapper, styles.archive_cd].join(
                                        ' ',
                                      )}
                                    >
                                      <div
                                        className={`${styles.status} ${
                                          styles[course?.course_state?.toLowerCase()]
                                        }`}
                                      >
                                        <div className={styles.status_txt}>
                                          {course_state || 'NA'}
                                        </div>
                                      </div>
                                      {course_state !== 'Draft' && (
                                        <div className={styles.text_wrapper}>
                                          {/* Please add other different calendar icon for different status */}
                                          <div
                                            className={
                                              course_state === 'Live'
                                                ? styles.calendar_icon
                                                : course_state === 'Offline'
                                                ? styles.offline_cal_icon
                                                : styles.archive_cal_icon
                                            }
                                          ></div>
                                          <div className={styles.date_text}>
                                            {course?.launch_date
                                              ? dateIsoToNormalDate(course?.launch_date)
                                              : 'NA'}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className={styles.description}>{title || 'NA'}</div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : isSearchFilterEmptyState ? (
                          <EmptyScreenComponent />
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : null}
                {/* Card View Ended */}

                {/* List View Started */}
                {isListView ? (
                  <div
                    className={[
                      styles.table_main_wrapper,
                      selectedCourse !== null ? styles.table_reduced_width : '',
                    ].join(' ')}
                  >
                    <div className={styles.table_container}>
                      <div className={styles.tbl_header}>
                        <div className={styles.tbl_head}>Thumbnail</div>
                        <div className={styles.tbl_head_wrapper}>
                          <div className={styles.tbl_head}>Title</div>
                          <div className={styles.tbl_head}>Launch date</div>
                          {/* <div className={styles.tbl_head}>
                            <div className={styles.active_user_icon}></div>
                            <span className={styles.user_txt}>Users</span>
                          </div> */}
                          <div className={styles.tbl_head}>Status</div>
                        </div>
                      </div>
                      {isLoading ? (
                        <div className={styles.tbl_body}>
                          <div className={[styles.tbl_row_wrapper].join(' ')}>
                            <LoadingShimmerList count={20} />
                          </div>
                        </div>
                      ) : (
                        <div className={styles.tbl_body}>
                          <div className={[styles.tbl_row_wrapper].join(' ')}>
                            {coursesUIData && coursesUIData.length > 0 ? (
                              coursesUIData.map((course, index) => {
                                const {courseId, course_state, bannerImage, title} = course;
                                return (
                                  // Please add selected state/class(selected) for row here
                                  <div
                                    className={[
                                      styles.tbl_row,
                                      selectedCourse?.courseId === courseId
                                        ? styles.selected
                                        : null,
                                    ].join(' ')}
                                    onClick={() => fetchSelectedCourse(courseId)}
                                    key={index}
                                    id={courseId}
                                  >
                                    <div className={styles.tbl_data}>
                                      <div className={styles.inner_data}>
                                        {bannerImage ? (
                                          <img
                                            className={styles.img}
                                            src={bannerImage}
                                            alt="selected course img"
                                          />
                                        ) : (
                                          <div className={[styles.empty_state_img].join(' ')}></div>
                                        )}
                                      </div>
                                    </div>
                                    <div className={styles.tbl_data_wrapper}>
                                      <div className={styles.tbl_data}>
                                        <div className={styles.inner_data}>
                                          <div className={[styles.txt, ''].join(' ')}>
                                            {title || 'NA'}
                                          </div>
                                        </div>
                                      </div>

                                      <div className={styles.tbl_data}>
                                        <div className={styles.inner_data}>
                                          {course_state === 'Draft' && (
                                            <div className={[styles.txt].join(' ')}>
                                              <div>
                                                {course?.launch_date
                                                  ? dateIsoToTime(course?.launch_date)
                                                  : 'NA'}
                                              </div>
                                              {/* For Future use, right now commented */}
                                              {/* <div>
                                                {course?.launch_date
                                                  ? dateIsoToDate(
                                                      course?.launch_date
                                                    )
                                                  : "NA"}
                                              </div> */}
                                            </div>
                                          )}
                                          {course_state !== 'Draft' && (
                                            <div className={[styles.txt].join(' ')}>
                                              <div>
                                                {course?.launch_date
                                                  ? dateIsoToTime(course?.launch_date)
                                                  : 'NA'}
                                              </div>
                                              <div>
                                                {course?.launch_date
                                                  ? dateIsoToDate(course?.launch_date)
                                                  : 'NA'}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      {/* <div className={styles.tbl_data}>
                                      <div className={styles.inner_data}>
                                        <div className={[styles.txt].join(" ")}>
                                          {" "}
                                          <span className={styles.txt_underline}>990</span>
                                        </div>
                                      </div>
                                    </div> */}

                                      <div className={styles.tbl_data}>
                                        <div className={styles.inner_data}>
                                          <div
                                            className={`${styles.status} ${
                                              styles[course?.course_state?.toLowerCase()]
                                            }`}
                                          >
                                            <div className={styles.status_txt}>
                                              {course_state || 'NA'}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : isSearchFilterEmptyState ? (
                              <EmptyScreenComponent />
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {/* List View Ended */}
                {/* Course Details Drawer Start */}
                {selectedCourse && !selectedCourse.redirectToWizard && (
                  <div className={[styles.course_drawer_container, ''].join(' ')}>
                    <div
                      className={styles.custom_overlay}
                      style={drawerOverlayCalculatedHeight({drawerHeight: drawerHeight})}
                    ></div>
                    <div
                      className={styles.course_drawer_wrapper}
                      style={drawerCalculatedHeight({drawerHeight: drawerHeight})}
                    >
                      <div
                        className={styles.mob_bar}
                        draggable
                        onTouchMove={(e) => {
                          pullbox({e: e, setDrawerHeight: setDrawerHeight});
                        }}
                        onTouchEnd={() => {
                          mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
                        }}
                        onClick={() => {
                          closeDrawer();
                        }}
                      >
                        <div className={styles.line_bar}></div>
                      </div>

                      {/* middle body */}
                      {!isDrawerAPIFailed ? (
                        <div className={styles.middle_container}>
                          {/* header */}
                          <div className={styles.header}>
                            <div className={styles.title}>Course Details</div>
                            <div
                              className={styles.close_wrapper}
                              onClick={() => {
                                closeDrawer();
                              }}
                            >
                              <div className={styles.close_icon} />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              selectedCourse?.courseState?.toString()?.toLowerCase() === 'draft'
                                ? handleOnClickNewCourse('main')
                                : handleOnClickNewCourse('edit');
                            }}
                            className={
                              selectedCourse?.courseState === 'Archive'
                                ? styles.edit_course_btn_wrapper_disabled
                                : styles.edit_course_btn_wrapper
                            }
                          >
                            <div className={styles.txt}>
                              {selectedCourse?.courseState?.toString()?.toLowerCase() === 'draft'
                                ? 'Go To Course Wizard'
                                : 'Edit Course'}
                            </div>
                            <div className={styles.edit_icon}></div>
                          </div>
                          {/* img wrapper */}
                          <div className={[styles.img_wrapper, styles.empty_state].join(' ')}>
                            {selectedCourse?.thumbnail ? (
                              <img
                                className={styles.img}
                                src={selectedCourse?.thumbnail}
                                alt="selectedCourse thumbnail"
                              />
                            ) : (
                              <div className={[styles.img, styles.empty_state_img].join(' ')}></div>
                            )}
                          </div>
                          {/* img wrapper */}
                          <div className={styles.course_title}>
                            {selectedCourse?.englishTitle || selectedCourse?.chineseTitle}
                          </div>
                          <div className={styles.description_wrapper}>
                            <div className={styles.label}>Description</div>
                            <div className={styles.value}>
                              {selectedCourse?.englishDescription ||
                                selectedCourse?.chineseDescription}
                            </div>
                          </div>
                          <div className={styles.upload_details_wrapper}>
                            <div className={styles.upload_info}>
                              <div className={styles.title}>Created By</div>
                              <div className={styles.info_text}>{selectedCourse?.createdBy}</div>
                            </div>
                            <div className={styles.upload_info}>
                              <div className={styles.title}>{`${
                                selectedCourse.courseState === 'Offline'
                                  ? 'Offline'
                                  : selectedCourse.courseState === 'Archive'
                                  ? 'Archived'
                                  : 'Launch'
                              } Date`}</div>
                              <div className={styles.info_text}>
                                {selectedCourse?.launchDate ? (
                                  <div>
                                    <div>{dateIsoToTime(selectedCourse?.launchDate)}</div>
                                    <div>{dateIsoToDate(selectedCourse?.launchDate)}</div>
                                  </div>
                                ) : (
                                  <div>NA</div>
                                )}
                              </div>
                            </div>
                            <div className={styles.upload_info}>
                              <div className={styles.title}>Last Updated</div>
                              <div className={styles.info_text}>
                                <div>
                                  {selectedCourse?.updatedBy && (
                                    <div>{selectedCourse?.updatedBy}</div>
                                  )}
                                  {selectedCourse?.updatedAt && (
                                    <div>{dateIsoToTime(selectedCourse?.updatedAt)}</div>
                                  )}
                                  {selectedCourse?.updatedAt && (
                                    <div>{dateIsoToDate(selectedCourse?.updatedAt)}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.publish_title}>Publish Settings</div>
                          <div
                            className={[
                              styles.course_status,
                              // styles.live,
                              // styles.offline,
                              // styles.archive,
                              // styles.draft,
                              styles[selectedCourse?.courseState?.toString().toLowerCase()],
                            ].join(' ')}
                          >
                            <div className={styles.txt_wrapper}>
                              <div className={styles.txt}>This Course is</div>
                              <div className={styles.status_txt}>
                                {' '}
                                {selectedCourse?.courseState === 'Archive'
                                  ? 'Archived'
                                  : selectedCourse?.courseState}{' '}
                              </div>
                            </div>
                            <div
                              className={styles.status_btn}
                              onClick={() => {
                                if (
                                  selectedCourse?.courseState?.toString()?.toLowerCase() === 'draft'
                                ) {
                                  handleDeleteModal();
                                } else if (
                                  selectedCourse?.courseState?.toString()?.toLowerCase() === 'live'
                                ) {
                                  changeCourseStatus(
                                    selectedCourse?.courseId,
                                    'offline',
                                    selectedCourse,
                                  );
                                } else if (
                                  selectedCourse?.courseState?.toString()?.toLowerCase() ===
                                    'offline' &&
                                  allCheckFunc(selectedCourse)
                                ) {
                                  changeCourseStatus(
                                    selectedCourse?.courseId,
                                    'live',
                                    selectedCourse,
                                  );
                                } else if (
                                  selectedCourse?.courseState?.toString()?.toLowerCase() ===
                                    'offline' &&
                                  !allCheckFunc(selectedCourse)
                                ) {
                                  setNotificationData({
                                    show: true,
                                    type: 'FAILURE',
                                    title: 'Missing information ',
                                    description:
                                      'Course Name cannot be made Live as there is some missing information. Please fill in all the mandatory fields in ‘Edit Course section before publishing.',
                                  });
                                }
                              }}
                            >
                              <div className={styles.text}>{getCourseButtonStatus()}</div>
                            </div>
                          </div>
                          <div className={styles.adapt_file_wrapper}>
                            <div className={styles.txt}>Adapt file</div>
                            <div className={styles.file_name}>
                              {selectedCourse?.adaptFileName || 'NA'}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.middle_container}>
                          {/* header */}
                          <div className={styles.header}>
                            <div className={styles.title}>Course Details</div>
                            <div
                              className={styles.close_wrapper}
                              onClick={() => setSelectedCourse(null)}
                            >
                              <div className={styles.close_icon} />
                            </div>
                          </div>
                          <DrawerFailScreen
                            drawerId={drawerIdForBrokenLink}
                            drawerTryAgainCall={(item) => {
                              fetchSelectedCourse(item);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* Course Details Drawer End*/}
              </div>
            </div>
          </div>
        </div>
      )}
      {
        //showCreateNewCoursesWizard && newCourseWizardState.courseWizardOpen && (
        // <CourseWizardComponent
        //   ApiData={selectedCourse}
        //   fetchCoursesList={() => fetchCoursesList()}
        //   fetchSelectedCourse={(id) => fetchSelectedCourse(id)}
        //   closeCourseWizard={() => {
        //     setCreateNewCoursesWizard(false);
        //     // setThumbnailId();
        //     setNewCourseData(null);
        //     // fetchCoursesList();
        //     setOpenNewCourseState(false);
        //     setNewThumbnailId('');
        //     setFilterData(_initialFilterData);
        //     setNewCourseWizardState({
        //       courseWizardOpen: false,
        //       courseWizardStateOpen: '',
        //       section: '',
        //     });
        //   }}
        //   setOpenImageLib={() => {
        //     setOpenImageLib();
        //     setCreateNewCoursesWizard(false);
        //   }}
        // />
        // )
      }
      {notificationData?.show && <Notification />}
      {isDelete && (
        <DeleteModal
          closeDeleteModal={() => {
            handleDeleteModal();
          }}
          saveDeleteChanges={() => {
            handleDeleteModal();
          }}
          deleteCall={() => deleteCourse()}
          name={
            selectedCourse?.englishTitle
              ? selectedCourse.englishTitle
              : selectedCourse?.chineseTitle
              ? selectedCourse.chineseTitle
              : ''
          }
        />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  notificationData: state && state.uiState && state.uiState.notificationData,
  newCourseWizardState: state && state.courseState && state.courseState.newCourseWizardState,
  courseMode: state && state.uiState && state.uiState.courseMode,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setNewCourseWizardState: (options) => dispatch(setNewCourseWizardState(options)),
  setNewCourseData: (options) => dispatch(setNewCourseData(options)),
  setCourseMode: (options) => dispatch(setCourseMode(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setMainImageBackup: (options) => dispatch(setMainImageBackup(options)),
  setReplacedImage: (options) => dispatch(setReplacedImage(options)),
  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),
  setImageData: (options) => dispatch(setImageData(options)),
  setCroppedImageData: (options) => dispatch(setCroppedImageData(options)),
  setCroppedImageData169: (options) => dispatch(setCroppedImageData169(options)),
  setCroppedImageData11: (options) => dispatch(setCroppedImageData11(options)),
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setNewThumbnailId: (options) => dispatch(setNewThumbnailId(options)),
  setThumbnailImage169: (options) => dispatch(setThumbnailImage169(options)),
  setBlockNavigation: (options) => dispatch(setBlockNavigation(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CourseManagementComponent));
