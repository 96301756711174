import {makeRequest} from '../APIsUtils/httpsUtils';
import {makeAnagram} from './CSTLandingPage';

export const handleCSTCreatModelCloseOpen = (props) => {
  const {
    setCreateCSTOpen,
    isCreateCSTOpen,
    setSelectTypeText,
    setOpenSelectTypeDropdown,
    setMouseHoverState,
    setEnglishTranslationCreateCST,
    setChineseTranslationCreateCST,
    setNameErrorStateEnglishCreate,
    setCreateMaxCharErrorMessageEnglish,
    setCreateEnglishTranslationDuplicetName,
    setNameErrorStateChineseCreate,
    setCreateMaxCharErrorMessageChinese,
    setCreateChineseTranslationEmpty,
    setCreateEnglishTranslationEmpty,
  } = props;

  setCreateCSTOpen(!isCreateCSTOpen);
  //console.log('>>>> handleCSTCreatModelCloseOpen >>>> props', props);
  setSelectTypeText('Select type');
  setOpenSelectTypeDropdown(false);
  setMouseHoverState('Select type');
  setEnglishTranslationCreateCST('');
  setChineseTranslationCreateCST('');
  setNameErrorStateEnglishCreate(false);
  setCreateMaxCharErrorMessageEnglish(false);
  setCreateEnglishTranslationDuplicetName(false);
  setNameErrorStateChineseCreate(false);
  setCreateMaxCharErrorMessageChinese(false);
  setCreateEnglishTranslationEmpty(false);
  setCreateChineseTranslationEmpty(false);
};

export const handleCreateCSTSelectTypeDropdown = (props) => {
  const {setOpenSelectTypeDropdown, openSelectTypeDropdown} = props;
  setOpenSelectTypeDropdown(!openSelectTypeDropdown);
  //console.log('>>>> handleCreateCSTSelectTypeDropdown >>>> props', props);
};

export const handleEnglishTranslationCreateCST = (props) => {
  const {text = '', setEnglishTranslationCreateCST, regexNameChinese, regexNameChinese2} = props;
  // console.log('>>>> >>>> handleEnglishTranslationCreateCST >>>> props', props);
  if (text[0] !== ' ') {
    setEnglishTranslationCreateCST(text?.replaceAll(/\s+/g, ' '));
  }
};

export const handleChineseTranslationCreateCST = (props) => {
  const {text = '', setChineseTranslationCreateCST, regexNameChinese, regexNameChinese2} = props;
  // console.log('>>>> >>>> handleChineseTranslationCreateCST >>>> props', props);
  if (text[0] !== ' ') {
    setChineseTranslationCreateCST(text?.replaceAll(/\s+/g, ' '));
  }
};

export const handleCreateNewCSTButtonSaveChanges = async (props) => {
  const {
    selectTypeText = '',
    englishTranslationCreateCST = '',
    chineseTranslationCreateCST,
    setCategorgyList,
    setSearchFilterEmptyState,
    setCategorgyListCopy,
    setErrorStateTemplate,
    isOnline,
    setCreateCSTOpen,
    isCreateCSTOpen,
    setSelectTypeText,
    setOpenSelectTypeDropdown,
    setMouseHoverState,
    setEnglishTranslationCreateCST,
    setChineseTranslationCreateCST,
    nameErrorStateEnglishCreate,
    setNameErrorStateEnglishCreate,
    setCreateEnglishTranslationDuplicetName,
    CreateEnglishTranslationDuplicetName,
    nameErrorStateChineseCreate,
    setNameErrorStateChineseCreate,
    setCreateMaxCharErrorMessageEnglish,
    setCreateMaxCharErrorMessageChinese,
    setNotificationData,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    fetchCategorySkillsTags,
    setCreateChineseTranslationEmpty,
    setCreateEnglishTranslationEmpty,
    filterData,
    setFilterData,
    setIsLoading,
  } = props;
  // console.log('>>>> >>>> handleCreateNewCSTButtonSaveChanges >>>> props', props);
  let result = {
    englishTranslation: englishTranslationCreateCST?.trim(),
    chineseTranslation: chineseTranslationCreateCST?.trim(),
  };
  const endpoint = `/admin/api/v1/csts?type=${selectTypeText}`;
  if (
    !nameErrorStateEnglishCreate &&
    !CreateEnglishTranslationDuplicetName &&
    !nameErrorStateChineseCreate
  ) {
    let data = await makeRequest({
      method: 'POST',
      endpoint,
      body: result,
    });
    if (data != 'ERR_NETWORK' && data != 'ERR_BAD_REQUEST' && data) {
      handleCSTCreatModelCloseOpen({
        setCreateCSTOpen,
        isCreateCSTOpen,
        setSelectTypeText,
        setOpenSelectTypeDropdown,
        setMouseHoverState,
        setEnglishTranslationCreateCST,
        setChineseTranslationCreateCST,
        setNameErrorStateEnglishCreate,
        setCreateMaxCharErrorMessageEnglish,
        setCreateEnglishTranslationDuplicetName,
        setNameErrorStateChineseCreate,
        setCreateMaxCharErrorMessageChinese,
        setCreateChineseTranslationEmpty,
        setCreateEnglishTranslationEmpty,
      });
      setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: `New ${selectTypeText} created`,
        description: `${englishTranslationCreateCST} created.`,
      });
      setDashboardNotificationCountApi();
      setNotificationListApi();
      await fetchCategorySkillsTags({
        setCategorgyList,
        setSearchFilterEmptyState,
        setCategorgyListCopy,
        setErrorStateTemplate,
        isOnline,
      });
      setFilterData(filterData);
    } else if (data != 'ERR_NETWORK' || data != 'ERR_BAD_REQUEST') {
      handleCSTCreatModelCloseOpen({
        setCreateCSTOpen,
        isCreateCSTOpen,
        setSelectTypeText,
        setOpenSelectTypeDropdown,
        setMouseHoverState,
        setEnglishTranslationCreateCST,
        setChineseTranslationCreateCST,
        setNameErrorStateEnglishCreate,
        setCreateMaxCharErrorMessageEnglish,
        setCreateEnglishTranslationDuplicetName,
        setNameErrorStateChineseCreate,
        setCreateMaxCharErrorMessageChinese,
        setCreateChineseTranslationEmpty,
        setCreateEnglishTranslationEmpty,
      });
      setNotificationData({
        show: true,
        type: 'WARNING',
        title: `CST Creation Failed`,
        description: `Please Try Again`,
      });
    }
  }
};

export const handleCreateErrorStatesEnglish = (props) => {
  const {
    e,
    setNameErrorStateEnglishCreate,
    regexNameChinese2,
    setCreateMaxCharErrorMessageEnglish,
    regexNameChinese,
    list,
    drawerDetails,
    setCreateEnglishTranslationDuplicetName,
    selectTypeText,
    setEnglishTranslationCreateCSTInvalidCharacter,
  } = props;
  // console.log('>>>> >>>> handleCreateErrorStatesEnglish >>>> props', props);

  let errorString = makeAnagram({
    a: e,
    b: e?.toString()?.replaceAll(regexNameChinese, ''),
  });
  setEnglishTranslationCreateCSTInvalidCharacter(errorString);

  let resultCategory = [];
  let resultSill = [];
  let resultTag = [];

  if (e?.toString()?.replaceAll(regexNameChinese2, '')) {
    setNameErrorStateEnglishCreate(true);
  } else {
    setNameErrorStateEnglishCreate(false);
  }
  if (e?.toString()?.replaceAll(regexNameChinese, '')?.length == 30) {
    setCreateMaxCharErrorMessageEnglish(true);
  } else {
    setCreateMaxCharErrorMessageEnglish(false);
  }
  // console.log('>>>> >>>> handleCreateErrorStatesEnglish >>>> list', list, '>>>> ', selectTypeText);

  list?.find((item) => {
    if (item?.type === 'Category' && selectTypeText === 'Category') {
      resultCategory?.push(item);
    } else if (item?.type === 'Skill' && selectTypeText === 'Skill') {
      resultSill?.push(item);
    } else if (item?.type === 'Tag' && selectTypeText === 'Tag') {
      resultTag?.push(item);
    }
  });

  if (selectTypeText === 'Category') {
    if (
      resultCategory?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase() == e?.toLowerCase()?.trim();
      })
    ) {
      setCreateEnglishTranslationDuplicetName(true);
    } else {
      setCreateEnglishTranslationDuplicetName(false);
    }
  } else if (selectTypeText === 'Skill') {
    if (
      resultSill?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase() == e?.toLowerCase()?.trim();
      })
    ) {
      setCreateEnglishTranslationDuplicetName(true);
    } else {
      setCreateEnglishTranslationDuplicetName(false);
    }
  } else if (selectTypeText === 'Tag') {
    if (
      resultTag?.find((singel) => {
        return singel?.englishTranslation?.toLowerCase() == e?.toLowerCase()?.trim();
      })
    ) {
      setCreateEnglishTranslationDuplicetName(true);
    } else {
      setCreateEnglishTranslationDuplicetName(false);
    }
  }
};

export const handleCreateErrorStatesChinese = (props) => {
  const {
    e,
    setCreateMaxCharErrorMessageChinese,
    regexNameChinese,
    setNameErrorStateChineseCreate,
    regexNameChinese2,
    setChineseTranslationCreateCSTInvalidCharacter,
  } = props;
  // console.log('>>>> >>>> handleCreateErrorStatesChinese >>>> props', props);

  let errorString = makeAnagram({
    a: e,
    b: e?.toString()?.replaceAll(regexNameChinese, ''),
  });
  setChineseTranslationCreateCSTInvalidCharacter(errorString);

  if (e?.toString()?.replaceAll(regexNameChinese, '')?.length == 30) {
    setCreateMaxCharErrorMessageChinese(true);
  } else {
    setCreateMaxCharErrorMessageChinese(false);
  }
  if (e?.toString()?.replaceAll(regexNameChinese2, '')) {
    setNameErrorStateChineseCreate(true);
  } else {
    setNameErrorStateChineseCreate(false);
  }
};

export const handleCreateButtonState = (props) => {
  const {
    englishTranslationCreateCST,
    chineseTranslationCreateCST,
    isCreateEnglishTranslationEmpty,
    nameErrorStateChineseCreate,
    CreateEnglishTranslationDuplicetName,
    isCreateChineseTranslationEmpty,
    nameErrorStateEnglishCreate,
  } = props;
  //console.log('>>>> >>>> handleCreateButtonState >>>> props', props);

  if (
    englishTranslationCreateCST?.length > 0 &&
    chineseTranslationCreateCST?.length > 0 &&
    !isCreateEnglishTranslationEmpty &&
    !CreateEnglishTranslationDuplicetName &&
    !nameErrorStateEnglishCreate &&
    !nameErrorStateChineseCreate &&
    !isCreateChineseTranslationEmpty
  ) {
    return true;
  } else {
    return false;
  }
};
