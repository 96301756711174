import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from '../CourseWizard.module.scss';
import AdaptFileModalComponent from '../AdaptFileModal';
import NoteCardComponent from '../../../shared/noteCard';
import CardNewComponent from '../../../shared/cardNewComponent';
import CheckboxNew from '../../../shared/checkboxNew';
import Pills from '../../../shared/pills';
import LoadingShimmerCustom from '../../../shared/LoadingShimmerCustom';
const CreateWizard = ({
  mainState,
  nextState,
  openAdaptFileModal,
  adaptFileState,
  fileDataTemp,
  data,
  setOpenAdaptFileModal,
  openInNewTab,
  setFileData,
  regexNameChinese,
  handleDataFill,
  addUserGroupList,
  deleteUserGroupList,
  disableStateUserManagement,
  handleDeleteUserTargetingGroups,
  handleDeleteUserGroups,
  disableClickBanner,
  handleFileUpload,
  adaptFileButtonState,
  uploadingFileButton,
  fileData,
  getEnableSaveAndExit,
  isCourseIDFetched,
  mainImageBackup,
  setImageData,
  disableImageLib,
  setThumbnailImage169,
  setCourseMode,
  setNewCourseData,
  setNewCourseWizardState,
  uploadImageFromDesktop,
  uploadNewImagePath,
  setNewThumbnailImageData,
  setNewImageData,
  setMainImageBackup,
  setCroppedImageData,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setSelectedImage_16_9_Backup,
  setSelectedImage_1_1_Backup,
  thumbnailImage169,
  setPreviewAdaptFileFlag,
  previewAdaptFileCreateNewCourse,
  previewData,
  categories,
  categoryData,
  setCategoryData,
  skills,
  skillsRef,
  setPillsDataSkills,
  pillsDataSkills,
  pillsAddAudienceGroup,
  pillsDataKeyword,
  filterListById,
  handleAddUserTargetingGroups,
  setNotificationData,
  newCourseID,
  addUserGroupListCopy,
  publishButtonAfterPreview,
  keyword,
  keywordsRef,
  setPillsDataKeyword,
  SearchListComponent,
  isLoading,
}) => {
  const router = useHistory();
  const {query = {}} = router.location;

  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(<LoadingShimmerCustom className={[styles.card_holder_ugm_section].join(' ')} />);
    }
    return content;
  };
  return (
    <>
      {mainState && (
        <div className={[styles.inner_wrapper, styles.padding_class, ''].join(' ')}>
          <div className={styles.row_wrapper}>
            {openAdaptFileModal && (
              <AdaptFileModalComponent
                unzipState={adaptFileState}
                closeModal={() => {
                  setOpenAdaptFileModal(false);
                  //fetchSelectedCourse(data?.courseId);
                }}
                openPreviewFile={() => {
                  openInNewTab();
                  //fetchSelectedCourse(data?.courseId);
                  // updateCourseDetails(data?.courseId,{adaptFileName:fileData?.name,courseState:data?.courseState
                  //   //  ,version :versionNum
                  // })
                  setFileData(fileDataTemp);
                }}
              />
            )}
            {/* Info container block */}
            <div className={styles.info_container}>
              <div
                className={[styles.inr_wrapper, styles.margin_zero, 'col-md-12 col-lg-6'].join(' ')}
              >
                <div className={[styles.title_wrapper, styles.margin_zero].join(' ')}>
                  <div className={styles.title}>Course Info</div>
                  <div className={[styles.txt, styles.margin_zero].join(' ')}>
                    English Course Title and Description are required to save a course in a draft
                    state. All fields are mandatory (except Achievements, User Group Targeting,
                    Skills You Will Gain and Keyword Tagging) to publish a course.
                  </div>
                </div>
              </div>
            </div>
            {/* Info container block */}
            <div className={[styles.info_container].join(' ')}>
              <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>English Course Info</div>
                  {/* <div className={styles.txt}>
                      Nunc at neque consectetur odio aenean mattis in. Posuere vel et vestibulum
                      pharetra posuere tempus.
                    </div> */}
                </div>
                <div
                  className={[
                    styles.form_field,
                    styles.info_wrapper,
                    data?.englishTitle?.length < 3 && data?.englishTitle?.length != 0
                      ? styles.error_wrapper
                      : ' ',
                  ].join(' ')}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Course Title</div>
                    <div className={styles.tag_text}>Max 30 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.input} />
                    ) : (
                      <input
                        type={'text'}
                        placeholder={'Enter Course Title'}
                        className={styles.input}
                        defaultValue={
                          data && data?.englishTitle?.toString()?.replaceAll(regexNameChinese, '')
                        }
                        value={
                          data && data?.englishTitle?.toString()?.replaceAll(regexNameChinese, '')
                        }
                        name="englishTitle"
                        maxLength="30"
                        //pattern={regexNameChinese}
                        onChange={(e) => {
                          e.target.value.length === 0 && handleDataFill(e.target.name, '');
                          handleDataFill(
                            e.target.name,
                            e.target.value?.toString()?.replaceAll(regexNameChinese, ''),
                          );
                        }}
                      />
                    )}

                    {data?.englishTitle?.length < 3 && data?.englishTitle?.length != 0 ? (
                      <div className={styles.error_icon_red}></div>
                    ) : null}
                  </div>
                  {data?.englishTitle?.length < 3 && data?.englishTitle?.length != 0 ? (
                    <div className={styles.error_message}>
                      {/* Need 3 or more characters to Create New Course */}
                      Need 3 or more characters. Valid characters include A-z, 0-9, ., :, ?, !, -, “
                      ”, ‘’
                    </div>
                  ) : null}
                </div>
                <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Description</div>
                    <div className={styles.tag_text}>Max 500 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.textarea} />
                    ) : (
                      <textarea
                        type={'text'}
                        name="englishDescription"
                        maxLength="500"
                        placeholder={'Enter Course Description'}
                        defaultValue={data && data?.englishDescription}
                        value={data && data?.englishDescription}
                        className={styles.textarea}
                        onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                        // onBlur={() => callCreateCourseAPI()}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Course Author/ Speaker Name (English)</div>
                  <div className={styles.txt}>
                    Enter the Author or a Speaker Name of the particular course. This information is
                    visible to the Front End ABO besides the title of ‘Taught by’.
                  </div>
                </div>
                <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.input} />
                    ) : (
                      <input
                        type={'text'}
                        maxLength="30"
                        name="englishSpeaker"
                        placeholder={'Enter Course Author/Speaker Name'}
                        defaultValue={(data && data?.author) || data?.englishSpeaker}
                        value={(data && data?.author) || data?.englishSpeaker}
                        className={styles.input}
                        onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={[styles.inr_wrapper, 'col-md-12 col-lg-6'].join(' ')}>
                <div className={[styles.title_wrapper, styles.invisible_tt].join(' ')}>
                  <div className={styles.title}>Chinese Course Info</div>
                  {/* <div className={styles.txt}>有关课程的任何信息都显示在此处</div> */}
                </div>
                <div
                  className={[
                    styles.form_field,
                    styles.info_wrapper,
                    data?.chineseTitle?.length < 3 && data?.chineseTitle?.length != 0
                      ? styles.error_wrapper
                      : ' ',
                  ].join(' ')}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Course Title</div>
                    <div className={styles.tag_text}>Max 30 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.input} />
                    ) : (
                      <input
                        id="inputChineseTitle"
                        type={'text'}
                        maxLength="30"
                        placeholder={'输入课程名称'}
                        name="chineseTitle"
                        defaultValue={
                          data && data?.chineseTitle?.toString()?.replaceAll(regexNameChinese, '')
                        }
                        value={
                          data && data?.chineseTitle?.toString()?.replaceAll(regexNameChinese, '')
                        }
                        className={styles.input}
                        // pattern={regexNameChinese}
                        onChange={(e) => {
                          e.target.value.length === 0 && handleDataFill(e.target.name, '');
                          // TODO: Need to fix below regex for Chinese characters
                          handleDataFill(
                            e.target.name,
                            e.target.value?.toString()?.replaceAll(regexNameChinese, ''),
                          );
                          // handleDataFill(
                          //   e.target.name,
                          //   e.target.value
                          //     ?.toString()
                          // );
                        }}
                      />
                    )}
                    {data?.chineseTitle?.length < 3 && data?.chineseTitle?.length != 0 ? (
                      <div className={styles.error_icon_red}></div>
                    ) : null}
                  </div>
                  {data?.chineseTitle?.length < 3 && data?.chineseTitle?.length != 0 ? (
                    <div className={styles.error_message}>
                      {/* Need 3 or more characters to Create New Course */}
                      Need 3 or more characters. Valid characters include A-z, 0-9, ., :, ?, !, -, “
                      ”, ‘’
                    </div>
                  ) : null}
                </div>
                <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Description</div>
                    <div className={styles.tag_text}>Max 500 characters</div>
                  </div>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.textarea} />
                    ) : (
                      <textarea
                        type={'text'}
                        maxLength="500"
                        name="chineseDescription"
                        placeholder={'输入课程描述'}
                        defaultValue={data && data?.chineseDescription}
                        value={data && data?.chineseDescription}
                        className={styles.textarea}
                        onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Course Author/ Speaker Name (Chinese)</div>
                  <div className={styles.txt}>
                    Enter the Author or a Speaker Name of the particular course. This information is
                    visible to the Front End ABO besides the title of ‘Taught by’.{' '}
                  </div>
                </div>
                <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  <div className={styles.input_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.input} />
                    ) : (
                      <input
                        type={'text'}
                        name="chineseSpeaker"
                        maxLength="30"
                        placeholder={'输入课程作者/演讲者姓名'}
                        defaultValue={data && data?.chineseSpeaker}
                        value={data && data?.chineseSpeaker}
                        className={styles.input}
                        onChange={(e) => handleDataFill(e.target.name, e.target.value)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Info container 2nd block */}
            <div className={styles.info_container}>
              <div
                className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(
                  ' ',
                )}
              >
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>User Group Targeting</div>
                  <div className={styles.txt}>
                    Target this course to the User Groups mentioned below. If you do not want this
                    course to be accessible to a particular audience group(s), you can remove them
                    from the course accesibility list. You can specify the Course type by selecting
                    from the options given below for each User Group.{' '}
                  </div>
                </div>
                <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  {
                    <>
                      {addUserGroupList?.length == 0 && deleteUserGroupList?.length > 0 && (
                        <div className={styles.note_wrapper}>
                          <NoteCardComponent />
                        </div>
                      )}
                      <div className={styles.card_list_wrapper}>
                        {addUserGroupList?.length > 0 && (
                          <div className={styles.title}>User Groups with Course Access</div>
                        )}
                        <div className={styles.card_wrapper}>
                          {!isLoading &&
                            addUserGroupList?.map((element, index) => {
                              return (
                                <CardNewComponent
                                  key={index}
                                  ugCourseTitle={element?.title}
                                  id={element?.id || element?.audGrpId}
                                  isDisabled={disableStateUserManagement}
                                  onHandleClickRadio={(ele, id) => {
                                    handleDeleteUserTargetingGroups(
                                      {
                                        courseType: ele,
                                        id,
                                        title: element?.title,
                                        audienceGrpDescription:
                                          element?.audienceGrpDescription || element.description,
                                      },
                                      addUserGroupList,
                                    );
                                  }}
                                  courseType={element?.courseType}
                                  onButtonClick={() => {
                                    handleDeleteUserGroups({
                                      courseType: element?.courseType,
                                      id: element?.id || element?.audGrpId,
                                      title: element?.title,
                                      audienceGrpDescription:
                                        element?.audienceGrpDescription || element.description,
                                    });
                                  }}
                                  disableClickBanner={() => {
                                    disableClickBanner();
                                  }}
                                  bonusIcon={true}
                                  audienceGrpDescription={element?.audienceGrpDescription}
                                />
                              );
                            })}
                          {isLoading ? getShimmerList(5) : null}
                        </div>
                      </div>
                    </>
                  }
                  {
                    <>
                      <div className={styles.card_list_wrapper}>
                        {deleteUserGroupList?.length > 0 && (
                          <div className={styles.title}>User Groups without Course Access</div>
                        )}
                        <div className={styles.card_wrapper}>
                          {!isLoading &&
                            deleteUserGroupList?.map((element, index) => {
                              return (
                                <CardNewComponent
                                  key={index}
                                  ugCourseTitle={element?.title}
                                  withoutCourses={true}
                                  onHandleClickRadio={(ele) => {
                                    handleAddUserTargetingGroups({
                                      courseType: ele,
                                      id: element?.id,
                                    }),
                                      deleteUserGroupList;
                                  }}
                                  courseType={element?.courseType}
                                  id={element?.id || element?.audGrpId}
                                  isDisabled={disableStateUserManagement}
                                  onButtonClick={() => {
                                    handleAddUserTargetingGroups({
                                      courseType: 'General visibility',
                                      id: element?.id || element?.audGrpId,
                                      title: element?.title,
                                      audienceGrpDescription:
                                        element?.audienceGrpDescription || element.description,
                                    });
                                  }}
                                  disableClickBanner={() => {
                                    disableClickBanner();
                                  }}
                                  bonusIcon={true}
                                  audienceGrpDescription={element?.audienceGrpDescription}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </>
                  }
                </div>
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Achievements</div>
                  <div className={styles.txt}>
                    Add a certificate to a course to reward the user for completion. They will get
                    an automatically generated certificate with the Course Name, their Name, and
                    Date. Certificate addition to a course is only possible during creation of a
                    course. It cannot be added or removed later.
                  </div>
                </div>
                <div className={styles.certificate_wrapper}>
                  <div className={styles.title}>Certificate</div>
                  {isLoading ? (
                    <div className={styles.checkbox_container}>
                      {' '}
                      <div className={styles.outerDiv}>
                        {' '}
                        <LoadingShimmerCustom className={styles.achievement_section} />
                      </div>{' '}
                      <div className={styles.checkbox_note}>
                        ABOs recieve a Certificate for completing this course.
                      </div>{' '}
                    </div>
                  ) : (
                    <div className={styles.checkbox_container}>
                      <CheckboxNew
                        isChecked={data?.isCertificateAvailable}
                        handleCheckClick={() => {
                          data && data?.isCertificateAvailable
                            ? handleDataFill('isCertificateAvailable', Boolean(false))
                            : handleDataFill('isCertificateAvailable', Boolean(true));
                        }}
                      />
                      <div className={styles.checkbox_note}>
                        ABOs recieve a Certificate for completing this course.
                      </div>
                    </div>
                  )}
                </div>
                {/* Course runtime / Duration  */}
              </div>

              <div
                className={[
                  styles.inr_wrapper,
                  styles.thumbnail_wrapper,
                  'col-md-12 col-lg-6',
                ].join(' ')}
              >
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Course Content</div>

                  <div className={styles.txt}>
                    Upload an Adapt file of the course from your local device and Preview it by
                    clicking on ‘Preview Adapt file’ on top of the wizard.
                  </div>
                </div>
                <input
                  type={'file'}
                  // accept=".zip,.rar,.7z"
                  accept=".zip"
                  onChange={(event) => {
                    handleFileUpload(event, data?.id || data?.courseId);
                  }}
                  hidden={true}
                  multiple={false}
                  id={'uploadFile'}
                  disabled={adaptFileButtonState || uploadingFileButton}
                />
                <label
                  htmlFor="uploadFile"
                  style={{
                    display: 'block',
                    width: '100%',
                    marginBottom: '0',
                  }}
                  //  style={{pointerEvents: !isCourseIDFetched ? 'none' : ''}}
                >
                  <div
                    className={
                      uploadingFileButton
                        ? [styles.btn_wrapper_uaf, styles.inprogress_state].join(' ')
                        : fileData?.name ||
                          getEnableSaveAndExit({
                            englishTitle: data?.englishTitle,
                            englishDescription: data?.englishDescription,
                          }) ||
                          isCourseIDFetched
                        ? [styles.btn_wrapper_uaf, styles.final_state].join(' ')
                        : styles.btn_wrapper_uaf
                    }
                    onClick={() => {
                      if (
                        !getEnableSaveAndExit({
                          englishTitle: data?.englishTitle,
                          englishDescription: data?.englishDescription,
                        }) &&
                        !isCourseIDFetched
                      ) {
                        setNotificationData({
                          show: true,
                          type: 'FAILURE',
                          title: 'Upload Adapt file disabled.',
                          description:
                            '‘Upload Adapt file’ button will be enabled only when you enter Course Title and Description.',
                        });
                      }
                    }}
                  >
                    <div className={styles.txt}>
                      {uploadingFileButton
                        ? 'File Uploading'
                        : fileData?.name || data?.adaptFileName
                        ? 'Replace Adapt Files'
                        : 'Upload an Adapt file'}
                    </div>
                    <div className={styles.upload_icon}></div>
                  </div>
                  {uploadingFileButton && (
                    <div className={styles.note_text}>
                      Note: Please do not navigate to any other page when the file is uploading.
                    </div>
                  )}
                </label>
                {(fileData?.name || data?.adaptFileName) && (
                  <div className={[styles.attachement_wrapper, ''].join(' ')}>
                    <div className={styles.txt}>{fileData?.name || data?.adaptFileName}</div>
                  </div>
                )}
                <div className={[styles.tag_text, styles.padding_zero].join(' ')}>
                  {' '}
                  100MB max | .zip
                </div>
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Thumbnail Image</div>
                  <div className={styles.txt}>
                    Add a Thumbnail Image to this course by choosing an Image from an existing Image
                    on the Image Library or by choosing an Image from your local device to add a New
                    Image.
                  </div>
                </div>
                {!mainImageBackup && !isLoading ? (
                  <div
                    className={[
                      styles.pre_browse_wrapper,
                      uploadingFileButton ? styles.disabled : '',
                      styles.uploadig_state,
                      '',
                    ].join(' ')}
                  >
                    <div className={styles.browse_img_txt_wrapper}>
                      <div className={styles.browse_img}></div>
                      <div
                        className={styles.browse_text}
                        onClick={() => {
                          setImageData({
                            imageName: null,
                            imageDescription: null,
                            pillsData: null,
                          });
                          if (!disableImageLib) {
                            setThumbnailImage169(null);
                            setCourseMode('FROM_COURSE_WIZARD');
                            router.push('/Contentmanagement');
                            // setOpenImageLib();

                            setNewCourseData({
                              data: data,
                              adaptFile: fileData,
                              skills: pillsDataSkills,
                              keyword: pillsDataKeyword,
                              newCourseID: newCourseID,
                              categoryData: categoryData,
                              pillsAddAudienceGroup: pillsAddAudienceGroup,
                              isPreviewAdaptFile: publishButtonAfterPreview,
                              addUserGroupList: addUserGroupList,
                              addUserGroupListCopy: addUserGroupListCopy,
                              deleteUserGroupList: deleteUserGroupList,
                            });
                            //setNewAdaptFile(fileData);
                            setNewCourseWizardState({
                              courseWizardOpen: true,
                              courseWizardStateOpen: 'main',
                              section: '',
                            });
                          }
                        }}
                      >
                        Choose Image from Image Library{' '}
                      </div>
                    </div>
                    <div className={styles.text}>or</div>
                    <input
                      type={'file'}
                      accept=".jpg,.jpeg"
                      onChange={(event) => {
                        setCourseMode('FROM_COURSE_WIZARD');
                        setImageData({
                          imageName: null,
                          imageDescription: null,
                          pillsData: null,
                        });
                        uploadImageFromDesktop(event, event.target.files[0]);
                        setNewCourseData({
                          data: data,
                          adaptFile: fileData,
                          skills: pillsDataSkills,
                          keyword: pillsDataKeyword,
                          newCourseID: newCourseID,
                          categoryData: categoryData,
                          pillsAddAudienceGroup: pillsAddAudienceGroup,
                          isPreviewAdaptFile: publishButtonAfterPreview,
                          addUserGroupList: addUserGroupList,
                          addUserGroupListCopy: addUserGroupListCopy,
                          deleteUserGroupList: deleteUserGroupList,
                        });
                        // setNewAdaptFile(fileData);
                        setNewCourseWizardState({
                          courseWizardOpen: true,
                          courseWizardStateOpen: 'main',
                          section: '',
                        });
                      }}
                      hidden={true}
                      multiple={false}
                      id={'chooseImage'}
                      disabled={disableImageLib}
                    />
                    <label htmlFor="chooseImage">
                      <div className={[styles.browse_text, styles.secondary_text].join(' ')}>
                        Choose Image from Local Device
                      </div>
                    </label>
                  </div>
                ) : mainImageBackup && !isLoading ? (
                  <div className={[styles.thumbnail_inner_wrapper, ''].join(' ')}>
                    <div className={[styles.top_section, ' '].join(' ')}>
                      <div className={[styles.btn_wrapper_uaf, styles.margin_zero].join(' ')}>
                        <div
                          className={styles.txt}
                          onClick={() => {
                            if (!disableImageLib) {
                              setCourseMode('FROM_COURSE_WIZARD');
                              router.push('/Contentmanagement');
                              // setOpenImageLib();
                              setNewCourseData({
                                data: data,
                                adaptFile: fileData,
                                skills: pillsDataSkills,
                                keyword: pillsDataKeyword,
                                newCourseID: newCourseID,
                                categoryData: categoryData,

                                pillsAddAudienceGroup: pillsAddAudienceGroup,
                                isPreviewAdaptFile: publishButtonAfterPreview,
                                addUserGroupList: addUserGroupList,
                                addUserGroupListCopy: addUserGroupListCopy,
                                deleteUserGroupList: deleteUserGroupList,
                              });
                              //setNewAdaptFile(fileData);
                              setNewCourseWizardState({
                                courseWizardOpen: true,
                                courseWizardStateOpen: 'main',
                                section: '',
                              });
                            }
                          }}
                        >
                          Choose From Image Library
                        </div>
                        <div className={styles.book_icon}></div>
                      </div>
                      <div
                        className={styles.insturction_text_wrapper}
                        onClick={() => {
                          //setNewImageData(null);
                          if (!disableImageLib) {
                            setCourseMode('FROM_COURSE_WIZARD');
                            uploadNewImagePath();
                            setNewCourseData({
                              data: data,
                              adaptFile: fileData,
                              skills: pillsDataSkills,
                              keyword: pillsDataKeyword,
                              newCourseID: newCourseID,
                              categoryData: categoryData,

                              pillsAddAudienceGroup: pillsAddAudienceGroup,
                              isPreviewAdaptFile: publishButtonAfterPreview,
                              addUserGroupList: addUserGroupList,
                              addUserGroupListCopy: addUserGroupListCopy,
                              deleteUserGroupList: deleteUserGroupList,
                            });
                            //setNewAdaptFile(fileData);
                            setNewCourseWizardState({
                              courseWizardOpen: true,
                              courseWizardStateOpen: 'main',
                              section: '',
                            });
                            setImageData({
                              imageName: null,
                              imageDescription: null,
                              pillsData: null,
                            });
                            setNewThumbnailImageData(mainImageBackup);
                            setNewImageData(null);
                            setMainImageBackup(null);
                            setCroppedImageData(null);
                            setCroppedImage_16_9(null);
                            setCroppedImage_1_1(null);
                            setSelectedImage_16_9_Backup(null);
                            setSelectedImage_1_1_Backup(null);
                          }
                        }}
                      >
                        <div className={styles.info_text}>Upload New Image </div>
                        <div className={styles.left_arrow_icon}></div>
                      </div>
                    </div>
                    <div className={[styles.img_wrapper, ' '].join(' ')}>
                      <div className={styles.img}>
                        <img
                          // src={ImageDetail?.bannerImage ? ImageDetail.bannerImage : ""}
                          src={
                            //newThumbnailId?.length > 0 &&
                            thumbnailImage169?.img ? thumbnailImage169?.img : mainImageBackup
                          }
                          alt="Thambnail"
                          className={styles.selected_img}
                        />
                      </div>
                    </div>
                  </div>
                ) : isLoading ? (
                  <LoadingShimmerCustom className={styles.pre_browse_wrapper} />
                ) : null}
                <div className={styles.tag_text}> 3MB max | .jpg, jpeg</div>

                {/* Course runtime / Duration  */}
                <div className={[styles.course_time_wrapper, styles.padding_class].join(' ')}>
                  <div className={styles.txt}>Total Course Runtime</div>
                  <div className={styles.time_spend_wrapper}>
                    {isLoading ? (
                      <LoadingShimmerCustom className={styles.hrs_box} />
                    ) : (
                      <input
                        type={'number'}
                        name="duration"
                        defaultValue={data?.duration === 0 ? '' : data && Number(data?.duration)}
                        value={data?.duration === 0 ? '' : data && Number(data?.duration)}
                        placeholder={'0'}
                        min="0"
                        max="20000"
                        className={styles.hrs_box}
                        onChange={(e) => handleDataFill(e.target.name, Number(e.target.value))}
                        onFocus={(e) => (e.target.placeholder = '')}
                        onBlur={(e) => (e.target.placeholder = '0')}
                      />
                    )}
                    <div className={styles.txt_xs}>Minutes</div>
                  </div>
                </div>
              </div>
            </div>
            {/* Info container 4th block */}
          </div>
        </div>
      )}

      {/* Category course wizard screen 2 started */}

      {nextState && (
        <div
          className={[
            styles.inner_wrapper,
            styles.category_wrapper,
            nextState ? styles.adapt_file : '',
            styles.preview_adapt,
            '',
          ].join(' ')}
        >
          <div
            className={styles.prev_adapt_file_wrapper}
            onClick={async () => {
              if (
                (previewData ||
                  (data && data?.adaptFileName?.length > 0) ||
                  (fileData && fileData?.name?.length > 0)) &&
                !uploadingFileButton
              ) {
                setPreviewAdaptFileFlag(true);
                await previewAdaptFileCreateNewCourse();
                //setOpenPreviewAdaptFile(true);
                //openInNewTab("url");
              }
            }}
          >
            <div
              className={
                (previewData ||
                  (data && data?.adaptFileName?.length > 0) ||
                  (fileData && fileData?.name?.length > 0)) &&
                !uploadingFileButton
                  ? styles.txt
                  : styles.txt_disable
              }
            >
              Preview Adapt File
            </div>
            <div
              className={[
                (previewData ||
                  (data && data?.adaptFileName?.length > 0) ||
                  (fileData && fileData?.name?.length > 0)) &&
                !uploadingFileButton
                  ? styles.adapt_file_icon
                  : styles.adapt_file_icon_disabled,
              ].join(' ')}
            ></div>
          </div>
          <div className={styles.row_wrapper}>
            {/* Info container  block */}
            <div className={styles.info_container}>
              <div
                className={[styles.inr_wrapper, styles.margin_pad_x, 'col-md-12 col-lg-6'].join(
                  ' ',
                )}
              >
                <div className={styles.title_wrapper}>
                  {/* <div className={styles.preview_tab_wrapper}>
                        <div className={styles.preview_icon}></div>
                        <div className={styles.note_txt}>
                          Please preview the Adapt file before publishing.
                        </div>
                      </div> */}

                  <div className={styles.title}>Category</div>
                  <div className={styles.txt}>
                    All Courses must have a Category. Only one Category may be selected. Categories
                    are used in Explore by the ABO to filter through courses. They are also used by
                    the system when populating related courses.
                  </div>
                </div>
                <div className={[styles.form_field, styles.info_wrapper].join(' ')}>
                  {isLoading ? (
                    <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                  ) : (
                    <SearchListComponent
                      list={categories}
                      varient="dropdown"
                      defaultInputData={categoryData ? categoryData : ''}
                      handlePillList={(title, dataWithTitle) => {
                        // setPillsData(data);
                        setCategoryData(title);
                        handleDataFill('category', dataWithTitle);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* Info container 2nd block */}
            <div className={styles.info_container}>
              <div
                className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(
                  ' ',
                )}
              >
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Skills You Will Gain</div>
                  <div className={styles.txt}>
                    Choose from a pre-loaded list of Skills that aid the user in choosing which
                    courses to take. Skills appear on the course details drawer. Skills are
                    automatically translated into all language supported by your market. You may
                    select up to 5 skills to display on a course.
                  </div>
                </div>
                <div
                  className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}
                >
                  {isLoading ? (
                    <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                  ) : (
                    <div className={styles.input_wrapper} ref={skillsRef}>
                      <SearchListComponent
                        list={skills}
                        reference={skillsRef}
                        varient="textInput"
                        placeHolderText={
                          data?.skills?.length >= 5
                            ? 'All Skills Added'
                            : 'Enter Skills You Will Gain'
                        }
                        pillsLimit={5}
                        handlePillList={(data, dataWithID) => {
                          setPillsDataSkills(data);
                          handleDataFill('skills', dataWithID);
                        }}
                        defaultPillList={pillsDataSkills}
                        disabledInput={data?.skills?.length >= 5}
                      />
                      <div className={styles.count_num}>{`${
                        pillsDataSkills?.length ? pillsDataSkills?.length : '0'
                      }/5`}</div>
                    </div>
                  )}
                </div>
                {!isLoading && (
                  <div
                    className={[styles.pills_container, 'col-xl-12 col-lg-12 pull-left'].join(' ')}
                  >
                    <Pills
                      pillData={pillsDataSkills}
                      isClose={true}
                      isBold={false}
                      onClosePill={(item) => {
                        filterListById(
                          item.id,
                          pillsDataSkills,
                          setPillsDataSkills,
                          'skills',
                          handleDataFill,
                        );
                      }}
                    />
                  </div>
                )}
              </div>
              <div
                className={[styles.inr_wrapper, styles.audtarg_wrapper, 'col-md-12 col-lg-6'].join(
                  ' ',
                )}
              >
                <div className={styles.title_wrapper}>
                  <div className={styles.title}>Keyword Tagging</div>
                  <div className={styles.txt}>
                    Keyword Tags are used by the Academy to aid in search and filtering both for
                    front-end app users. These keywords do not appear on course detail drawers. Add
                    all relevant tags to the course.
                  </div>
                </div>
                <div
                  className={[styles.form_field, styles.info_wrapper, styles.margin_zero].join(' ')}
                  ref={keywordsRef}
                >
                  <div className={styles.label_wrapper}>
                    <div className={styles.label_text}>Add Tags</div>
                  </div>
                  {isLoading ? (
                    <LoadingShimmerCustom className={styles.search_list_wrapper_loading} />
                  ) : (
                    <SearchListComponent
                      list={keyword}
                      reference={keywordsRef}
                      placeHolderText="Add Keywords and Enter"
                      varient="textInput"
                      handlePillList={(data, dataWithID) => {
                        setPillsDataKeyword(data);
                        handleDataFill('keywords', dataWithID);
                      }}
                      pillsLimit={100}
                      defaultPillList={pillsDataKeyword}
                    />
                  )}
                </div>

                {!isLoading && (
                  <div
                    className={[styles.pills_container, 'col-xl-12 col-lg-12 pull-left'].join(' ')}
                  >
                    <Pills
                      pillData={pillsDataKeyword}
                      isClose={true}
                      isBold={false}
                      onClosePill={(item) => {
                        filterListById(
                          item.id,
                          pillsDataKeyword,
                          setPillsDataKeyword,
                          'keywords',
                          handleDataFill,
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Category course wizard screen 2 ended */}
    </>
  );
};
export default CreateWizard;
