import axios from 'axios';
import config from '../../../config';
const axiosInstance = axios.create();
const {currentRegion, currentEnv} = config;
const {apiConfig, oidc} = config[currentRegion];
const BASE_URL = apiConfig[currentEnv].baseURL;

export const getCurrentRegionOIDCConfigs = () => oidc[currentEnv];
export const getCurrentRegionAPIConfigs = () => apiConfig[currentEnv];

export const _apiHeaderConfigs = () => {
  const _localStorageOktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
  if (_localStorageOktaTokenStorage) {
    const {idToken = {}, accessToken = {}} = _localStorageOktaTokenStorage;
    const _accessToken = accessToken && accessToken.accessToken;
    const _idToken = idToken && idToken.idToken;

    return {
      _accessToken: _accessToken,
      _idToken: _idToken,
    };
  } else {
    return {
      _accessToken: false,
      _idToken: false,
    };
  }
};

export const makeRequest = async (params) => {
  const {method} = params;
  let resp = {};
  switch (method) {
    case 'GET':
      resp = await makeGetRequest(params);
      return resp;
    case 'POST':
      resp = await makePostRequest(params);
      return resp;
    case 'PUT':
      resp = await makePutRequest(params);
      return resp;
    case 'DELETE':
      resp = await makeDeleteRequest(params);
      return resp;
    case 'PATCH':
      resp = await makePatchRequest(params);
      return resp;
    default:
      console.warn('[httpUtil] >> [makeRequest] >> Please provide valid HTTP method.');
  }
};

const makeGetRequest = async (params) => {
  const {endpoint, url} = params;
  const _headers = _apiHeaderConfigs();
  const resp = await axiosInstance
    .get(url ? url : BASE_URL + endpoint, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    })
    .then((response) => {
      const {url} = params;
      if (url) {
        return response;
      } else {
        const {status} = response;
        if (status === 200) {
          return response && response.data ? response.data : [];
        } else if (status === 500 || status >= 500) {
          return 'ERR_NETWORK';
        }
      }
    })
    .catch((err) => {
      return err.code && 'ERR_NETWORK';
    });
  return resp;
};

const makePostRequest = async (params) => {
  const {endpoint, body} = params;
  const _headers = _apiHeaderConfigs();
  const resp = await axiosInstance
    .post(BASE_URL + endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    })
    .then((response) => {
      const {status} = response;
      if (status === 200 || status === 201) {
        return response && response.data ? response.data : [];
      } else if (status === 500 || status >= 500) {
        return 'ERR_NETWORK';
      }
    })
    .catch((err) => {
      return err.code;
    });
  return resp;
};

const makePutRequest = async (params) => {
  const {endpoint, body} = params;
  const _headers = _apiHeaderConfigs();
  const resp = await axiosInstance
    .put(BASE_URL + endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    })
    .then((response) => {
      const {status} = response;
      if (status === 200 || status === 201) {
        return response && response.data ? response.data : [];
      } else if (status === 500 || status >= 500) {
        return 'ERR_NETWORK';
      }
    })
    .catch((err) => {
      return err.code;
    });
  return resp;
};

const makePatchRequest = async (params) => {
  const {endpoint, body} = params;
  const _headers = _apiHeaderConfigs();
  const resp = await axiosInstance
    .patch(BASE_URL + endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    })
    .then((response) => {
      const {status} = response;
      if (status === 200 || status === 201) {
        return response && response.data ? response.data : [];
      } else if (status === 500 || status >= 500) {
        return 'ERR_NETWORK';
      }
    })
    .catch((err) => {
      return err.code;
    });
  return resp;
};

const makeDeleteRequest = async (params) => {
  const {endpoint, body} = params;
  const _headers = _apiHeaderConfigs();
  if (body?.audienceGroups.deleteAudGrpIds?.length > 0) {
    const resp = await axiosInstance
      .delete(BASE_URL + endpoint, {
        headers: {
          'Content-Type': 'application/json',
          authorization: _headers._accessToken,
          profile: _headers._idToken,
        },
        data: body,
      })
      .then((response) => {
        const {status} = response;
        if (status === 200 || status === 201) {
          return response && response.data ? response.data : [];
        }
      })
      .catch((err) => {
        return err.code;
      });
    return resp;
  } else {
    const resp = await axiosInstance
      .delete(BASE_URL + endpoint, {
        headers: {
          'Content-Type': 'application/json',
          authorization: _headers._accessToken,
          profile: _headers._idToken,
        },
      })
      .then((response) => {
        const {status} = response;
        if (status === 200 || status === 201) {
          return response && response.data ? response.data : [];
        }
      })
      .catch((err) => {
        return err.code;
      });
    return resp;
  }
};
