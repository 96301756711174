import React, {useEffect, useState} from 'react';
import styles from './searchBar.module.scss';
import {Hint} from 'react-autocomplete-hint';
const SearchBarComponent = ({
  isDrawerOpen = false,
  handleSearchInput,
  predictedSearch,
  placeholderName = 'Search Course Name',
  isToggleSwitch = false,
  searchFontXS = '',
  searchBarFrom = '',
  subheader = '',
}) => {
  const [search, setSearch] = useState('');
  const [width, setWidth] = useState(0);
  const [isDrawer, setDrawer] = useState(isDrawerOpen);
  const [onFocusBorderColorChange, setOnFocusBorderColorChange] = useState(false);
  const [isPredictedSearchIsComplete, setPredictedSearchIsComplete] = useState(false);
  const [isEnterPressed, setEnterPressed] = useState(false);

  const getCalculatedWidth = () => {
    if (isDrawer && width >= 1024 && searchBarFrom !== 'LEARNING-PATH') {
      return '21rem';
    } else if (!isDrawer && width >= 1024) {
      return '25rem';
    } else if (isDrawer === true && width >= 1024 && searchBarFrom === 'LEARNING-PATH') {
      return '19rem';
    } else if (!isDrawer && width >= 375 && width < 768 && searchBarFrom === 'Course Addition') {
      return 'calc(100% - 116px)';
    } else if (width >= 767 && width < 1200 && searchBarFrom === 'CST') {
      return 'calc(100% - 205px)';
    } else if (
      !isDrawer &&
      width >= 375 &&
      width < 768 &&
      searchBarFrom === 'LEARNING-PATH-COURSE-ADDITION'
    ) {
      return 'calc(100% - 105px)';
    } else if (
      isDrawer === true &&
      width >= 768 &&
      width < 1024 &&
      searchBarFrom === 'LEARNING-PATH'
    ) {
      return 'calc(100% - 425px)';
    } else if (
      !isDrawer === true &&
      width >= 768 &&
      width < 1024 &&
      searchBarFrom === 'LEARNING-PATH'
    ) {
      return 'calc(100% - 424px)';
    } else if (!isDrawer && width >= 768 && width < 1024) {
      return '100%';
    } else if (!isDrawer && width >= 375 && width < 768 && !isToggleSwitch) {
      return 'calc(100%  -  51px)';
    } else if (!isDrawer && width >= 375 && width < 768 && isToggleSwitch) {
      return 'calc(100%  -  105px)';
    } else if (!isDrawer && width > 320) {
      return '100%';
    } else {
      return '15rem';
    }
  };
  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
  };
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  useEffect(() => {
    setDrawer(isDrawerOpen);
  }, [isDrawerOpen]);

  useEffect(() => {
    if (
      (search?.toLowerCase() === predictedSearch[0]?.label?.toLowerCase() ||
        search?.toLowerCase() === predictedSearch[1]?.label?.toLowerCase()) &&
      isEnterPressed
    ) {
      setPredictedSearchIsComplete(true);
    } else {
      setPredictedSearchIsComplete(false);
      setEnterPressed(false);
    }
  }, [search, isEnterPressed]);

  return (
    <React.Fragment>
      <div
        style={{
          width: getCalculatedWidth(),
        }}
        className={[
          styles.search_main_wrapper,
          !isPredictedSearchIsComplete && (onFocusBorderColorChange || search.length > 0)
            ? styles.active
            : '',
        ].join(' ')}
      >
        <div className={styles.search_wrapper}>
          <Hint options={predictedSearch} allowTabFill={true}>
            <input
              type="text"
              placeholder={placeholderName}
              value={search}
              onChange={(e) => {
                handleSearchInput(e), setSearch(e.target.value);
              }}
              onKeyPress={(e) => {
                handleSearchInput(e), setSearch(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  setEnterPressed(true);
                }
                handleSearchInput(e), setSearch(e.target.value);
              }}
              className={[styles.search_input, searchFontXS ? styles.search_font_XS : ''].join(' ')}
              onFocus={(e) => {
                handleSearchInput(e), setSearch(e.target.value);
                setOnFocusBorderColorChange(true);
              }}
              onBlur={() => {
                setOnFocusBorderColorChange(false);
                setEnterPressed(false);
              }}
            />
          </Hint>
          {onFocusBorderColorChange || search.length > 0 ? (
            <div
              className={styles.icon_wrapper}
              onClick={() => {
                handleSearchInput('');
                setSearch('');
                setEnterPressed(false);
              }}
            >
              <div className={styles.close_icon} />
            </div>
          ) : (
            <div className={styles.icon_wrapper}>
              <div className={styles.search} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

SearchBarComponent.propTypes = {};

export default SearchBarComponent;
