import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import SideNavigationComponent from './sideNavigation/index';
import styles from './Layout.module.scss';
import NoInternetConnection from './shared/networkErrorScreen/index';
import {setErrorStateTemplate} from '../redux/actions/uiActions';
const Layout = (props) => {
  const {children, isUserLoggedIn = true, setErrorStateTemplate, errorTemplateState} = props;

  const [isOnline, setOnline] = useState(true);
  const [errorOccured, setErrorOccured] = useState(false);
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener('online', () => {
    setOnline(true);
    setErrorStateTemplate({
      status: false,
      variant: 'networkError',
    });
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
    setErrorStateTemplate({
      status: true,
      variant: 'networkError',
    });
  });
  useEffect(() => {
    setErrorOccured(errorTemplateState.status);
  }, [errorTemplateState.status]);

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        {isUserLoggedIn && <SideNavigationComponent />}
        {errorOccured ? <NoInternetConnection /> : children}
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUserLoggedIn: state && state.uiState && state.uiState.isUserLoggedIn,
  errorTemplateState: state && state.uiState && state.uiState.errorTemplateState,
});
const mapDispatchToProps = (dispatch) => ({
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Layout));
